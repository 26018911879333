import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const FileManager = ({userInfo}) => {


    // console.log(userInfo)
    const [avatarImage, setAvatarImage] = useState(userInfo?.image)
    const [arrayImage, setimageArray] = useState(null)


    const changeHandlerImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            let files = event.target.files;
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);

            reader.onload = async (e) => {
                var formData = new FormData();
                formData.append("update_avatar", 1);
                formData.append("user_id", userInfo?.user_id);
                formData.append("username", userInfo?.username);
                formData.append("image", e.target.result);
                await axios({
                    method: "post",
                    url: ENV.DOMAIN + 'process.php',
                    data: formData,
                }).then((res) => {
                    // setProductList(res.data)
                    // console.log(res.data)
                    setAvatarImage(res.data);
                }).catch((err) => {
        
                });


                
                // console.log(event.target.files[0])
                // setimageArray([...imagearray, { 
                //     filename: event.target.files[0].name, 
                //     filetype: event.target.files[0].type, 
                //     filezise: event.target.files[0].size, 
                //     image: e.target.result
                // }])
                // setAvatarImage(e.target.result)
                // setimageDisplayOne(e.target.result)
                // const split = event.target.files[0].name.split('.')[1]
                // console.log(split)
            }
        }
    };

    return (
        <div style={{ width: 90, height: 90, position: "relative", backgroundColor:"#fff", borderRadius:100 }}>
            {/* "../dist/img/user1-128x128.jpg" */}
            <img className="img-circle elevation-2" src={avatarImage!=null ? ENV.DOMAIN+avatarImage : "../dist/img/user1-128x128.jpg"} style={{ width: 90, height: 90 }} />
            <label style={{ position: "absolute", width: 40, height: 40, top: 60, right: 0, borderRadius: 100, backgroundColor: "#fff", border: "3px solid #ddd", zIndex: 1, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {/* <label> */}
                <input type="file" placeholder="" style={{ display: "none" }} className="required" onChange={(e) => changeHandlerImage(e)} />
                <i className='pi pi-camera' />
                {/* </label> */}
            </label>
        </div>
    );
}

export default FileManager;
