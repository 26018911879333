import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';
import MatrixMenu from './extra/MatrixMenu';
import PUCApplication from './component/pickupcenter/PUCApplication';
import MatrixCodeDorm from './component/pickupcenter/MatrixCodeForm';

const PickupCenter = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    
    const [Copied, setCopied] = useState({id:'', status:false});

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [application, setApplication] = useState([]);
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_application", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setApplication(res.data)
            // console.log(res.data)
        });
    }, [ENV]);

    // console.log(userInfo)

    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                            <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                    <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                    <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                </div> */}
                                <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                    <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                    <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                    <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                    {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                </div>
                            </div>
                            {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                        </div>
                    </>
                :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{marginBottom:50}}>
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="content" style={{padding: 0}}>
                    <div className="container">
                        <div className="row" style={{padding: 0}}>
                            {/* /.col */}
                            <div className="col-md-12" style={{padding: 0}}>
                                {
                                    !useCheckMobileScreen() ?
                                        <div className="card">
                                            <div className="card-body">
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Fullname</th>
                                                            {/* <th>Placement</th> */}
                                                            <th>Rank</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            application.map((item, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td></td>
                                                                        <td>{item.fullname}</td>
                                                                        {/* <td>{item.placement_fullname}</td> */}
                                                                        <td>{item.rank_status}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div style={{padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10}}>
                                                <MatrixCodeDorm userInfo={userInfo} />
                                                <PUCApplication userInfo={userInfo} />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent:"center", flexWrap:"wrap", alignItems: "center", gap: 10 }}>
                                                {
                                                    [...application].map((item, key) => {
                                                        return (
                                                            <div key={key} className="" style={{ padding: 5, border: "1px solid #ddd", display:"flex", flexDirection:"column", gap:0, marginBottom:1, width: "45%", borderRadius: 10, backgroundColor: "#fff", height: 200 }}>
                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop:0, paddingBottom:0, height: "100%" }}>
                                                                    <span style={{fontWeight: "bolder"}}>Pick-up Center</span>
                                                                    <span style={{fontWeight: "bolder"}}>Under Construction</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                }
                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* <MatrixMenu /> */}
                <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div> */}
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default PickupCenter;
