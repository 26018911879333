import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';
import ConfirmPaymentEntry from './extra/admin/ConfirmPaymentEntry';

const AdminKYCApplication = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [kyclist, setKYClist] = useState([])
    const [userlist, setUserlist] = useState([])
    const [search, setSearch] = useState('')


    const refreshCodeList = async () => {
        var formData = new FormData();
        formData.append("kyc_list", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res)=>{
            console.log(res.data)
        }).catch((err)=>{

        });
    }


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("kyc_list", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res)=>{
            setKYClist(res.data)
        }).catch((err)=>{

        });
    }, [ENV])


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("users", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res)=>{
            setUserlist(res.data)
        }).catch((err)=>{

        });
    }, [ENV])



    const SearchData = (data, value)  => {
        if (value!='') {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.owner_name.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.code.toLowerCase().includes(value.trim().toLowerCase()))
            || (i?.confirm == 0 ? 'paid' : 'unpaid').toLowerCase().includes(value.trim().toLowerCase())
            );
        }
        return data;
    }

    const buttonProcess = (status) => {
        if (status === 'approve') {
            alert("Under Construction")
        } else if (status === 'decline') {
            alert("Under Construction")
        }
    }



    if(window.innerWidth <= 768) {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0">Paid Code</h5>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div style={{padding:5}}>
                            <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search...' style={{padding:5, height:30, borderRadius:5, border:"1px solid #ddd"}} />
                        </div>
                        <div style={{display:"flex", flexDirection:"column", gap:5}}>
                        {
                                    SearchData(kyclist, search).map((item) => {
                                        if(item.owner_id !='' && item.paid == 1) {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: item.user_id != '' ? "#cf9999" : "#fff", color: item.user_id != '' ? "#fff" : "#000", borderRadius: 5, padding: 5, borderBottom:"1px solid #ddd" }}>
                                                    <div style={{ display: "flex", width: "50%", flexDirection: "column" }}>
                                                        <div style={{ fontSize: 15 }}>Name: {item.owner_name}</div>
                                                        <div style={{ fontSize: 15 }}>Code: {item.code}</div>
                                                        {
                                                            item.user_id != '' ?
                                                            <>
                                                                <div style={{ fontSize: 15 }}>Used By: {item.fullname}</div>
                                                                <div style={{ fontSize: 15 }}>Date: {item.date_used}</div>
                                                            </>
                                                            :null
                                                        }
                                                    </div>
                                                    <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                                        <div style={{ textAlign: "right", fontSize: 12 }}>Created: {item.date_time}</div>
                                                        <div style={{ textAlign: "right", fontSize: 15 }}>
                                                            <div style={{color:item.paid == 0 ? "red" : "green"}}>{item.paid == 0 ? "Unpaid" : "Paid"}</div>
                                                            <div>{item.comment}</div>
                                                            {
                                                                item.confirm == 1 ?
                                                                <ConfirmPaymentEntry refreshCodeList={refreshCodeList} item={item} />
                                                                :null
                                                            }
                                                        </div>
    
    
    
                                                        {/* <CopyToClipboard text={item.code}
                                                            style={{ border: "1px solid #ddd", padding: 2, borderRadius: 5, fontWeight: 200, fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
                                                            onCopy={() => {
                                                                setCopied({ code: item.code, status: true })
                                                                setTimeout(() => {
                                                                    setCopied({ code: item.code, status: false })
                                                                }, 1000)
                                                            }}>
                                                            {
                                                                Copied.code == item.code && Copied.status == true ?
                                                                    <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                    :
                                                                    <a className="btn btn-primary btn-block"><b>Copy</b></a>
                                                            }
                                                        </CopyToClipboard> */}
                                                        {/* <button style={{borderRadius:5, outline:"none", border:"1px solid #ddd", fontSize:13, width:100}}>Copy</button> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                        </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">KYC Application</h1>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* Left col */}
                                {/* /.Left col */}
                                {/* right col (We are only adding the ID to make the widgets sortable)*/}
                                
                                <div className='col-sm-12'>
                                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                    {
                                        [...kyclist].filter((i)=>i).map((item)=>{
                                            return (
                                                <div style={{width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>Account ID: <span style={{fontWeight: "bolder"}}>{item.user_id}</span></span>
                                                        <span>Fullname: <span style={{fontWeight: "bolder"}}>{[...userlist].filter((i)=>i.user_id === item.user_id)[0]?.fullname}</span></span>
                                                        <span>Telephone: <span style={{fontWeight: "bolder"}}>{[...userlist].filter((i)=>i.user_id === item.user_id)[0]?.telephone}</span></span>
                                                        <hr />
                                                        <span>Upline: <span style={{fontWeight: "bolder"}}>{[...userlist].filter((i)=>i.user_id === [...userlist].filter((i)=>i.user_id === item.user_id)[0]?.sponsor_id)[0]?.fullname}</span></span>
                                                    </div>
                                                    <hr />
                                                    <div>
                                                        <div>KYC Form</div>
                                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                                                            <div style={{display: "flex", flexDirection: "column", gap: 5, width: 250}}>
                                                                <div style={{display: "flex", gap: 5, width: 250}}>
                                                                    <span style={{fontSize: 20, fontWeight: "bolder"}}>{item.first_name} {item.middle_name} {item.last_name}</span>
                                                                </div>
                                                                <div style={{display: "flex", flexDirection: "column", width: 250}}>
                                                                    <span>Birthday: {item.birthday}</span>
                                                                    <span>Gender: {item.gender}</span>
                                                                    <span>Nationality: {item.nationality}</span>
                                                                    <span>Civil Status: {item.civil_status}</span>
                                                                </div>
                                                            </div>
                                                            <div style={{display: "flex", flexDirection: "column", gap: 5, width: 350}}>
                                                                <span>Mobile #: {item.phone_number}</span>
                                                                <span>Email: {item.email_address}</span>
                                                            </div>
                                                            <div style={{display: "flex", flexDirection: "column", gap: 5, width: 350}}>
                                                                <span>Street: {item.street_address}</span>
                                                                <span>City: {item.city}</span>
                                                                <span>State: {item.state ? item.state : "N/A"}</span>
                                                                <span>Postal Code: {item.postal_code}</span>
                                                                <span>Country: {item.country}</span>
                                                            </div>
                                                            <div style={{display: "flex", flexDirection: "column", gap: 5, width: 300}}>
                                                                <span>Type: {item.identification_type}</span>
                                                                <span>Number: {item.identification_number}</span>
                                                                <span>Expiry Date: {item.expiry_date ? item.expiry_date : 'N/A'}</span>
                                                            </div>
                                                            <div style={{display: "flex", flexDirection: "column", gap: 50, width: 200}}>
                                                                <div style={{display: "flex", gap: 10}}>
                                                                    <a href={ENV.DOMAIN+'dist/img/kyc/'+item.identification_front_image} target="_blank">
                                                                        <img src={ENV.DOMAIN+'dist/img/kyc/'+item.identification_front_image} style={{width: 50, height: 50}} />
                                                                    </a>
                                                                    
                                                                    <a href={ENV.DOMAIN+'dist/img/kyc/'+item.identification_back_image} target="_blank">
                                                                        <img src={ENV.DOMAIN+'dist/img/kyc/'+item.identification_back_image} style={{width: 50, height: 50}} />
                                                                    </a>
                                                                    <a href={ENV.DOMAIN+'dist/img/kyc/'+item.selfie_image} target="_blank">
                                                                        <img href={ENV.DOMAIN+'dist/img/kyc/'+item.selfie_image} target="_blank" src={ENV.DOMAIN+'dist/img/kyc/'+item.selfie_image} style={{width: 50, height: 50}} />
                                                                    </a>
                                                                </div>
                                                                {/* <div style={{display: "flex", flexDirection: "row", gap: 5}}>
                                                                    <button className='btn btn-info btn-sm' onClick={()=>buttonProcess('approve')}>Approve</button>
                                                                    <button className='btn btn-danger btn-sm' onClick={()=>buttonProcess('decline')}>Decline</button>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>


                                {/* right col */}
                            </div>
                            {/* /.row (main row) */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>

        );
    }



}

export default AdminKYCApplication;
