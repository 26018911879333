import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import { Sidebar } from 'primereact/sidebar';
import ShopCard from './component/shop/ShopCard';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import Cart from './component/shop/Cart';
import Pending from './component/shop/Pending';

const Shop = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';
    // const [activation_code, setActivationCode] = useState('');
    
    const [cartlist, setCartList] = useState([]);
    const [productlist, setProductList] = useState([]);
    const [opentab, setOpenTab] = useState('');
    // const [productlist, setProductList] = useState([]);
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("product_shop", 1);
        // formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setProductList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("my_cart", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            setCartList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    const cartLoad = async () => {
        var formData = new FormData();
        formData.append("my_cart", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            setCartList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    const [fix, setFix] = useState(true)

    function setFixed() {
        if (window.scrollY >= 50) {
            // console.log(false)
            setFix(false)
        } else {
            setFix(true)
            // console.log(false)
        }
        // console.log(window.scrollY)
    }

    useMemo(() => {
        window.addEventListener('scroll', setFixed)
    }, [])
    // const TotalQty = checked?.reduce((qty, item) => Number(item.product_qty) + qty, 0);

    return (
        <>
            {/* /////////////////////////////////// */}
            <div className="wrapper">
                {/* Navbar */}
                {
                    window.innerWidth <= 768 ?
                        <>
                            <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                                <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                    {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                        <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                        <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                    </div> */}
                                    <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                        {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                    </div>
                                </div>
                                {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                            </div>
                        </>
                        :
                        <Header />
                }
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <Navbar userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper" style={{ minHeight: '1604.8px', marginBottom: 50 }}>

                    {
                        true ?
                            <>
                                <div style={{ width: "100%", height: window.innerHeight, color: "#fff", fontSize: 25, position: "absolute", zIndex: 2, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                    <div style={{ width: "100%", height: window.innerHeight - 100, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                        <img src={"https://icons.iconarchive.com/icons/paomedia/small-n-flat/256/shop-icon.png"} style={{ width: 150, height: 150 }} />
                                        <div style={{ fontSize: 30 }}>COMING SOON</div>
                                    </div>
                                </div>
                                <div style={{ width: "100%", height: "100%", position: "absolute", backgroundColor: "#000", opacity: 0.5, zIndex: 1 }}></div>
                            </>
                            : null
                    }
                    {
                        !fix ?
                            <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd", position: "fixed", top: 0, zIndex: 5, width: window.innerWidth }}>
                                    <div onClick={() => opentab == 'cart' ? setOpenTab('') : setOpenTab('cart')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'cart' ? "#fff" : "#000", backgroundColor: opentab == 'cart' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-shopping-cart' />  <span>Cart</span>
                                    </div>
                                    <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                                    <div onClick={() => opentab == 'pending' ? setOpenTab('') : setOpenTab('pending')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'pending' ? "#fff" : "#000", backgroundColor: opentab == 'pending' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-stopwatch' />  <span>Pending</span>
                                    </div>
                                    <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                                    <div onClick={() => opentab == 'completed' ? setOpenTab('') : setOpenTab('completed')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'completed' ? "#fff" : "#000", backgroundColor: opentab == 'completed' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-verified' />  <span>Completed</span>
                                    </div>
                                </div>
                                <div style={{ width: "100%", height: 50 }}></div>
                            </>
                        :
                            <>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd" }}>
                                    <div onClick={() => opentab == 'cart' ? setOpenTab('') : setOpenTab('cart')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'cart' ? "#fff" : "#000", backgroundColor: opentab == 'cart' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-shopping-cart' />  <span>Cart</span>
                                    </div>
                                    <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                                    <div onClick={() => opentab == 'pending' ? setOpenTab('') : setOpenTab('pending')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'pending' ? "#fff" : "#000", backgroundColor: opentab == 'pending' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-stopwatch' />  <span>Pending</span>
                                    </div>
                                    <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                                    <div onClick={() => opentab == 'completed' ? setOpenTab('') : setOpenTab('completed')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, color: opentab == 'completed' ? "#fff" : "#000", backgroundColor: opentab == 'completed' ? "#00b090" : "#fff", padding: 5 }}>
                                        <i className='pi pi-verified' />  <span>Completed</span>
                                    </div>
                                </div>
                            </>

                    }

                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", border: "1px solid #ddd" }}>
                        <div onClick={()=>opentab == 'cart' ? setOpenTab('') : setOpenTab('cart')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, backgroundColor: "#fff", padding:5 }}>
                            <i className='pi pi-shopping-cart' />  <span>Cart</span>
                        </div>
                        <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                        <div onClick={()=>opentab == 'pending' ? setOpenTab('') : setOpenTab('pending')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, backgroundColor: "#fff", padding:5 }}>
                            <i className='pi pi-stopwatch' />  <span>Pending</span>
                        </div>
                        <div style={{ width: .81, height: 30, backgroundColor: "#ddd" }}></div>
                        <div onClick={()=>opentab == 'completed' ? setOpenTab('') : setOpenTab('completed')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "50%", height: null, backgroundColor: "#fff", padding:5 }}>
                            <i className='pi pi-verified' />  <span>Completed</span>
                        </div>
                    </div> */}
                    <section className="">
                        <div className="">
                            <div className="">
                                <div className="" style={{ padding: 0 }}>

                                {
                                        opentab == 'cart' ?
                                            <Cart userInfo={userInfo} cartlist={cartlist} setCartList={setCartList} cartLoad={cartLoad} />
                                        // <div style={{display:"flex", flexDirection:"column", gap:5, marginBottom:115}}>
                                        //     {
                                        //         cartlist.map((item, key)=>{
                                        //             return (
                                            // cartlist={cartlist} setCartList={setCartList} cartLoad={cartLoad} item={item} userInfo={userInfo} checked={checked} setChecked={setChecked}
                                        //             )
                                        //         })
                                        //     }
                                        // </div>
                                        :null
                                    }
                                    {
                                        opentab == 'pending' ?
                                        <Pending userInfo={userInfo} cartlist={cartlist} setCartList={setCartList} cartLoad={cartLoad} />
                                        :null
                                    }
                                    {
                                        opentab == 'completed' ?
                                        <div style={{display:"flex", flexDirection:"column", gap:5}}>
                                            {
                                                [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map((item, key) => {
                                                    return (
                                                        <div style={{width:"100%", backgroundColor:"#fff", borderRadius:5, padding:5, border:"1px solid #ddd", boxShadow:"2px 3px 2px 0px rgb(0,0,0,0.4)"}}>
                                                            <div style={{fontSize:12}}>
                                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                    <div>Status: Completed</div>
                                                                    {/* <div> */}
                                                                        <div style={{border:"1px solid #ddd", borderRadius:5, backgroundColor:"#00b090", color:"#fff", width:100, textAlign:"center"}}>Completed</div>
                                                                    {/* </div> */}
                                                                </div>
                                                                <div>Date: 0000-00-00</div>
                                                                <div>ORDER ID: 0000000000</div>
                                                                <div>Mode of Payment: 0000000000</div>
                                                            </div>
                                                            <div>
                                                                <div style={{fontSize:12}}>Shipping Details</div>
                                                                <div style={{fontSize:12}}>Cust Name: Sample Name</div>
                                                                <div style={{fontSize:12}}>Shipping Address</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :null
                                    }

                                    {
                                        opentab == '' ?
                                        <>
                                            {/* <div style={{ width: "100%", height: 100, backgroundColor: "#ddd", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                                PROMOTION
                                            </div> */}
                                            <div>
                                                <div style={{ width: "100%", padding: 1, display: "flex", flexDirection: "column", flexWrap: "wrap", gap: 5 }}>
                                                    {
                                                        productlist.map((item, key) => {
                                                            // orderform={orderform} setOrderForm={setOrderForm}
                                                            return (
                                                                <>
                                                                    <ShopCard item={item} key={key} userInfo={userInfo} cartLoad={cartLoad} />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        :null
                                    }


                                    {/* <div style={{width:"100%", height:window.innerHeight-100, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                    <img src={"https://icons.iconarchive.com/icons/paomedia/small-n-flat/256/shop-icon.png"} style={{width:150, height:150}} />
                                    <div style={{fontSize:30}}>COMING SOON</div>
                                </div> */}


                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {
                        opentab == 'cart' ?
                        <></>
                            // <div style={{ position: "fixed", zIndex: 2, bottom: 0, borderTop:"1px solid #ddd", backgroundColor: "#fff", width: "100%", height:null, display:"flex", flexDirection:"column", justifyContent:"flex-start" }}>
                            //     <div style={{padding:5}}>
                            //         <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            //             <div>
                            //                 Mode of Payment
                            //                 <select onChange={(e)=>setModePayment(e.target.value)} value={modepayment} style={{display:"flex", justifyContent:"center", alignItems:"center", width:150, height:35, borderRadius:5, border:"1px solid #ddd"}}>
                            //                     <option selected disabled>Select</option>
                            //                     <option value={"DG-Wallet"}>DG-Wallet</option>
                            //                     <option value={"COD"}>COD</option>
                            //                 </select>
                            //             </div>
                            //             <div style={{lineHeight:1, textAlign:"right"}}>
                            //                 <div style={{ fontSize:15, fontWeight:600}}>SubTotal: ${(SubTotal ?? 0).toFixed(2)}</div>
                            //                 <span style={{fontSize:12, color:"blue"}}>discounted: -${discounted.toFixed(2)}</span>
                            //                 <span style={{fontSize:12, color:"blue"}}>Shipping fee: ${shippingfee.toFixed(2)}</span>
                            //                 <div style={{ fontSize:14, fontWeight:600}}>Total Payment: ${(modepayment === "DG-Wallet" ? SubTotal>0 ? (SubTotal+shippingfee)-discounted>0 ? (SubTotal+shippingfee)-discounted : 0 : 0 : SubTotal>0 ? (SubTotal+shippingfee)-discounted>0 ? (SubTotal+shippingfee)-discounted : 0 : 0 ?? 0).toFixed(2)}</div>
                            //             </div>
                            //         </div>
                            //     </div>
                            //     {
                            //         modepayment === "DG-Wallet" ?
                            //             <div style={{padding:5, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                            //                 <span>Bal: ${(Number(userInfo.balance) ?? 0).toFixed(2)}</span>
                            //                 {
                            //                     userInfo?.balance>=SubTotal ?
                            //                     <></>
                            //                     :
                            //                     <span style={{color: "red", fontSize:10}}>[Insufficient Balance]</span>
                            //                 }
                            //                 <span></span>
                            //             </div>
                            //         :null
                            //     }
                            //     <div style={{padding:10, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", gap:10}}>
                            //         <div style={{display:"flex", flexDirection:"column", flexGrow:1, justifyContent:"center", alignItems:"center", textAlign:"center", color:"blue", fontSize:13}}> 
                            //             <div>
                            //                 <span className='pi pi-phone'></span> {userInfo.telephone}
                            //             </div>
                            //             <div>
                            //                 <span className='pi pi-map-marker'></span> Shipping Address
                            //             </div>
                            //         </div>
                            //         <div style={{width:180}}>
                            //             {
                            //                 SubTotal-discounted>0 ? 
                            //                 <>
                            //                 {
                            //                     userInfo?.balance>=SubTotal ?
                            //                     <button onClick={()=>null} style={{display:"flex", justifyContent:"center", alignItems:"center", width:180, color: "#fff", fontWeight:600, height:35, borderRadius:5, backgroundColor:"#00b090", border:"1px solid #ddd"}}>Place Order</button>
                            //                     :
                            //                     <>
                            //                     {
                            //                         modepayment === "COD" ?
                            //                         <button onClick={()=>null} style={{display:"flex", justifyContent:"center", alignItems:"center", width:180, color: "#fff", fontWeight:600, height:35, borderRadius:5, backgroundColor:"#00b090", border:"1px solid #ddd"}}>Place Order</button>
                            //                         :
                            //                         <button style={{display:"flex", justifyContent:"center", alignItems:"center", width:180, color: "#fff", fontWeight:600, height:35, borderRadius:5, backgroundColor:"#ddd", border:"1px solid #ddd"}}>Place Order</button>
                            //                     }
                            //                     </>
                            //                 }
                            //                 </>
                            //                 :
                            //                 <button style={{display:"flex", justifyContent:"center", alignItems:"center", width:180, color: "#fff", fontWeight:600, height:35, borderRadius:5, backgroundColor:"#ddd", border:"1px solid #ddd"}}>Place Order</button>
                            //             }
                            //         </div>
                            //     </div>
                            // </div>
                        :
                        <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                            <div onClick={() => window.location.assign('/dashboard')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20 }}>
                                <i className='pi pi-microsoft' />
                                <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Home</span>
                            </div>
                            <div onClick={() => null} style={{ position: "relative", color: "#fff", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#71a3f5", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <i className='pi pi-shopping-bag' />
                                <span style={{ position: "absolute", color: "#fff", padding: 3, top: 2, right: 10, backgroundColor: "red", fontSize: 8, borderRadius: 100 }}>Coming</span>
                                <span style={{ fontSize: 12, color: "#fff" }}>Shop</span>
                            </div>
                            <div onClick={() => window.location.assign('/wallet')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <i className='pi pi-wallet' />
                                <span style={{ fontSize: 12 }}>Wallet</span>
                            </div>
                            <div onClick={() => window.location.assign('/profile')} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                                <i className='pi pi-user' />
                                <span style={{ fontSize: 12, color: "rgb(101 101 101)" }}>Profile</span>
                            </div>
                        </div>
                    }
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
                {/* /.control-sidebar */}
                <div id="sidebar-overlay" />
            </div>
        </>
    );
}

export default Shop;
