import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';

const ProfileTwo = ({ userInfo }) => {
    const { refid } = useParams();
    // console.log(userInfo)
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    // const [userInfo, setUserInfo] = useState(null);
    const [activation_code, setActivationCode] = useState('');
    const [OpenActivateForm, setOpenActivateForm] = useState(false);
    const [Copied, setCopied] = useState({ id: '', status: false });
    const [payoutForm, setPayoutForm] = useState(false);

    const [payoutlist, setPayoutList] = useState([])
    const [balance, setBalance] = useState(0)
    const [totalpayout, setTotalPayout] = useState(0)

    const [insufficientalert, setInsufficientAlert] = useState(false);
    const [incorrectpassword, setIncorrectPassword] = useState(false);


    const [UserSelectedInfo, setUserSelectedInfo] = useState(null);


    const [directInfo, setDirectInfo] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("direct", 1);
        formData.append("user_id", refid);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, refid]);


    useMemo(async () => {
        console.log(refid)

        var formData = new FormData();
        formData.append("profile", 1);
        formData.append("user_id", refid);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data[0])
            setUserSelectedInfo(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, refid]);


    const [downlineInfo, setDownlineInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("downline", 1);
        formData.append("user_id", UserSelectedInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDownlineInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [UserSelectedInfo]);











    const ActivateAccount = async (event) => {
        event.preventDefault();
        if (activation_code == '') {
            alert('Invalid');
            return;
        }
        var formData = new FormData();
        formData.append("activate_account", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        formData.append("username", localStorage.getItem('username') != null ? localStorage.getItem('username') : null);
        formData.append("activation_code", activation_code);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfully Activated",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    window.location.assign('/profile')
                });
            } else if (res.data[0].status == 'invalid_activation') {
                swal({
                    title: "Invalid Code!",
                    text: "please try again",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    // window.location.assign('/profile')
                });
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Invalid!",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    // window.location.assign('/profile')
                });
            }
            // swal({
            //     title: "Done!",
            //     text: "Successfully Activated",
            //     icon: "success",
            //     timer: 1000,
            //     button: false,
            // }).then(() => {
            // localStorage.setItem('user_id', res.data[0].user_id)
            // localStorage.setItem('username', res.data[0].username)
            // localStorage.removeItem('adminUser')
            // window.location.assign('/dashboard')
            // window.location.assign('/profile')
            // setUsername('')
            // setPassword('')
            // });
            // window.location.assign('/profile')
        })
            .catch((err) => {
                console.log(err);
            });
    }

    // swal({
    //     title: "Invalid Code!",
    //     text: "please try again",
    //     icon: "error",
    //     // timer: 1000,
    //     button: false,
    // }).then(() => {
    //     // window.location.assign('/profile')
    // }); 


    const payout_list = async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    // const ClickCopied = (user_id) => {
    //     // alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    //     navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id);
    // }

    const onHistory = () => {

    }

    const [accountName, setAccountName] = useState(false);
    const [accountNumber, setAccountNumber] = useState(false);
    const [amountRequest, setAmountRequest] = useState(false);
    const [password, setPassword] = useState(false);

    const sendRequest = async () => {
        var formData = new FormData();
        formData.append("payout_request", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        formData.append("account_name", accountName);
        formData.append("account_number", accountNumber);
        formData.append("amount", amountRequest);
        formData.append("password", password);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            if (res.data[0].status == 'success') {
                setInsufficientAlert(false)
                setBalance(res.data[0].balance)
                payout_list()
                setPayoutForm(false)
            } else if (res.data[0].status == 'insufficient') {
                setInsufficientAlert(true)
            } else if (res.data[0].status == 'incorect_password') {
                setIncorrectPassword(true)
            }
        }).catch((err) => {

        });

    }

    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setBalance(res.data[0].balance)
            payout_list()
        }).catch((err) => {

        });

    }


    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("total_payout", 1);
        formData.append("user_id", localStorage.getItem('user_id') != null ? localStorage.getItem('user_id') : null);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setTotalPayout(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const btnstatus = (item) => {
        if (item.status == 'deducted') {
            return (<span style={{ color: "red", fontSize: 10 }}>Deducted</span>);
        } else if (item.status == 'cancelled') {
            return (<span style={{ color: "red", fontSize: 10 }}>Cancelled</span>);
        } else if (item.status == 'declined') {
            return (<span style={{ color: "red", fontSize: 10 }}>Declined</span>);
        } else if (item.status == 'approved') {
            return (<span style={{ color: "green", fontSize: 10 }}>Approved</span>);
        } else {
            return (<a onClick={() => CancelRequest(item, 'cancelled')} style={{ width: 100, fontSize: 10, color: "red", textDecoration: "underline" }}>Cancel Payout</a>);
        }
    }

    const Status = (item) => {
        if (item.status == 'pending') {
            return item.status
        }
    }




    const [activitylist, setActivityList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("showactivity", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setActivityList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    useEffect(() => {
        // payout_list()
    }, []);

    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                            <div style={{ paddingLeft: 10, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                <img src={"../../dist/img/drinkgood.png"} style={{ width: 45, height: 45 }} />
                                <span style={{ fontWeight: 600 }}>DrinkGOOD</span>
                            </div>
                            {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                        </div>
                    </>
                    :
                    <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                {/* <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            
                        </div>
                    </div>
                </section> */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-3">
                                {/* Profile Image */}
                                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 0 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                            <img src={ENV?.DOMAIN+UserSelectedInfo?.image} style={{ width: 100, height: 100, borderRadius: 100, border: "1px solid #ddd" }} />
                                        </div>
                                        <div>{UserSelectedInfo?.fullname != null ? UserSelectedInfo?.fullname : 'Loading...'}</div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                            <small style={{ fontSize: 18 }}>{UserSelectedInfo?.rank_status}</small>
                                        </div>
                                        {
                                            userInfo?.direct_sponsor_name != '' ?
                                                <div>
                                                    <small style={{ fontSize: 10 }}>Direct Upline: <span style={{ textDecoration: "underline", fontWeight: 600 }}>{UserSelectedInfo?.direct_sponsor_name}</span></small>
                                                </div>
                                                :
                                                null
                                        }
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                <span style={{ fontSize: 15 }}>{UserSelectedInfo?.direct_count} Direct</span>
                                                {/* <span style={{ width: 5, height: 5, backgroundColor: "#bfbcc2", borderRadius: 100 }}></span>
                                                <span style={{ fontSize: 15 }}>{downlineInfo.length} Downline</span> */}
                                            </div>
                                            {

                                                UserSelectedInfo?.user_id == sessionStorage.getItem('user_id') ?
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                        <span style={{ fontSize: 12 }}>Balance:
                                                            $ {userInfo?.balance}</span>
                                                    </div>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div style={{ paddingBottom: 0 }}>
                                    <div style={{ fontSize: 15, paddingTop: 5, paddingBottom: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <span style={{ fontWeight: 600 }}>Direct/s</span>
                                        {/* {
                                            directInfo.length>=2 ?
                                            <span onClick={()=>window.location.assign('/direct')} style={{fontWeight:600, textDecoration:"underline"}}>show all</span>
                                            :
                                            null
                                        } */}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", height: 75, alignItems: "center", gap: 10, overflowX: "scroll", overflowY: "hidden" }}>
                                        {
                                            directInfo.length > 0 ?
                                                directInfo.map((item, key) => {
                                                    if (Number(key + 1) <= 5) {
                                                        return (
                                                            <div key={key} onClick={() => window.location.assign('/profile/' + item?.user_id)} style={{ width: 50, height: 70, borderRadius: 5, backgroundColor: "rgb(244 246 249)" }}>
                                                                <div style={{ width: 50, height: 50, backgroundColor: "#ddd", borderRadius: 5 }}>
                                                                    <img src={ENV.DOMAIN+item.image} style={{ width: 50, height: 50, borderRadius: 5, border: "1px solid #ddd" }} />
                                                                </div>
                                                                <div style={{ fontSize: 8, wordWrap: "break-word" }}>{item?.fullname}</div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                                :
                                                <div>
                                                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div> */}
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default ProfileTwo;
