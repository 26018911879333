import React from 'react';
import { Helmet } from 'react-helmet-async';

const ShopDetails = () => {
    return (
        <>
        <Helmet>
            <title>Shop</title>
            <meta name="description" content="DrinkGOOD Shop" />
            <link rel="canonical" href='/shop/details' />
        </Helmet>
        <div>
            
        </div>
        </>
    );
}

export default ShopDetails;
