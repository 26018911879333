import { Sidebar } from 'primereact/sidebar';
import React, { useRef, useState } from 'react'
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';

const BackCamera = (props) => {

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const [visible, setVisible] = useState(false);
    const [facingMode, setFacingMode] = useState("environment");
    const [image, setImage] = useState(null);
    const [scannedData, setScannedData] = useState('');
    const webcamRef = useRef(null);

    const videoConstraints = {
        facingMode: "environment",
        width: { min: 230 },
        height: { min: 150 },
        aspectRatio: 0.6666666667,
        transform: `rotate(90deg)`
    };


    const captureImage = () => {
        const imageSrc = webcamRef.current.getScreenshot({ width: 230, height: 150, aspectRatio: 0.6666666667, screenshotQuality: 0.92, transform: `rotate(90deg)` });
        if (imageSrc) {
            setImage(imageSrc);
            // processImage(imageSrc);
            if (props.sidemode === 'front') {
                props.setInputValue(prev=>({...prev, "identification_front_image": imageSrc}))
            } else if (props.sidemode === 'back') {
                props.setInputValue(prev=>({...prev, "identification_back_image": imageSrc}))
            }
            
            setVisible(false);
        }
    };

    const processImage = (imageSrc) => {
        Tesseract.recognize(
            imageSrc,
            'eng',
            { logger: info => console.log(info) } // Optional logging
        ).then((data) => {
            // console.log(data)
            setScannedData(data.text);
        }).catch(err => {
            console.error('OCR error:', err);
        });
    };


    const handleClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);
    return (
        <>
            <Sidebar visible={visible} icons={
                <div style={{ width: "100%", fontWeight: "bolder" }}>Documents</div>
            } blockScroll={true} showCloseIcon={true} position="bottom" style={{ height: 700, borderRadius: 0, padding: 0 }} onHide={() => setVisible(false)}>

                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>

                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            ...videoConstraints,
                            facingMode
                        }}
                        mirrored={false}
                        screenshotQuality={0.92}
                        height = {100 + '%'}
                        width = {100 + '%'}
                        // forceScreenshotSourceSize={true}

                    />
                    <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10}}> 
                        <div style={{width: "33.33%"}}></div>
                        <button style={{width: "33.33%"}} onClick={captureImage} className='btn btn-primary btn-sm'>Capture</button>
                        <button style={{width: "33.33%"}} onClick={()=>setVisible(false)} className='btn btn-danger btn-sm'>Close</button>
                    </div>
                </div>


            </Sidebar>
            {
                image ? (
                    <div onClick={() => setVisible(true)} style={{ width: 230, height: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                        <img src={image} style={{ width: "100%", height: "100%", aspectRatio: 0.6666666667 }} />
                    </div>
                ) : (
                    <div onClick={() => setVisible(true)} style={{ width: 230, height: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}></div>
                )
            }

        </>
    )
}

export default BackCamera;
