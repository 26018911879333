import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const ShopCard = ({item, key, userInfo, cartLoad}) => {
    
    
    const [orderform, setOrderForm] = useState(false);
    const [opendetails, setOpenDetails] = useState(false);
    const [input, setInput] = useState({qty: 0});

    const AddToCart = async () => {
        setOrderForm(false)
        var formData = new FormData();
        formData.append("add_to_cart", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("id", item.id);
        formData.append("qty", input.qty);
        formData.append("price", userInfo.account_status == 1 ? item.mprice : item.srp);
        if (Number(input.qty)!=0) {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                // alert(res.data)
                cartLoad();
                // if (res.data[0].status == 'success') {
                    // swal({
                    //     title: "Done!",
                    //     text: "Successfull saved",
                    //     icon: "success",
                    //     timer: 1000,
                    //     button: false,
                    // }).then(() => {
                    //     // console.log(input)
                    //     setChangePasswordForm(false)
                    //     window.location.assign('/profile')
                    // });
                // } else {

                // }
            });   
        }
    }


    return (
        <>
            <Sidebar visible={orderform} position="bottom" style={{ height: "auto", borderRadius:0, height: "auto" }} blockScroll={true} showCloseIcon={false} onHide={() => setOrderForm(false)}>                
                <>
                    <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                        <img src={ENV.DOMAIN+item.images} style={{ width: "100%", borderRadius:5 }} />
                    </div>
                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                        <span style={{fontSize:20, fontWeight:600}}>{item.product_name}</span>
                    </div>
                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                        <span>Amount</span>
                        {
                            userInfo?.account_status == 1 ?
                            <span style={{fontSize:18, fontWeight:600}}>${item.mprice}</span>
                            :
                            <span style={{fontSize:18, fontWeight:600}}>${item.srp}</span>
                        }
                    </div>
                    <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                        <span>Quantity</span>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                            <input placeholder='0' type='number' name='qty' onChange={(e)=>setInput({...input, [e.target.name]: e.target.value})} style={{width:"100%", height:40, border:"1px solid #ddd", borderBottomLeftRadius:5, borderTopLeftRadius:5, padding:5}} />
                            <button onClick={()=>AddToCart()} style={{width:100, height:40, borderBottomRightRadius:0, borderTopRightRadius:0, border:"1px solid #ddd"}}>
                                <i className='pi pi-check' />
                            </button>
                            <button onClick={()=>setOrderForm(false)} style={{width:100, height:40, borderBottomRightRadius:5, borderTopRightRadius:5, border:"1px solid rgb(212 0 0)", backgroundColor:"rgb(212 0 0)", color:"#fff"}}>
                                <i className='pi pi-times' />
                            </button>
                        </div>
                    </div>
                </>
            </Sidebar>
            {/* /////////////////////////////////// */}
            {/* /////////////////////////////////// */}
            <Sidebar visible={opendetails} position="bottom" style={{ height: "auto", borderRadius:0 }} onHide={() => setOpenDetails(false)}>                
                <div style={{overflowY:"auto", height:window.innerHeight-100}}>
                    <div>
                        <img src='https://scontent.fmnl25-4.fna.fbcdn.net/v/t1.15752-9/383546056_630540338956892_4048569604380770444_n.jpg?_nc_cat=109&ccb=1-7&_nc_sid=8cd0a2&_nc_eui2=AeEEJeY2hjNHGqS3DtxXrCPN0NyxlRfByGXQ3LGVF8HIZaOlo1kilH3kMwVtZfifFkBPTwEg2jHEUqBA1jAjxcIS&_nc_ohc=1aJSB6yW-zQAX_4sBeD&_nc_ht=scontent.fmnl25-4.fna&oh=03_AdSwE-B8u1HmgLtFlVgnOGWqYt-9k10mAPrpGBULb1XOGg&oe=6575D929' style={{ width: "100%" }} />
                    </div>
                    <div>
                        <span>Description</span>
                        <div>
                            <div>Title</div>
                            <div>0.00</div>
                            <div>0.00</div>
                            <div>0.00</div>
                            <div>0.00</div>

                        </div>
                    </div>
                </div>
            </Sidebar>


            {/* //////////////////////////////////////// */}
            {/* //////////////////////////////////////// */}
            <div key={key} style={{ width: "100%", backgroundColor: "#fff", flexGrow: 1, display: "flex", flexDirection: "row", gap: 5, borderRadius:5, border:"1px solid #ddd", boxShadow:"2px 3px 2px 0px rgb(0,0,0,0.1)" }}>
                <div onClick={()=>window.location.assign('/shop/details/'+item.id)}>
                    <img src={ENV.DOMAIN+item.images} style={{ width: 100, borderRadius:5 }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", flexGrow:1, justifyContent:"space-between", padding: 5 }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{fontWeight:600, fontSize:15}}>{item.product_name}</span>
                        {
                            userInfo?.account_status == 1 ?
                            <span style={{fontSize:18, fontWeight:500}}>${item.mprice}</span>
                            :
                            <span style={{fontSize:18, fontWeight:500}}>${item.srp}</span>
                        }
                    </div>
                    <div style={{padding: 0}}>
                        <button onClick={()=>setOrderForm(true)} style={{ width: 110, height: 25, border: "none", borderRadius: 5, fontSize:12 }}>Add to Cart</button>
                    </div>
                </div>
            </div>

            {/* //////////////////////////////////////// */}
            {/* //////////////////////////////////////// */}
            {/* <div key={key} style={{ width: "49%", backgroundColor: "#fff", flexGrow: 1, display: "flex", flexDirection: "column", gap: 5, borderRadius:5, border:"1px solid #ddd", boxShadow:"2px 3px 2px 0px rgb(0,0,0,0.1)" }}>
                <div onClick={()=>window.location.assign('/shop/details/'+item.id)}>
                    <img src={ENV.DOMAIN+item.images} style={{ width: "100%", borderRadius:5 }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", padding: 5 }}>
                    <span style={{fontWeight:600, fontSize:15}}>{item.product_name}</span>
                    {
                        userInfo?.account_status == 1 ?
                        <span style={{fontSize:18, fontWeight:600}}>${item.mprice}</span>
                        :
                        <span style={{fontSize:18, fontWeight:600}}>${item.srp}</span>
                    }
                </div>
                <div style={{padding: 5}}>
                    <button onClick={()=>setOrderForm(true)} style={{ width: "100%", height: 35, border: "none", borderRadius: 5 }}>Add to Cart</button>
                </div>
            </div> */}
        </>
    );
}

export default ShopCard;
