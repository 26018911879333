import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminTransaction = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payoutlist, setPayoutList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("AdminshowTransaction", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])


    useEffect(() => {

    }, [])

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Transaction</h1>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    {
                                        (window.innerWidth <= 768) ?
                                        <>
                                        {
                                            
                                            payoutlist.map((item) => {
                                                return (
                                                    <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", flexDirection:"column", justifyContent: "space-between", width: "100%" }}>
                                                        <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                            <div style={{ width: "85%", fontSize:12 }}>{item.date_time}</div>
                                                            <div style={{ display:"flex", flexGrow:1, textAlign:"right", fontSize:12 }}>
                                                            {
                                                                item.type === 'entry' ?
                                                                <>ENTRY</>
                                                                :null
                                                            }
                                                            {
                                                                item.type === 'matrix_refill' ?
                                                                <>REFILL</>
                                                                :null
                                                            }

                                                            </div>
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                            <span style={{fontWeight:"600", fontSize:15}}>{item.fullname}</span>
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                            {
                                                                item.description != ''?
                                                                <div style={{ width: 150, textAlign: "left" }}>
                                                                    <small style={{fontWeight:"600"}}>Product Name</small>
                                                                    <br />
                                                                    {item.description}</div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                                                            <div style={{ width: 100, textAlign: "left" }}>
                                                                <small style={{ fontWeight: "600" }}>Amount</small>
                                                                <br />
                                                                {/* ${item.amount} */}
                                                                ${Number(Number(item.amount) + Number(item.vat)).toFixed(2)}
                                                            </div>
                                                            <div style={{ width: 50, textAlign: "left" }}>
                                                                <small style={{ fontWeight: "600" }}>Total</small>
                                                                <br />
                                                                ${Number(Number(item.amount) + Number(item.vat)).toFixed(2)}
                                                            </div>
                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }
                                        </>
                                        :
                                        <>
                                        {
                                            payoutlist.map((item) => {
                                                return (
                                                    <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center" }}>
                                                            <div style={{ width: 150 }}>{item.date_time}</div>
                                                            <div style={{ display:"flex", flexDirection:"column" }}>
                                                                <div style={{display:"flex", flexDirection:"row"}}>
                                                                    <span style={{fontWeight:"600", fontSize:20}}>{item.fullname}</span>
                                                                </div>
                                                                <div style={{display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center"}}>
                                                                    <div style={{ width: 100, textAlign: "left" }}>
                                                                        <small style={{fontWeight:"600"}}>Amount</small>
                                                                        <br />
                                                                        $ {item.amount}</div>
                                                                    {/* <div style={{ width: 100, textAlign: "left" }}>
                                                                        <small style={{fontWeight:"600"}}>Vat</small>
                                                                        <br />
                                                                        $ {item.vat}</div> */}
                                                                    <div style={{ width: 50, textAlign: "left" }}>
                                                                        <small style={{fontWeight:"600"}}>Total</small>
                                                                        <br />
                                                                        $ {Number(Number(item.amount)+Number(item.vat)).toFixed(2)}
                                                                        </div>
                                                                        {
                                                                            item.description != ''?
                                                                            <div style={{ width: 150, textAlign: "left" }}>
                                                                                <small style={{fontWeight:"600"}}>Product Name</small>
                                                                                <br />
                                                                                {item.description}</div>
                                                                            :
                                                                            null
                                                                        }
                                                                    <div style={{ width: 150, textAlign: "left" }}>
                                                                        <small style={{fontWeight:"600"}}>Type</small>
                                                                        <br />
                                                                        {item.type}</div>
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminTransaction;
