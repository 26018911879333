import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';

const Notifications = ({ userInfo }) => {
    document.body.className = 'sidebar-mini';

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [downlineInfo, setDownlineInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("downline", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setDownlineInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);


    return (
        <div className="wrapper">
            {/* Navbar */}
            <Header />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Notifications</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                {/* <div className="card-header p-2"> */}
                                {/* <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul> */}
                                {/* </div> */}
                                {/* /.card-header */}
                                {
                                    !useCheckMobileScreen() ?
                                        <div className="card">
                                            {/* <div className="card-body table-responsive">
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>Fullname</th>
                                                            <th>Sponsor</th>
                                                            <th>Placement</th>
                                                            <th>Rank</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            downlineInfo.map((item, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {item.fullname}
                                                                            <br />
                                                                            <small>{item.direct_user_id != '' ? item.direct_user_id : ''}</small>
                                                                        </td>
                                                                        <td>
                                                                            {item.sponsor_name}
                                                                            <br />
                                                                            <small>{item.sponsor_user_id != '' ? item.sponsor_user_id : ''}</small>
                                                                        </td>
                                                                        <td>
                                                                            {item.placement_name}
                                                                            <br />
                                                                            <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                        </td>
                                                                        <td>{item.rank_status}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                        :
                                        <>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {/* {
                                                    downlineInfo.map((item, key) => {
                                                        return (
                                                            <div className="card" style={{ padding: 10 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div>
                                                                        <b>{item.fullname}</b>
                                                                        <br />
                                                                        <small>{item.direct_user_id != '' ? item.direct_user_id : ''}</small>
                                                                    </div>
                                                                    <small>{item.rank_status}</small>
                                                                </div>
                                                                <div style={{border:"0.5px solid #ddd"}}></div>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                        <small><b>Sponsored</b></small>
                                                                        <small>{item.sponsor_name}</small>
                                                                        <small>{item.sponsor_user_id != '' ? item.sponsor_user_id : ''}</small>
                                                                    </div>
                                                                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                        <small><b>Placement</b></small>
                                                                        <small>{item.placement_name}</small>
                                                                        <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                } */}
                                            </div>
                                        </>
                                }

                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer>
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Notifications;
