import React from 'react';

const AdminTransactionMatrixRefill = ({userInfo}) => {
    return (
        <div>
            
        </div>
    );
}

export default AdminTransactionMatrixRefill;
