import React, { useEffect, useRef, useState } from 'react';

const formatTime = (time) => {
    let minutes = Math.floor(time / 60)
    let seconds = Math.floor(time - minutes * 60)
    if (minutes <= 10) minutes = String(minutes).padStart(2,0);
    if (seconds <= 10) seconds = String(seconds).padStart(2,0);
    return minutes+':'+seconds
}

const Countdown = ({second, setResendAction, setTime}) => {
    const [countdown, setCountdown] = useState(second)
    const timeid = useRef()

    useEffect(()=>{
        timeid.current = setInterval(() => {
            setCountdown(prev => prev - 1)
        }, 1000)
        return () => clearInterval(timeid.current)
    },[])

    useEffect(() => {
        if(countdown <= 0) {
            clearInterval(timeid.current)
            setResendAction(false)
            setTime(formatTime(countdown))
            // alert('END')
        }
    },[countdown])

    return formatTime(countdown)
    // return (
    //     <div>
    //         Count Down: {formatTime(countdown)}
    //     </div>
    // );
}

export default Countdown;
