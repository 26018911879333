import React from 'react';

const Maintenance = ({userInfo}) => {
    document.body.style.backgroundImage = '';
    document.body.style.backgroundRepeat = '';
    document.body.style.backgroundSize = '';
    document.body.style.backgroundPosition = '';
    // if (userInfo?.maintenance == false) {
    //     window.location.assign('/dashboard')
    // }
    return (
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:window.innerHeight}}>
            {/* <div style={{position:"absolute", width:"100%", top:0, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                <img src={require('./384540628_1008238667298426_6685715244998088129_n.jpg')} style={{width:100, height:100, borderRadius:100}} />
            </div> */}
            <div>
                <img src={require('./application-supporttomaintenace-img.jpg')} style={{width:"100%", height:"auto", borderRadius:0}} />
                <div style={{fontWeight:800, textAlign:"center", fontSize:30}}>System</div>
                <div style={{fontWeight:800, textAlign:"center", fontSize:30}}>Maintenance!</div>
            </div>
            <div>
                {
                    userInfo?.maintenance_msg
                }
                {
                    userInfo?.maintenance_date
                }
            </div>
        </div>
    );
}

export default Maintenance;
