import React, { useMemo, useState } from 'react';
import EntryPackage from './EntryPackage';
import axios from 'axios';
import ENV from '../../ENV';
import AddWallet from './AddWallet';
import MemberCard from './MemberCard';

const MemberList = (props) => {

    const [users, setUsers] = useState([]);
    const [totalusers, setTotalUsers] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setUsers(res.data.filter((i)=>i?.user_id.toLowerCase().includes(props.search.trim().toLowerCase()) || i?.fullname.toLowerCase().includes(props.search.trim().toLowerCase())))
            setTotalUsers(res.data)
            // setUsers(res.data.filter((i)=>i?.user_id !== '000000000'))
        })
            .catch((err) => {
                console.log(err);
            });
    }, [props]);

    return (
        <>
            <div>
                <span>Total Registered: <span style={{fontWeight: "bolder"}}>{[...totalusers].filter((i)=>i).length}</span></span>
            </div>
            {
                [...users].map((item) => {
                    return (
                        <MemberCard item={item} setUserDetails={props.setUserDetails} />
                    )
                }).slice(0, 7)
            }
        </>
    );
}

export default MemberList;
