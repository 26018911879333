import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../../ENV';
import swal from 'sweetalert';

const ActivateButton = ({ showStatus, setShowStatus, item }) => {

    const [inputvalue, setInputValue] = useState({user_id: null, password: null})
    const [isloading, setIsloading] = useState(false)
    

    const ActivateAccount = async (item) => {
        setIsloading(true);
        if (inputvalue.password != '') {
            var formData = new FormData();
            formData.append("activate_by_admin", 1);
            formData.append("user_id", item.user_id);
            formData.append("password", inputvalue.password);
            formData.append("adminusername", localStorage.getItem('adminusername'));
    
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                if (res.data == 'success') {
                    swal({
                        title: item.fullname,
                        text: "Successfull Activated",
                        icon: "success",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        console.log(inputvalue)
                        setShowStatus(0)
                        setIsloading(false)
                    });
                } else if (res.data == 'invalid_unpaid_account') {
                    swal({
                        title: item.fullname,
                        text: "Unsuccessfull",
                        icon: "error",
                        timer: 2000,
                        button: false,
                    }).then(() => {
                        setIsloading(false)
                    });
                }
            });
        }
    }
    
    if(showStatus == 0) {
        if (localStorage.getItem('adminusername') == 'master_admin' || localStorage.getItem('adminusername') == 'account') {
            return (
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", gap:5}}>
                    <input type='password' name='password' style={{borderRadius:5, border:"2px solid #ddd", padding:5}} placeholder='Enter Password' onChange={(e)=>setInputValue({...inputvalue, user_id: item.user_id, [e.target.name]: e.target.value})} />
                    <button onClick={()=>isloading ? null : ActivateAccount(item)} style={{borderRadius:5, border:"2px solid green", backgroundColor:isloading ? "#ddd" : "orange", color:isloading ? "#000" : "#fff", fontWeight:600}}>{isloading ? "please wait...." : "Activate"}</button>
                </div>
            );
        } else {
            return(<></>)
        }
    }
    return(<></>);
}

export default ActivateButton;
