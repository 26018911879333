import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import React, { useMemo, useState } from 'react';

const MatrixRefill = (props) => {
    const [visible, setVisible] = useState(false)
    const [buttonselection, setButtonsSelection] = useState({})
    const [inputvalue, setInputValue] = useState({ "refill_type": props.item.account_status === '1' ? "" : "regular", "set_desciption": "" })
    const onHide = () => {
        setVisible(false)
        setButtonsSelection({})
        setInputValue({ "refill_type": "", "set_desciption": "", puc_id: 0 })
    }

    const CompleteOrder = async () => {
        if (inputvalue.refill_type !== '' && inputvalue.set_desciption !== '') {
            var formData = new FormData();
            formData.append("completeOrder", 1);
            formData.append("inputvalue", JSON.stringify({ ...inputvalue, ...props.item, puc_id: 0 }));
            await axios({
                method: "post",
                url: 'http://localhost/drinkgood/process.php',
                // url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                console.log(res.data);
                setVisible(false)
                setInputValue({ "refill_type": "", "set_desciption": "", puc_id: 0 })
                props.LoadTransaction()
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }


    return (
        <>
            <Dialog
                footer={
                    <div>
                        <button onClick={() => CompleteOrder()} className='btn btn-success btn-sm'>Complete Order</button>
                    </div>
                }
                visible={visible} style={{ width: '30rem' }} header={"Matrix Refill"} breakpoints={{ '960px': '75vw', '641px': '90vw' }} modal className="p-fluid" onHide={() => onHide()}>
                {
                    props.item.account_status === '1' ?
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", alignItems: "center", paddingBottom: 10 }}>
                            <button onClick={() => setInputValue(prev => ({ ...prev, "refill_type": "regular" }))} className={inputvalue.refill_type === 'regular' ? 'btn btn-primary btn-sm' : 'btn btn-outline-dark btn-sm'} style={{ width: "50%" }}>
                                <div>Regular</div>
                                <div>788</div>
                            </button>
                            <button onClick={() => setInputValue(prev => ({ ...prev, "refill_type": "promo" }))} className={inputvalue.refill_type === 'promo' ? 'btn btn-primary btn-sm' : 'btn btn-outline-dark btn-sm'} style={{ width: "50%" }}>
                                <div>Promo</div>
                                <div>688</div>
                            </button>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", alignItems: "center", paddingBottom: 10 }}>
                            <button onClick={() => setInputValue(prev => ({ ...prev, "refill_type": "regular" }))} className={inputvalue.refill_type === 'regular' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ width: "50%" }}>Regular</button>
                        </div>
                }
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <button className={inputvalue.set_desciption === '1 Set Container' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "1 Set Container", "qty": 1 }))}>1 Set Container</button>
                    {/* <button className={inputvalue.set_desciption === '2 Set Container' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "2 Set Container", "qty": 2 }))}>2 Set Container</button> */}
                    {/* <button className={inputvalue.set_desciption === '3 Set Container' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "3 Set Container", "qty": 3 }))}>3 Set Container</button> */}
                    <button className={inputvalue.set_desciption === '1 Set Liter Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "1 Set Liter Pack", "qty": 1 }))}>1 Set Liter Pack</button>
                    {/* <button className={inputvalue.set_desciption === '2 Set Liter Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "2 Set Liter Pack", "qty": 2 }))}>2 Set Liter Pack</button> */}
                    {/* <button className={inputvalue.set_desciption === '3 Set Liter Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "3 Set Liter Pack", "qty": 3 }))}>3 Set Liter Pack</button> */}
                    <button className={inputvalue.set_desciption === '1 Set Gallon Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "1 Set Gallon Pack", "qty": 1 }))}>1 Set Gallon Pack</button>
                    {/* <button className={inputvalue.set_desciption === '2 Set Gallon Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "2 Set Gallon Pack", "qty": 2 }))}>2 Set Gallon Pack</button> */}
                    {/* <button className={inputvalue.set_desciption === '3 Set Gallon Pack' ? 'btn btn-primary btn-sm btn-block' : 'btn btn-outline-dark btn-sm btn-block'} style={{ fontSize: 20 }} onClick={() => setInputValue(prev => ({ ...prev, "set_desciption": "3 Set Gallon Pack", "qty": 3 }))}>3 Set Gallon Pack</button> */}
                </div>

            </Dialog>
            {
                props.item.account_status === '1' ?
                    <button onClick={() => setVisible(true)} className='btn btn-info btn-xs'>Matrix Refill</button>
                    :
                    <button onClick={() => setVisible(true)} className='btn btn-info btn-xs'>Refill</button>
            }
        </>
    );
}

export default MatrixRefill;
