import { BrowserRouter as Router, Route, Link, useRouteMatch, useParams, Switch } from "react-router-dom";
import React from 'react';
// import Homepage from "./components/Homepage";
import Login from './screens/Login'
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Profile from "./screens/Profile";
import axios from "axios";
import ENV from "./screens/ENV";
import { useEffect, useMemo, useState } from "react";
// import firebase from './firebase'
import Downline from "./screens/Downline";
import Direct from "./screens/Direct";
import Unpaid from "./screens/Unpaid";
import Wallet from "./screens/Wallet";
import AdminLogin from "./screens/AdminLogin";
import AdminDashboard from "./screens/AdminDashboard";
import AdminCodeGenerator from "./screens/AdminCodeGenerator";
import AdminPayout from "./screens/AdminPayout";
import AdminSales from "./screens/AdminSales";
import AdminMember from "./screens/AdminMembers";
import AdminCode from "./screens/AdminCode";
import Notifications from "./screens/Notifications";
import MatrixGenealogy from "./screens/MatrixGenealogy";
import PersonalGenealogy from "./screens/PersonalGenealogy";
import AdminPayoutHistory from "./screens/AdminPayoutHistory";
import AdminCodeUsed from "./screens/AdminCodeUsed";
import Unilevel from "./screens/Unilevel";
import Matrix from "./screens/Matrix";
import AdminMatrixGenealogy from "./screens/AdminMatrixGenealogy";
import AdminPersonalGenealogy from "./screens/AdminPersonalGenealogy";
import AdminTransaction from "./screens/AdminTransaction";
import Maintenance from "./screens/Maintenance";
import ProfileTwo from "./screens/ProfileTwo";
import { ProgressSpinner } from "primereact/progressspinner";
import Shop from "./screens/Shop";
import ShopDetails from "./screens/ShopDetails";
import PreSignUpList from "./screens/PreSignUpList";
import AdminOrderNew from "./screens/AdminOrderNew";
import AdminOrderPending from "./screens/AdminOrderPending";
import AdminTransactionPayin from "./screens/AdminTransactionPayin";
import AdminTransactionMatrixRefill from "./screens/AdminTransactionMatrixRefill";
import AdminPayinSummary from "./screens/AdminPayinSummary";
import AdminReportPayout from "./screens/AdminReportPayout";
import AdminReportSales from "./screens/AdminReportSales";
import AdminReportEntry from "./screens/AdminReportEntry";
import Pos from "./screens/Pos";
import NewsFeed from "./screens/NewsFeed";
import PickupCenter from "./screens/PickupCenter";
import CardScanner from "./screens/CardScanner";
import AutoCapture from "./screens/AutoCapture";
import KYCForm from "./screens/KYCForm";
import MainPOS from "./screens/MainPOS";
import AdminKYCApplication from "./screens/AdminKYCApplication";
import AdminAccessAccount from "./screens/AdminAccessAccount";
import MatrixUnilevel from "./screens/MatrixUnilevel";
// import firebase from "./firebase";


function App() {
    // localStorage.removeItem('username')
    
    const [userInfo, setUserInfo] = useState(null);
    const [notification, setNotification] = useState(null);
    const [isLoading, setisLoading] = useState(true);
    const [timeout, settimeout] = useState(0);

    
    useEffect(async()=>{     
        
        var formData = new FormData();
        formData.append("profile", 1);
        formData.append("user_id", localStorage.getItem('user_id'));

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            if(res.data[0]) {
                setUserInfo(res.data[0])
                setisLoading(false)
            } 
            // else {                
            //     setisLoading(false)
            //     localStorage.removeItem('user_id')
            //     localStorage.removeItem('username')
            // }
        })
        .catch((err) => {
            console.log(err);
        });
    },[setUserInfo]);



    if (userInfo?.maintenance == true) {
        return (
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/login">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/register/:refid">
                        <Register />
                    </Route>
                    <Route exact={true} path="/dashboard">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/profile">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/profile/:refid">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/direct">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/downline">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/unilevel">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/matrix">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/personal/genealogy">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/matrix/unilevel">
                        <MatrixUnilevel userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/matrix/genealogy">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/unpaid">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/wallet">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/notifications">
                        <Maintenance userInfo={userInfo} />
                    </Route>
                    <Route exact={true} path="/administrator">
                        {localStorage.getItem('adminusername') != null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/dashboard">
                        {localStorage.getItem('adminusername') != null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/members">
                        {localStorage.getItem('adminusername') != null ? <AdminMember userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/members/genealogy/matrix/:user_id">
                        {localStorage.getItem('adminusername') != null ? <AdminMatrixGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/members/genealogy/personal/:user_id">
                        {localStorage.getItem('adminusername') != null ? <AdminPersonalGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions">
                        {localStorage.getItem('adminusername') != null ? <AdminTransaction userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions/payin">
                        {localStorage.getItem('adminusername') != null ? <AdminTransactionPayin userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions/matrixrefill">
                        {localStorage.getItem('adminusername') != null ? <AdminTransactionMatrixRefill userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/payout">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/request">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/history">
                        {localStorage.getItem('adminusername') != null ? <AdminPayoutHistory userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/request/:id">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/sales">
                        {localStorage.getItem('adminusername') != null ? <AdminSales userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code">
                        {localStorage.getItem('adminusername') != null ? <AdminCode userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code/generate">
                        {localStorage.getItem('adminusername') != null ? <AdminCodeGenerator userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code/used">
                        {localStorage.getItem('adminusername') != null ? <AdminCodeUsed userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                </Switch>
            </Router>
        )
    }

    if(isLoading){
        if(localStorage.getItem('user_id') == null){
            setTimeout(()=>{
                setisLoading(false)
            }, 2000);
            return(
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:window.innerHeight}}>
                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    <div>Please wait...</div>
                </div>
            )
        } else {
            // setTimeout(()=>{
            //     setisLoading(false)
            //     localStorage.removeItem('user_id')
            //     localStorage.removeItem('username')
            //     window.location.assign('/')
            // }, 2000);
            return(
                <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%", backgroundColor:"#fff", height:window.innerHeight}}>
                    <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                    <div>Please wait...</div>
                </div>
            )
        }
    }
    
    return (
        // <AppProvider>
            <Router>
                <Switch>
                    <Route exact={true} path="/">
                        <NewsFeed userInfo={userInfo} />
                        {/* {localStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />} */}
                    </Route>
                    <Route exact={true} path="/refer/:refid">
                        <NewsFeed userInfo={userInfo} />
                    </Route>
                    {/* <Route exact={true} path="/">
                        {localStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route> */}
                    <Route exact={true} path="/login">
                        {localStorage.getItem('username') == null ? <Login /> : <Dashboard userInfo={userInfo} />}
                    </Route>
                    {/* <Route exact={true} path="/register"></Route> */}
                    <Route exact={true} path="/register/:refid">
                        <Register />
                    </Route>
                    <Route exact={true} path="/dashboard">
                        {localStorage.getItem('username') != null ? <Dashboard userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/profile">
                        {localStorage.getItem('username') != null ? <Profile userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/profile/:refid">
                        {localStorage.getItem('username') != null ? <ProfileTwo userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/pre-sign-up">
                        {localStorage.getItem('username') != null ? <PreSignUpList userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/direct">
                        {localStorage.getItem('username') != null ? <Direct userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/downline">
                        {localStorage.getItem('username') != null ? <Downline userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/unilevel">
                        {localStorage.getItem('username') != null ? <Unilevel userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/matrix">
                        {localStorage.getItem('username') != null ? <Matrix userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/matrix/unilevel">
                        {localStorage.getItem('username') != null ? <MatrixUnilevel userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/personal/genealogy">
                        {localStorage.getItem('username') != null ? <PersonalGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/matrix/genealogy">
                        {localStorage.getItem('username') != null ? <MatrixGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/unpaid">
                        {localStorage.getItem('username') != null ? <Unpaid userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/wallet">
                        {localStorage.getItem('username') != null ? <Wallet userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/notifications">
                        {localStorage.getItem('username') != null ? <Notifications userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/shop">
                        {localStorage.getItem('username') != null ? <Shop userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/shop/details/:pid">
                        {localStorage.getItem('username') != null ? <ShopDetails userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/pickupcenter">
                        {localStorage.getItem('username') != null ? <PickupCenter userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/pickupcenter/:id">
                        {localStorage.getItem('username') != null ? <PickupCenter userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/kyc">
                        {localStorage.getItem('username') != null ? <KYCForm userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/pos">
                    {
                        localStorage.getItem('username') != null ?
                            <Pos userInfo={userInfo} />
                            :
                            <Login />
                    }
                    </Route>




                    <Route exact={true} path="/administrator">
                        {localStorage.getItem('adminusername') != null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/dashboard">
                        {localStorage.getItem('adminusername') != null ? <AdminDashboard userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/members">
                        {localStorage.getItem('adminusername') != null ? <AdminMember userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/user_account">
                    {
                        localStorage.getItem('adminusername') != null ?
                            localStorage.getItem('admin_id') === '2' || localStorage.getItem('admin_id') === '3' ?
                                <AdminAccessAccount userInfo={userInfo} />
                                : null
                            : <AdminLogin />
                    }
                    </Route>
                    <Route exact={true} path="/administrator/members/genealogy/matrix/:user_id">
                        {localStorage.getItem('adminusername') != null ? <AdminMatrixGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/members/genealogy/personal/:user_id">
                        {localStorage.getItem('adminusername') != null ? <AdminPersonalGenealogy userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions">
                        {localStorage.getItem('adminusername') != null ? <AdminTransaction userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions/payin">
                        {localStorage.getItem('adminusername') != null ? <AdminTransactionPayin userInfo={userInfo} /> : <Login />}
                    </Route>
                    <Route exact={true} path="/administrator/transactions/matrixrefill">
                        {localStorage.getItem('adminusername') != null ? <AdminTransactionMatrixRefill userInfo={userInfo} /> : <Login />}
                    </Route>
                    {/* <Route exact={true} path="/administrator/generator/code">
                        {localStorage.getItem('adminusername') != null ? <AdminCodeGenerator userInfo={userInfo} /> : <AdminLogin />}
                    </Route> */}
                    <Route exact={true} path="/administrator/payout">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payin/summary">
                        {localStorage.getItem('adminusername') != null ? <AdminPayinSummary userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/request">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/history">
                        {localStorage.getItem('adminusername') != null ? <AdminPayoutHistory userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/payout/request/:id">
                        {localStorage.getItem('adminusername') != null ? <AdminPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/sales">
                        {localStorage.getItem('adminusername') != null ? <AdminSales userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/report/payout">
                        {localStorage.getItem('adminusername') != null ? <AdminReportPayout userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/report/sales">
                        {localStorage.getItem('adminusername') != null ? <AdminReportSales userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/report/entry">
                        {localStorage.getItem('adminusername') != null ? <AdminReportEntry userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code">
                        {localStorage.getItem('adminusername') != null ? <AdminCode userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code/generate">
                        {localStorage.getItem('adminusername') != null ? <AdminCodeGenerator userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/code/used">
                        {localStorage.getItem('adminusername') != null ? <AdminCodeUsed userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    {/* ///////////////////////// */}
                    <Route exact={true} path="/administrator/order/new">
                        {localStorage.getItem('adminusername') != null ? <AdminOrderNew userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/order/pending">
                        {localStorage.getItem('adminusername') != null ? <AdminOrderPending userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    <Route exact={true} path="/administrator/kyc/list">
                        {localStorage.getItem('adminusername') != null ? <AdminKYCApplication userInfo={userInfo} /> : <AdminLogin />}
                    </Route>
                    
                    <Route exact={true} path="/CardScanner">
                        <AutoCapture/>
                    </Route>
                    <Route exact={true} path="/main/pos">
                        <MainPOS/>
                    </Route>
                </Switch>
            </Router>
        // </AppProvider>
    )
}

export default App;
