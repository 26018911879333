import React, { useEffect, useState } from 'react';
import Stories from './extra/Stories';
import { useHistory, useParams } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';

const NewsFeed = (props) => {

    const [topdisplay, setTopDisplay] = useState({})
    const history = useHistory()
    const { refid } = useParams();

    const images = [
        {
            images: "437129288_3580748438805525_986689946208853090_n.jpg", sort: 0
        },
        {
            images: "439332569_7253815174745446_8198774541587578735_n.jpg", sort: 1
        },
        {
            images: "440988588_1607512876756804_2164060958059688555_n.jpg", sort: 2
        }
    ]

    useEffect(()=>{
        const random = Math.floor(Math.random() * 3);
        // alert(random)
        const setimage = images.filter((e)=>e.sort == random);
        setTopDisplay(setimage[0]?.images)
    }, [])

    console.log(refid)

    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const productTemplate = (product) => {
        return (
            <div style={{ width: 100, height: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                <div style={{width: 100, height: 120}}>
                    <img src="https://drdecuplepi.org/dist/img/drinkgood.png" style={{ width: 100, height: 100 }} />
                </div>
                <div style={{padding: 5}}>
                    PUC...
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <div style={{ width: window.innerWidth < 750 ? "100%" : "50%", height: "100vh", backgroundColor: "#ddd", border: "1px solid #000", overflow: "auto" }} className='hidescroll'>
                {/* <div style={{ width: "100%", height: 50, borderBottom: "1px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 5 }}>
                        <img src="https://drdecuplepi.org/dist/img/drinkgood.png" style={{ width: 45, height: 45 }} />
                        <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                        </div>
                    </div>
                </div> */}
                <div style={{backgroundColor: "#fff"}}>
                    {/* <div>
                        <img src='https://drdecuplepi.org/dist/img/new20240717.PNG' style={{width: "100%", height: "100%"}} />
                    </div> */}
                    <div>
                        <video autoPlay loop muted style={{width: "100%"}}>
                            <source src='https://drdecuplepi.org/dist/video/17c5f9de-2c9a-4563-aae7-8a11860a40aa.mp4' type="video/mp4"/>
                        </video>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center", height: 100}}>
                        <div><span style={{fontSize: 20, fontWeight: "bolder", color: "#6262d1"}}>Dr. BiOPitimized</span></div>
                        <div><span style={{fontSize: 20, fontWeight: "bolder", color: "#6262d1"}}>Nano-Cluster Structured Water</span></div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 5}}>
                        <span style={{fontWeight: "bold", fontSize: 18}}>Dr. Fereydoon Batmanghelidj</span>
                        <span style={{fontWeight: "normal", fontSize: 15}}>"YOUT ARE NOT SICK YOU ARE JUST THIRSTY!"</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 5}}>
                        <span style={{fontWeight: "bold", fontSize: 18}}>Dr. Fereydoon Batmanghelidj</span>
                        <span style={{fontWeight: "normal", fontSize: 15}}>"No Virus and Bacteria can Survive in an Oxygen Rich Environment!"</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", padding: 5}}>
                        <span style={{fontWeight: "bold", fontSize: 18}}>Dr. Rain Docere</span>
                        <span style={{fontWeight: "normal", fontSize: 15}}>"IntraCellular Hydration is not only important, it is vital to our health."</span>
                    </div>
                </div>




                <div style={{padding: 5, backgroundColor: "#fff", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        <span style={{fontWeight: "bolder", display: "flex", flexDirection: "column"}}>
                            <span style={{fontSize: 20}}>Welcome</span>
                            {/* <span>{props.userInfo?.fullname}</span> */}
                        </span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10}}>
                    {
                        localStorage.getItem('username') != null ?
                        <button onClick={()=>window.location.assign("/dashboard")} className='btn btn-info btn-sm'>Go to Dashboard</button>
                        :
                        <>
                            {
                                refid!=undefined ?
                                    <button onClick={()=>window.location.assign(`/register/${refid}`)} className='btn btn-info btn-sm'>Register</button>
                                :
                                null
                            }
                            <button onClick={()=>window.location.assign("/login")} className='btn btn-warning btn-sm'>Login</button>
                        </>
                    }
                    </div>
                </div>
                {/* <div style={{ padding: 5, backgroundColor: "#fff"}}>
                    <div style={{ width: "100%", height: 150, borderRadius: 5, border: "1px solid #fff",
                                backgroundImage: refid!=undefined ? `url(/${topdisplay})` : `url(${topdisplay})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "contain",
                                backgroundPosition: "center", 
                                flex: 1,
                                aspectRatio: 1}}>

                    </div>
                </div> */}
                <div style={{display: "none"}}>
                     
                </div>
                {/* <div style={{ width: "100%", backgroundColor: "#fff", paddingBottom: 10 }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>Our Pick-up Center</div>
                    <div style={{ width: "100%", overflowX: "scroll", height:155 }} className='hidescroll'>
                        <div style={{marginLeft: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5}}>
                            {
                                [1, 2, 3, 4, 5].map((item, key) => {
                                    return (
                                        <div style={{ width: 100, height: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                            <div style={{width: 100, height: 120}}>
                                                <img src="https://drdecuplepi.org/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg" style={{ width: 100, height: 100 }} />
                                            </div>
                                            <div style={{padding: 5}}>
                                                PUC...
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div> */}
                <div style={{ width: "100%", backgroundColor: "#fff", paddingBottom: 10 }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>Our Products</div>
                    <div style={{ width: "100%", overflowX: "scroll", height:155 }} className='hidescroll'>
                        <div style={{marginLeft: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5}}>                            
                            {
                                [
                                    {
                                        "img": "https://drdecuplepi.org/dist/our_products/454789008_1027747119358857_2115075681948699490_n.jpg",
                                    },
                                    {
                                        "img": "https://drdecuplepi.org/dist/img/product/368635171_1428378178086777_6425695059322588817_n.jpg"
                                    },
                                    {
                                        "img": "https://drdecuplepi.org/dist/img/product/377149420_1068326037636354_2663609736674720865_n.jpg"
                                    }
                                ].map((item, key) => {
                                    return (
                                        <div key={key} style={{ width: 100, height: 150, borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff" }}>
                                            <div style={{width: 100, height: 150}}>
                                                <img src={item.img} style={{ width: 100, height: 150 }} />
                                            </div>
                                            {/* <div style={{padding: 5}}>
                                                Products
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div style={{ padding: 5, display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff" }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>Dr. BiOPitimized Testimonies</div>
                    {
                        [
                            {
                                "name": "Ms. Welah Mae Antonio", 
                                "img": "https://drdecuplepi.org/dist/img/avatar/1701003187.Blackwarrior.png",
                                "msg": "Get Healed from Allergies, Amoebiasis and Dysmenorrhea just by drinking Dr. BiOptimized Water!"
                            },
                            {
                                "name": "Mr. Luther Rama", 
                                "img": "https://drdecuplepi.org/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg",
                                "msg": "4 years undergoing Dialysis 2x a week insulin injections dependent, but get healed after drinking Dr. BiOPtimized Water."
                            },
                            {
                                "name": "Ma'am Evelyn N. Palmes", 
                                "img": "https://drdecuplepi.org/static/media/c456944a-87e8-47d5-8d2d-eb6645f0a7b2.50aa7972df89f9234485.jpg",
                                "msg": "Get Healed from her Hypertension and stop taking maintenance for 25 years."
                            },
                        ].map((item, key) => {
                            return (
                                <div key={key} style={{ width: "100%", borderRadius: 5, border: "1px solid #ddd" }}>
                                    <div style={{width: "100%", padding: 5, display: "flex", flexDirection: "row"}}>
                                        <div style={{width: "30%", height: 100, border: "1px solid #ddd"}}>
                                            <img src={item.img} style={{width: "100%", height: "100%"}} />
                                        </div>
                                        <div style={{width: "70%", padding: 5, display: "flex", flexDirection: "column", minHeight: 100, border: "1px solid #fff"}}>
                                            <span style={{fontWeight: "bolder"}}>{item.name}</span>
                                            <span style={{fontSize: 12}}>{item.msg}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                {/* <div style={{ padding: 5, display: "flex", flexDirection: "column", gap: 5, backgroundColor: "#fff" }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>Updates</div>
                    {
                        [1, 2, 3, 4, 5].map(() => {
                            return (
                                <div style={{ width: "100%", height: 200, borderRadius: 5, border: "1px solid #000" }}>

                                </div>
                            )
                        })
                    }
                </div>

                <div style={{ padding: 5, backgroundColor: "#fff" }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>About</div>
                    <div style={{ width: "100%", height: 200, borderRadius: 5, border: "1px solid #000" }}>

                    </div>
                </div>

                <div style={{ padding: 5, backgroundColor: "#fff" }}>
                    <div style={{fontWeight: "bolder", fontSize: 20, padding:5}}>Schedules</div>
                    <div style={{ width: "100%", height: 200, borderRadius: 5, border: "1px solid #000" }}>

                    </div>
                </div> */}

            </div>
        </div>
    );
}

export default NewsFeed;
