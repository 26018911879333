import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';
import EntryPackage from './component/main_pos/EntryPackage';
import MatrixRefill from './component/main_pos/MatrixRefill';
import MemberList from './component/main_pos/MemberList';
import AddWallet from './component/main_pos/AddWallet';
import ActivateAccount from './component/main_pos/ActivateAccount';
import ActivatePickupCenter from './component/main_pos/ActivatePickupCenter';

const MainPOS = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    
    const [users, setUsers] = useState([]);
    const [totalusers, setTotalUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [Copied, setCopied] = useState({id:'', status:false});
    const [userdetails, setUserDetails] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [topMenu, setTopMenu] = useState({"menu":""});



    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [directInfo, setDirectInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("direct", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    const LoadTransaction = async () => {
        var formData = new FormData();
        formData.append("allTransactions", 1);

        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/process.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setTransactions(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    useMemo(async () => {
        var formData = new FormData();
        formData.append("allTransactions", 1);

        await axios({
            method: "post",
            url: 'http://localhost/drinkgood/process.php',
            // url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setTransactions(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("users", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setUsers(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const itemList = [
        {id: 1, name: "", price: 0, promo: ""},
        {id: 2, name: "", price: 0, promo: ""},
        {id: 3, name: "", price: 0, promo: ""},
        {id: 4, name: "", price: 0, promo: ""},
        {id: 5, name: "", price: 0, promo: ""}
    ]

    useEffect(() => {

    }, [])

    

    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }


    return (
        <div className="wrapper">
            {/* Navbar */}
            {/* <Header /> */}
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            {/* <Navbar userInfo={userInfo} /> */}
            {/* Content Wrapper. Contains page content */}
            <div className="content" style={{ minHeight: '1000px' }}>
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="">
                    <div className="" style={{marginBottom:50}}>
                        <div className="row">
                            {/* /.col */}
                            <div className="col-sm-2">
                                
                            </div>
                            <div className="col-sm-8" style={{backgroundColor: "#ddd", height: window.innerHeight}}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div style={{paddingTop: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                            <div></div>
                                            <div style={{display: "flex", gap: 10}}>
                                                <span onClick={()=>setUserDetails(prev => ({...prev, "menu":"guess"}))} className='btn btn-default btn-sm' style={{display: "flex", flexDirection: "row", gap: 5}}>
                                                    <span>Guess</span>
                                                </span>
                                                <span onClick={()=>setUserDetails(prev => ({...prev, "menu":"transaction"}))} className='btn btn-default btn-sm' style={{display: "flex", flexDirection: "row", gap: 5}}>
                                                    <span>Transactions</span>
                                                </span>
                                                <span onClick={()=>setUserDetails(prev => ({...prev, "menu":"notification"}))} className='btn btn-default btn-sm' style={{display: "flex", flexDirection: "row", gap: 5}}>
                                                    <span>Notification</span> <div style={{padding: 5, borderRadius: 100, minWidth: 20, height: 20, backgroundColor: "red", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>+99</div>
                                                </span>
                                                <button className='btn btn-danger btn-sm'>Logout</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        {/* <div style={{paddingTop: 10, paddingBottom: 10, display: "flex", justifyContent: "space-between", gap: 5}}>
                                            <div style={{paddingTop: 10, paddingBottom: 10, display: "flex", gap: 5}}>
                                                <MatrixRefill />
                                                <button style={{borderRadius: 5, border: "1px solid #ddd", width: 150, fontWeight: "bolder", fontSize: 20 }}>Liter Pack</button>
                                                <button style={{borderRadius: 5, border: "1px solid #ddd", width: 150, fontWeight: "bolder", fontSize: 20 }}>Gallon Pack</button>
                                            </div>
                                            <div style={{paddingTop: 10, paddingBottom: 10, display: "flex", gap: 5}}>
                                                <span>Total: <span style={{fontWeight: "bolder", fontSize: 25}}>0.00</span></span>
                                            </div>
                                        </div> */}
                                        <div>
                                            {
                                                userdetails.menu === 'member' ?
                                                <>
                                                    <div style={{padding: 5, borderRadius: 5, backgroundColor: "#fff"}}>
                                                        <div style={{fontWeight: "bolder", fontSize: 20}}>
                                                            <span>Name: {userdetails.fullname}</span>
                                                        </div>
                                                        <div>
                                                            <span>ID: {userdetails.user_id}</span>
                                                        </div>
                                                        <div>
                                                            <span>Contact: {userdetails.telephone}</span>
                                                        </div>
                                                        {
                                                            // JSON.stringify(userdetails)
                                                        }
                                                        <hr />
                                                        <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 10}}>
                                                            {/* <button>Add eWallet</button> */}
                                                            <AddWallet item={userdetails} />
                                                            <MatrixRefill item={userdetails} LoadTransaction={LoadTransaction} />
                                                            <ActivateAccount item={userdetails} />
                                                            <ActivatePickupCenter item={userdetails} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div>Transaction</div>
                                                        <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                                        {
                                                            [...transactions].filter((i)=>i.user_id === userdetails.user_id).map((item)=>{
                                                                return (                                                                    
                                                                        <div>
                                                                            <div style={{width: "100%", backgroundColor: "#fff", padding: 5}}>
                                                                                <div>
                                                                                    <span>{item.date_time}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span>{item.description}</span>
                                                                                </div>
                                                                                <div>
                                                                                    <span>{item.amount}</span> <span>({item.type})</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </>
                                                : userdetails.menu === 'transaction' ?
                                                <div>
                                                    <div>
                                                    <div style={{fontSize: 25, fontWeight: "bolder", textAlign: "right"}}>All Transaction</div>
                                                    <div style={{fontSize: 18, fontWeight: "", textAlign: "right"}}>Regular: {[...transactions].filter((i)=>i.type === 'regular')?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                                                    <div style={{fontSize: 18, fontWeight: "", textAlign: "right"}}>Promo: {[...transactions].filter((i)=>i.type === 'promo')?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>

                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column", gap: 5, height: "100%", overflowY: "auto"}}>
                                                    {
                                                        [...transactions].map((item)=>{
                                                            return (                                                                    
                                                                    <div>
                                                                        <div style={{width: "100%", backgroundColor: "#fff", padding: 5}}>
                                                                            <div>
                                                                                <span>{item.date_time}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span style={{fontWeight: "bolder", fontSize: 18}}>{[...users].filter((i)=>i.user_id === item.user_id)[0]?.fullname}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span>{item.description}</span>
                                                                            </div>
                                                                            <div>
                                                                                <span>{item.amount}</span> <span>({item.type})</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                                :null
                                            }
                                            {/* <div style={{display: "flex", flexDirection: "column", gap: 2, minHeight: 500, maxHeight: 500, borderBottom: "1px solid #fff", overflowX: "auto"}}>
                                                {
                                                    [1,2,3,4,5].map(()=>{
                                                        return (
                                                            <div style={{width: "100%", borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#fff", display: "flex", flexDirection: "column", padding: 5}}>
                                                                <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                                                    <span>Description</span>
                                                                    <span>Price: 0.00</span>
                                                                </div>
                                                                <div>
                                                                    <span>Type: Promo</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div> */}
                                            {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: 10, paddingTop: 10}}>
                                                <button className='btn btn-info btn-sm'>Complete Order</button>
                                                <button className='btn btn-danger btn-sm'>Cancel Order</button>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div>
                                            <div style={{paddingTop: 10, paddingBottom: 10}}>
                                                <input type={"text"} onChange={(e)=>setSearch(e.target.value)} placeholder='Search Member Name / ID' style={{width: "100%", height: 40, padding: 5, borderRadius: 5, border: "1px solid #ddd"}} />
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                                            <MemberList search={search} setUserDetails={setUserDetails} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-2">
                                
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default MainPOS;
