import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import { OrganizationChart } from 'primereact/organizationchart';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';

const AdminPersonalGenealogy = ({ userInfo }) => {
    const history = useHistory()
    const { user_id } = useParams();
    document.body.className = 'sidebar-mini';

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [data, setData] = useState([]);
    const [selection, setSelection] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("personal_OrganizationChart", 1);
        formData.append("user_id", user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            // url: 'http://127.0.0.1/drinkgood/process.php?OrganizationChart',
            data: formData,
        }).then(async(res) => {
            console.log(res.data)
            setData(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [user_id]);


    const nodeTemplate = (node=null) => {
        if (node?.label != null) {
            return (
                <div className="" style={{width:50}}>
                    <div className="flex flex-column align-items-center">
                        <img alt={node?.name} src={ENV.DOMAIN+node?.data.image} style={{borderRadius:100}} className="mb-3 w-3rem h-3rem" />
                        {/* <span className="font-bold mb-2" style={{fontSize:10}}>{node?.label}</span> */}
                        <span className="font-bold mb-2" style={{fontSize:9}}>{node?.name}</span>
                        <span style={{fontSize:12}}>{node?.data?.title}</span>
                    </div>
                </div>
            );
        }

        return node?.label;
    };

    return (
        <div className="wrapper">
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Personal Genealogy</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" style={{overflow:"auto"}}>
                            
                            {
                                data.length>0?
                                <OrganizationChart value={data} selectionMode="multiple" selection={selection} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />
                                :
                                null
                            }

                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer>
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default AdminPersonalGenealogy;
