import React, { useState } from 'react';
import AddWallet from './AddWallet';
import EntryPackage from './EntryPackage';
import { Dialog } from 'primereact/dialog';
import MatrixRefill from './MatrixRefill';

const MemberCard = (props) => {
    const [visible, setVisible] = useState(false)
    return (
        <>
            <Dialog visible={visible} style={{ width: '30rem' }} header={
                <span>Options</span>
            } breakpoints={{ '960px': '75vw', '641px': '90vw' }} modal className="p-fluid" onHide={() => setVisible(false)}>

                <MatrixRefill />

            </Dialog>
            <div style={{ width: "100%", padding: 5, border: "1px solid #ddd", backgroundColor: "#fff", borderRadius: 5 }}>
                <div onClick={() => props.setUserDetails({...props.item, menu: "member"})} style={{ cursor: "pointer" }}>
                    <div>
                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{props.item.fullname}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span>ID: {props.item.user_id}</span>
                        <span>Rank: {props.item.rank_status}</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <span>Registered Since: {props.item.date_registered.split(' ')[0]}</span>
                    </div>
                    {/* <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <span>Activated Since: {props.item.date_registered}</span>
                    </div> */}
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    {/* <span>
                        {
                            props.item.account_status === '1' ?
                                <AddWallet item={props.item} />
                                :
                                <EntryPackage />
                        }

                    </span> */}
                    <span>
                        {
                            props.item.account_status === '1' ?
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5}}>                                
                                <span className='btn btn-success btn-xs'>Paid</span>
                                <span className='btn btn-info btn-xs'>Pick-up Center</span>
                            </div>
                                :
                                <span className='btn btn-danger btn-xs'>Unpaid</span>
                        }
                    </span>
                </div>
            </div>
        </>
    );
}

export default MemberCard;
