import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const ActivateAccount = (props) => {
    const [visible, setVisible] = useState(false)
    const [packageentry, setPackageEntry] = useState('')

    const onHide = () => {
        setVisible(false)
        setPackageEntry('')
    }

    const activateNow = () => {
        alert(packageentry);
    }
    return (
        <>

            <Dialog visible={visible} footer={
                <div>
                    {
                        packageentry !== '' ?
                            <button onClick={() => activateNow()} className='btn btn-info btn-sm'>Activate Now</button>
                            : null
                    }
                </div>
            } style={{ width: '30rem' }} header={
                <div>
                    <span style={{ fontSize: 15 }}>Activation</span>
                    <div style={{ fontSize: 20 }}>{props?.item?.fullname}</div>
                    <div style={{ fontSize: 20 }}>{props?.item?.user_id}</div>
                </div>
            } breakpoints={{ '960px': '75vw', '641px': '90vw' }} modal className="p-fluid" onHide={() => onHide()}>

                <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "center", alignItems: "center" }}>
                    <button onClick={() => setPackageEntry('2,288.00')} className='btn btn-info btn-sm'>2,288.00</button>
                    <button onClick={() => setPackageEntry('3,888.00')} className='btn btn-warning btn-sm'>3,888.00</button>
                </div>

            </Dialog>
            {
                props?.item?.account_status === '0' ?
                    <button onClick={() => setVisible(true)} className='btn btn-info btn-xs'>Activate Account</button>
                : null
            }
        </>
    );
}

export default ActivateAccount;
