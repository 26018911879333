import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import { OrganizationChart } from 'primereact/organizationchart';

const PersonalGenealogy = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [data, setData] = useState([]);
    const [selection, setSelection] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("personal_OrganizationChart", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            // url: 'http://127.0.0.1/drinkgood/process.php?OrganizationChart',
            data: formData,
        }).then(async(res) => {
            // console.log(res.data)
            setData(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);


    const nodeTemplate = (node=null) => {
        if (node?.label != null) {
            return (
                <div className="" style={{width:50}}>
                    <div className="flex flex-column align-items-center">
                        <img alt={node?.name} src={ENV.DOMAIN+node?.data.image} style={{borderRadius:100}} className="mb-3 w-3rem h-3rem" />
                        {/* <span className="font-bold mb-2" style={{fontSize:10}}>{node?.label}</span> */}
                        <span className="font-bold mb-2" style={{fontSize:9}}>{node?.name}</span>
                        <span style={{fontSize:12}}>{node?.data?.title}</span>
                    </div>
                </div>
            );
        }
        return node?.label;
    };

    return (
        <div className="wrapper">
            {/* Navbar */}
                {
                    window.innerWidth <= 768 ?
                        <>
                            <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                                <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                    {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                        <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                        <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                    </div> */}
                                    <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                        {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                    </div>
                                </div>
                                {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                            </div>
                        </>
                        :
                        <Header />
                }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Personal Genealogy</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row" style={{overflow:"auto"}}>
                            
                            {
                                data.length>0?
                                <OrganizationChart value={data} selectionMode="multiple" selection={selection} onSelectionChange={(e) => setSelection(e.data)} nodeTemplate={nodeTemplate} />
                                :
                                null
                            }

                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div> */}
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer>
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default PersonalGenealogy;
