import React, { useState } from 'react';
import ENV from '../../ENV';
// import QRCode from "qrcode.react";
import QRCodes from 'qrcode'
import { Sidebar } from 'primereact/sidebar';

const QRCodeImage = (props) => {
    const [openSidebar, setOpenSidebar] = useState(false);
    const [qrCodes, setQRCodes] = useState(null)
    QRCodes.toDataURL(props.userInfo.user_id)
      .then(async (base64) => {
        console.log(base64)
        setQRCodes(base64)
    });

    return (
        <>
        <Sidebar visible={openSidebar} blockScroll={true} showCloseIcon={false} position="bottom" style={{ height:"auto", borderRadius: 0 }} onHide={() => setOpenSidebar(false)}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"100%", gap:2}}>
                <img src={qrCodes} style={{width:200, height:200, border:"2px solid #ddd", borderRadius:20}} />
                <div>
                    <div style={{fontWeight: "bolder", textDecoration: "underline"}}>{props.userInfo.user_id}</div>
                </div>
            </div>
        </Sidebar>



            <div onClick={()=>setOpenSidebar(true)} style={{width:50, height:29.6, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", padding:5, borderRadius:5, border:"1px solid #ddd", backgroundColor:openSidebar? '#000' : null, color:openSidebar? '#fff' : null}} className='pi pi-qrcode'></div>
        </>
    );
}

export default QRCodeImage;
