import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const AddWallet = (props) => {
    const [visible, setVisible] = useState(false)
    const actbtn = (amount) => {
        alert(amount)
    }
    return (
        <>
            <Dialog visible={visible} footer={
                <div>
                    <button className='btn btn-info btn-sm'>Send to eWallet</button>
                </div>
            } style={{ width: '30rem' }} header={
                <div>
                    <span style={{fontSize: 12}}>Add eWallet</span>
                    <div style={{fontSize: 25}}>{props?.item?.fullname}</div>
                </div>
            } breakpoints={{ '960px': '75vw', '641px': '90vw' }} modal className="p-fluid" onHide={()=>setVisible(false)}>
                <div>
                    <div>
                        <span>Balance: 0.00</span>
                    </div>
                    <div>
                        <input className='form-control' />
                    </div>
                </div>
            </Dialog>
            {
                props?.item?.account_status === '1'?
                <span onClick={()=>setVisible(true)} className='btn btn-info btn-xs'>Add eWallet</span>
                :
                <span className='btn btn-default btn-xs'>Add eWallet</span>
            }            
        </>
    );
}

export default AddWallet;
