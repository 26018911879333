import React, { useState } from 'react';
import ENV from './ENV';
import swal from 'sweetalert';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const Register = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');


    const containsWhitespace = (str) => {
        return /\s/.test(str);
    }

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }


    // console.log()

    const history = useHistory()
    const { refid } = useParams();

    // console.log(refid)

    if (!useCheckMobileScreen()) {
        // document.body.className = 'register-page';
        document.body.style.backgroundImage = 'url(https://assets.nst.com.my/images/articles/060616LET02pix1.jpg.transformed.jpg)';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundSize = 'contain';
        document.body.style.backgroundPosition = 'center center';
    } else {
        // document.body.className = 'register-page';
        document.body.style.backgroundImage = 'url(https://www.verdict.co.uk/wp-content/uploads/2019/08/water-harvester.jpg)';
        document.body.style.backgroundRepeat = 'repear-y';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
    }


    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [address, setAddress] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmpassword, setConfirmPassword] = useState('')
    const [referral_code, setReferralCode] = useState(refid != '' ? refid : '')


    const registerBtn = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("register", 1);
        formData.append("fullname", fullname);
        formData.append("email", email);
        formData.append("telephone", mobile);
        formData.append("address", address);
        formData.append("username", username);
        formData.append("password", password);
        formData.append("confirm_password", confirmpassword);
        formData.append("referral_code", refid != '' ? refid : referral_code);

        if (fullname != '' && email != '' && mobile != '' && address != '' && username != '' && password != '' && confirmpassword != '' && referral_code != '') {
            if (password == confirmpassword) {
                if (!containsWhitespace(username) && !containsWhitespace(password) && !containsWhitespace(confirmpassword)) {
                    await axios({
                        method: "post",
                        url: ENV.DOMAIN + 'process.php',
                        data: formData,
                    }).then((res) => {
                        console.log(res.data)
                        if (res.data[0].status == 'success') {
                            swal({
                                title: "Done!",
                                text: "Successfull Registered",
                                icon: "success",
                                timer: 1000,
                                button: false,
                            }).then(() => {
                                // localStorage.setItem('username', res.data[0].username)
                                // localStorage.removeItem('adminUser')
                                // window.location.assign('/register/'+referral_code)
                                setFullname('');
                                setEmail('');
                                setUsername('');
                                setPassword('');
                                setConfirmPassword('');
                            });
                        } else if(res.data[0].status == 'invalid_mobile') {
                            alert('Mobile number already registered.')
                        } else if(res.data[0].status == 'invalid_name') {
                            swal({
                                title: "Invalid!",
                                text: "This Name Already Exist",
                                icon: "error",
                                timer: 1000,
                                button: false,
                            });
                        } else {
                            alert('invalid username and password')
                        }
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    alert('Some fields cannot contain whitespace.')
                }
                // return;
            } else {
                alert('password does not match.')
                // return;
            }
            // return;
        } else {
            alert('Empty Field')
        }

        // console.log('00')
    }

    const [userdata, setUserData] = useState({})



    const userData = async () => {
        var formData = new FormData();
        formData.append("get_referral_code", 1);
        formData.append("user_id", referral_code);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setUserData(res.data[0])
        });
    }





    useEffect(() => {
        userData()
    }, []);



    return (
        <>
            <Helmet>
                <title>Registration</title>
                <meta property="og:site_name" content="DrinkGOOD" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://drinkgood.tagaytaycafe.org/dist/img/drinkgood.png" />
                <meta property="og:url" content={window.location.pathname + window.location.searc} />
                <meta property="og:title" content="DrinkGOOD" />
                <meta property="og:description" content="DrinkGOOD Registration" />
                <meta name="description" content="DrinkGOOD Registration" />
                <link rel="canonical" href='/register' />
            </Helmet>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <video src={require('./356955600_6510487162366595_2239991482821432230_n.mp4')} style={{ width: "100%", height: "100%", objectFit: "cover" }} autoPlay loop muted />

                {
                    useCheckMobileScreen() ?
                        <div className="register-box" style={{ opacity: 0.8, position: "absolute" }}>
                            <div className="register-logo">
                                <img src={require('./434005290_1098140417902000_9145911008996709347_n.jpg')} style={{ width: 100, height: 100, borderRadius: 100 }} />
                                {/* <a href="../../index2.html">DrinkGOOD</a> */}
                            </div>
                            <div className="card">
                                <div className="card-body register-card-body">
                                    <p className="login-box-msg">Register a new membership</p>
                                    <form onSubmit={registerBtn} action="../../index.html" method="post">
                                        <div className="input-group mb-3">
                                            <div style={{borderRadius: 5, border: "1px solid green", width: "100%", padding: 5, backgroundColor: "#fff"}}>
                                                <div style={{fontWeight: "bolder", color: "green"}}>Upline Check: {userdata?.fullname}</div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setFullname(e.target.value)} value={fullname} className="form-control" placeholder="Full name" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-user" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" placeholder="Email" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="number" onChange={(e) => setMobile(e.target.value)} value={mobile} className="form-control" placeholder="Mobile No." />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-phone" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <textarea className="form-control" onChange={(e) => setAddress(e.target.value)} style={{resize:"none", width:"100%", border:"1px solid #ddd"}} placeholder="Address" rows={4}></textarea>
                                        </div>
                                        <hr />
                                        <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setUsername(e.target.value)} value={username} className="form-control" placeholder="Username" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            containsWhitespace(username) ?
                                                <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                : null
                                        }
                                        <div className="input-group mb-3">
                                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" placeholder="Password" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            containsWhitespace(password) ?
                                                <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                : null
                                        }
                                        <div className="input-group mb-3">
                                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmpassword} className="form-control" placeholder="Retype password" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            containsWhitespace(confirmpassword) ?
                                                <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                : null
                                        }
                                        <hr />
                                        {/* <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setReferralCode(e.target.value)} value={referral_code} readonly="" className="form-control" placeholder="Referral Code" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-user" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-6 text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <a onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>Login</a>
                                            </div>
                                            <div className="col-6">
                                                {
                                                    !containsWhitespace(username) && !containsWhitespace(password) && !containsWhitespace(confirmpassword) ?
                                                        <button type="submit" className="btn btn-primary btn-block">Register</button>
                                                        :
                                                        <button type="button" className="btn btn-default btn-block" disabled>Register</button>
                                                }
                                            </div>
                                            {/* /.col */}
                                        </div>
                                    </form>
                                    {/* <a href="/" className="text-center">I already have a membership</a> */}
                                </div>
                                {/* /.form-box */}
                            </div>{/* /.card */}
                        </div>
                        :
                        <div className="register-box" style={{ opacity: 0.9, position: "absolute" }}>
                            <div className="register-logo">
                                <img src={require('./434005290_1098140417902000_9145911008996709347_n.jpg')} style={{ width: 100, height: 100, borderRadius: 100 }} />
                                <br />
                                <a>DrinkGOOD</a>
                            </div>
                            <div className="card">
                                <div className="card-body register-card-body">
                                    <p className="login-box-msg">Register a new membership</p>
                                    <form onSubmit={registerBtn} action="../../index.html" method="post">
                                        <div className="input-group mb-3">
                                            <div style={{borderRadius: 5, border: "1px solid green", width: "100%", padding: 5, backgroundColor: "#fff"}}>
                                                <div style={{fontWeight: "bolder", color: "green"}}>Upline Check: {userdata?.fullname}</div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setFullname(e.target.value)} value={fullname} className="form-control" placeholder="Full name" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-user" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" placeholder="Email" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="number" onChange={(e) => setMobile(e.target.value)} value={mobile} className="form-control" placeholder="Mobile No." />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <textarea className="form-control" onChange={(e) => setAddress(e.target.value)} style={{resize:"none", width:"100%", border:"1px solid #ddd"}} placeholder="Address" rows={4}></textarea>
                                        </div>
                                        <hr />
                                        <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setUsername(e.target.value)} value={username} className="form-control" placeholder="Username" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                            {
                                                containsWhitespace(username) ?
                                                    <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                    : null
                                            }
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className="form-control" placeholder="Password" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                            {
                                                containsWhitespace(password) ?
                                                    <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                    : null
                                            }
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmpassword} className="form-control" placeholder="Retype password" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                            {
                                                containsWhitespace(confirmpassword) ?
                                                    <span style={{ color: "red" }}>This field cannot contain whitespace.</span>
                                                    : null
                                            }
                                        </div>
                                        <hr />
                                        {/* <div className="input-group mb-3">
                                            <input type="text" onChange={(e) => setReferralCode(e.target.value)} value={referral_code} readonly="" className="form-control" placeholder="Referral Code" />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-user" />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-6 text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <a onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>Login</a>
                                            </div>
                                            <div className="col-6">
                                                {
                                                    !containsWhitespace(username) && !containsWhitespace(password) && !containsWhitespace(confirmpassword) ?
                                                        <button type="submit" className="btn btn-primary btn-block">Register</button>
                                                        :
                                                        <button type="button" className="btn btn-default btn-block" disabled>Register</button>
                                                }
                                            </div>
                                            {/* /.col */}
                                        </div>
                                    </form>
                                    {/* <a href="/" className="text-center">I already have a membership</a> */}
                                </div>
                                {/* /.form-box */}
                            </div>{/* /.card */}
                        </div>
                }


            </div>
        </>

    );
}

export default Register;
