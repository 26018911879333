import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';

const PreSignUpList = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    
    const [Copied, setCopied] = useState({id:'', status:false});

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [directInfo, setDirectInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("unpaid", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    

    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }


    return (
        <div className="wrapper">
            {/* Navbar */}
            {
                window.innerWidth <= 768 ?
                    <>
                        <div style={{height:50, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", backgroundColor:"#fff"}}>
                            <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                <img src={"../../dist/img/drinkgood.png"} style={{width:45, height:45}} />
                                <span style={{fontWeight:600}}>DrinkGOOD</span>
                            </div>
                            {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                        </div>
                    </>
                :
                <Header />
            }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1000px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid" style={{marginBottom:50}}>
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                {/* <div className="card-header p-2"> */}
                                {/* <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul> */}
                                {/* </div> */}
                                {/* /.card-header */}

                                {
                                    !useCheckMobileScreen() ?
                                        <div className="card">
                                            <div className="card-body">
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Fullname</th>
                                                            {/* <th>Placement</th> */}
                                                            <th>Rank</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            directInfo.map((item, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td></td>
                                                                        <td>{item.fullname}</td>
                                                                        {/* <td>{item.placement_fullname}</td> */}
                                                                        <td>{item.rank_status}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div style={{ display: "flex", flexDirection: "column", gap:5 }}>
                                                {
                                                    directInfo.map((item, key) => {
                                                        return (
                                                            <div key={key} className="card" style={{ padding: 5, display:"flex", flexDirection:"column", gap:0, marginBottom:0 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", paddingTop:5, paddingBottom:10 }}>
                                                                    <div>
                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", gap:10}}>
                                                                            <img src={ENV.DOMAIN+item.image} className="img-circle elevation-2" alt="User Image" style={{width:50, height:50}} />
                                                                            <div>
                                                                                <b>{item.fullname}</b>
                                                                                <div><small>ID: {item.user_id}</small></div>
                                                                                <div><small>{item.date_registered}</small></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <small>{item.rank_status}</small> */}
                                                                </div>
                                                                {/* <div style={{padding:5, borderTop:"1px solid #f0f0f0", display:"flex", flexDirection:"row", gap:5, justifyContent:"flex-start", alignItems:"center"}}>
                                                                    <button onClick={() => null} style={{border:"1px solid #ddd", height:25, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", padding:5, borderRadius:5}}>Notify</button>
                                                                </div> */}
                                                                {/* <div style={{padding:5, borderTop:"1px solid #f0f0f0"}}>
                                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                                            <small>{item.date_registered}</small>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                }
                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div> */}
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default PreSignUpList;
