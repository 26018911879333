import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminTransactionPayin = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payoutlist, setPayoutList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("AdminshowTransaction", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])


    useEffect(() => {

    }, [])

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Pay-in Transaction</h1>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong> All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminTransactionPayin;
