import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';

const ActivatePickupCenter = (props) => {
    const [visible, setVisible] = useState(false)
    const actbtn = (amount) => {
        alert(amount)
    }
    return (
        <>
            <Dialog visible={visible} draggable={false} footer={
                <div>
                    <button className='btn btn-info btn-sm'>Activate</button>
                </div>
            } style={{ width: '30rem' }} header={
                <div>
                    <span style={{fontSize: 12}}>Activate for Pick-up Center</span>
                    <div style={{fontSize: 25}}>{props?.item?.fullname}</div>
                </div>
            } breakpoints={{ '960px': '75vw', '641px': '90vw' }} modal className="p-fluid" onHide={()=>setVisible(false)}>
                <div>
                    <div>
                        <label>Address</label>
                        <textarea className='form-control' style={{resize: "none"}} rows={5}></textarea>
                    </div>
                    <div>
                        <label>Follow-up By</label>
                        <input className='form-control' placeholder='Account ID' />
                    </div>
                </div>
            </Dialog>
            {
                props?.item?.account_status === '1'?
                // <span onClick={()=>setVisible(true)} className='btn btn-info btn-xs'>Add eWallet</span>
                <button onClick={()=>setVisible(true)} className='btn btn-info btn-xs'>Activate for PUC</button>
                : null
            }            
        </>
    );
}

export default ActivatePickupCenter;
