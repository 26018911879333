import React, { useState } from 'react';
import ENV from './ENV';
import axios from 'axios';
import swal from 'sweetalert';
import { useEffect } from 'react';

const Login = () => {

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
        }
    
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
    
        return (width <= 768);
    }

    // console.log()
    
    // const history = useHistory()
    // const { refid } = useParams();

    // console.log(refid)

    if (!useCheckMobileScreen()) {
        // document.body.className = 'hold-transition login-page';
        document.body.style.backgroundImage = 'url(https://assets.nst.com.my/images/articles/060616LET02pix1.jpg.transformed.jpg)';
        document.body.style.backgroundRepeat = 'no-repeat';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center center';
    } else {
        // document.body.className = 'hold-transition login-page';
        document.body.style.backgroundImage = 'url(https://www.verdict.co.uk/wp-content/uploads/2019/08/water-harvester.jpg)';
        document.body.style.backgroundRepeat = 'repear-y';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
    }


    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')



    const LoginBtn = async (event) => {
        event.preventDefault();

        var formData = new FormData();
        formData.append("login", 1);
        formData.append("username", username);
        formData.append("password", password);

        if(username=='' && password==''){
            return;
        }

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            if(res.data[0].status == 'success'){
                swal({
                    title: "Done!",
                    text: "Successfull Login",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    localStorage.setItem('user_id', res.data[0].user_id)
                    localStorage.setItem('username', res.data[0].username)
                    // localStorage.removeItem('adminUser')
                    if (res.data[0].account_status == 1) {
                        window.location.assign('/dashboard')
                    } else {
                        window.location.assign('/profile')
                    }
                    // setUsername('')
                    // setPassword('')
                });
            } else {
                alert('invalid Username and Password')
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }






    return (
        <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", height:"100vh"}}>
            <video src={require('./356955600_6510487162366595_2239991482821432230_n.mp4')} style={{ width:"100%", height:"100%", objectFit:"cover"}} autoPlay loop muted />
            <div className="login-box" style={{opacity:0.89, position:"absolute"}}>
                <div className="login-logo">
                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:200, height:200, borderRadius:100}} />
                    {/* <a href="../../index2.html">DRINKGOOD</a> */}
                </div>
                {/* /.login-logo */}
                <div className="card" style={{margin: 10}}>
                    <div className="card-body login-card-body" style={{borderRadius: 10}}>
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form onSubmit={LoginBtn} method="post">
                            <div className="input-group mb-3">
                                <input type="text" onChange={(e)=>setUsername(e.target.value)} className="form-control" placeholder="Username" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" onChange={(e)=>setPassword(e.target.value)} className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        {/* <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label> */}
                                    </div>
                                </div>
                                {/* /.col */}
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                                {/* /.col */}
                            </div>
                        </form>
                    </div>
                    {/* /.login-card-body */}
                </div>
            </div>
        </div>

    );
}

export default Login;
