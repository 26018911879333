import React, { useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import SelfieCamera from './component/kyc/SelfieCamera';
import BackCamera from './component/kyc/BackCamera';
import { Toast } from 'primereact/toast';

const KYCForm = (props) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    const toastTopRight = useRef(null);
    document.body.className = 'sidebar-mini';
    
    const [inputvalue, setInputValue] = useState({
        "user_id": props.userInfo?.user_id,
        "first_name": "",
        "middle_name": "",
        "last_name": "",
        "birthday": "",
        "gender": "",
        "nationality": "",
        "civil_status": "",
        "street_address": "",
        "city": "",
        "state": "",
        "postal_code": "",
        "country": "Philippine",
        "phone_number": "",
        "email_address": "",
        "identification_type": "",
        "identification_number": "",
        "identification_front_image": "",
        "identification_back_image": "",
        "selfie_image": "",
        "expiry_date": ""
    })
    const [loading, setLoading] = useState(false)
    console.log(inputvalue)


    const SubmitApplication = async () => {
        // event.preventDefault();
        setLoading(true)
        var formData = new FormData();
        formData.append("submitKYCApplication", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));

        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            // url: 'http://localhost/drinkgood/kyc_form.php',
            data: formData,
        }).then((res)=>{
            setLoading(false)
            if (res.data[0].status === 'success') {
                toastTopRight.current.show({ severity: "success", summary: "KYC Application", detail: "Successfully Submitted", life: 3000 });
                window.location.assign('/profile')
            } else {
                setLoading(false)
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }





    return (
        <div className="wrapper">
            
            <Toast ref={toastTopRight} position="top-right" />
            {/* Navbar */}
                {
                    window.innerWidth <= 768 ?
                        <>
                            <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                                <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                    {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                        <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                        <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                    </div> */}
                                    <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                        {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                    </div>
                                </div>
                                {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                            </div>
                        </>
                        :
                        <Header />
                }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={props.userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>KYC Verification</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                {/* <div className="card-header p-2"> */}
                                {/* <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul> */}
                                {/* </div> */}
                                {/* /.card-header */}
                                
                                <div>
                                    <div>
                                        <span style={{fontWeight: "bolder", fontSize: 20}}>Personal Information</span>
                                    </div>
                                    <div>
                                        <div>
                                            <label>First Name</label>
                                            <input type='text' className='form-control' name='first_name' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Middle Name</label>
                                            <input type='text' className='form-control' name='middle_name' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Last Name</label>
                                            <input type='text' className='form-control' name='last_name' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Date of Birth</label>
                                            <input type='date' className='form-control' name='birthday' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Gender</label>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='gender' value={"Male"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Male
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='gender' value={"Female"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Female
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <label>Nationality</label>
                                            <input type='text' className='form-control' name='nationality' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                        </div>
                                        <div>
                                            <label>Marital Status</label>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='civil_status' value={"Single"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Single
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='civil_status' value={"Married"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Married
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='civil_status' value={"Separated"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Separated
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='civil_status' value={"Widowed"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Widowed
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <label style={{fontSize: 20}}>Residential Address</label>
                                            <div>
                                                <label>Street Address</label>
                                                <input type='text' className='form-control' name='street_address' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <label>City</label>
                                                <input type='text' className='form-control' name='city' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <label>State</label>
                                                <input type='text' className='form-control' name='state' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <label>Postal Code</label>
                                                <input type='text' className='form-control' name='postal_code' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <label>Country</label>
                                                <input type='text' className='form-control' name='country' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} value={"Philippine"} readOnly />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div>
                                            <label style={{fontSize: 20}}>Contact Details</label>
                                            <div>
                                                <label>Phone Number</label>
                                                <input type='text' className='form-control' name='phone_number' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <label>Email Address</label>
                                                <input type='text' className='form-control' name='email_address' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <span style={{fontWeight: "bolder", fontSize: 20}}>Identification Documents</span>
                                        <div>
                                            <label>Identification Type</label>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='identification_type' value={"Passport"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Passport
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='identification_type' value={"Driver/s License"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Driver’s License
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='identification_type' value={"National ID Card"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> National ID Card
                                                </label>
                                            </div>
                                            <div>
                                                <label style={{fontWeight: "normal"}}>
                                                    <input type='radio' name='identification_type' value={"Other"} onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} /> Other
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Document/s</span>
                                            <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                                <BackCamera sidemode="front" inputvalue={inputvalue} setInputValue={setInputValue} />
                                                <BackCamera sidemode="back" inputvalue={inputvalue} setInputValue={setInputValue} />
                                            </div>
                                            <span>Selfie</span>
                                            <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                                <SelfieCamera inputvalue={inputvalue} setInputValue={setInputValue} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <span>Identification Number</span>
                                                <input type='text' className='form-control' name='identification_number' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>
                                            <div>
                                                <span>Expiry Date</span>
                                                <input type='date' className='form-control' name='expiry_date' onChange={(e)=>setInputValue(prev=> ({...prev, [e.target.name]: e.target.value}))} />
                                            </div>

                                        </div>
                                    </div>
                                    <div style={{paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                        <button className='btn btn-primary btn-sm' onClick={()=>window.location.assign('/')}>Home</button>
                                        {
                                            loading ?
                                            <button className='btn btn-info btn-sm'>Please wait...</button>
                                            :
                                            <button className='btn btn-info btn-sm' onClick={()=>SubmitApplication()}>Submit Application</button>
                                        }
                                    </div>
                                </div>

                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div style={{height: 50}}>

                </div>
                {/* <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div> */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default KYCForm;
