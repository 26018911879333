import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import swal from 'sweetalert';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import FileManager from './component/Avatar/FileManager';
import ChangePasswordBtn from './component/profile/ChangePasswordBtn';
import InformationBtn from './component/profile/InformationBtn';
import ChangeContactNumber from './component/profile/ChangeContactNumber';
import ChangeAddress from './component/profile/ChangeAddress';
import AllMembersAddress from './component/profile/AllMembersAddress';
import Accounts from './component/profile/Accounts';
import QRCodeImage from './component/profile/QRCodeImage';
import AvailableCode from './component/profile/AvailableCode';
import KYCStatus from './component/kyc/KYCStatus';

const Profile = ({ userInfo }) => {
    const toast = useRef(null);
    // console.log(userInfo)
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    // const [userInfo, setUserInfo] = useState(null);
    const [activation_code, setActivationCode] = useState('');
    const [OpenActivateForm, setOpenActivateForm] = useState(false);
    const [Copied, setCopied] = useState({ id: '', status: false });
    const [payoutForm, setPayoutForm] = useState(false);

    const [payoutlist, setPayoutList] = useState([])
    const [balance, setBalance] = useState(0)
    const [totalpayout, setTotalPayout] = useState(0)

    const [insufficientalert, setInsufficientAlert] = useState(false);
    const [incorrectpassword, setIncorrectPassword] = useState(false);



    const [usdRate, setUSDRate] = useState(0);
    
    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            console.log(res.data.rates.PHP)
            console.log(Number(3880/res.data.rates.PHP).toFixed(2))
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    // const { refid } = useParams();
    // console.log(refid ?? localStorage.getItem('user_id'))

    // useMemo(async()=>{
    //     console.log(refid)

    //     var formData = new FormData();
    //     formData.append("profile", 1);
    //     formData.append("user_id", ? userInfo.user_id);

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         console.log(res.data[0])
    //         setUserSelectedInfo(res.data[0])
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // },[ENV, refid]);


    const [downlineInfo, setDownlineInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("downline", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDownlineInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV, userInfo]);











    const ActivateAccount = async (event) => {
        event.preventDefault();
        if (activation_code == '') {
            alert('Invalid');
            return;
        }
        var formData = new FormData();
        formData.append("activate_account", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("username", userInfo.username);
        formData.append("activation_code", activation_code);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            if (res.data[0].status == 'success') {
                swal({
                    title: "Done!",
                    text: "Successfully Activated",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    window.location.assign('/profile')
                });
            } else if (res.data[0].status == 'invalid_activation') {
                swal({
                    title: "Invalid Code!",
                    text: "please try again",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    // window.location.assign('/profile')
                });
            } else {
                swal({
                    // title: "Invalid!",
                    text: "Invalid!",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    // window.location.assign('/profile')
                });
            }
            // swal({
            //     title: "Done!",
            //     text: "Successfully Activated",
            //     icon: "success",
            //     timer: 1000,
            //     button: false,
            // }).then(() => {
            // localStorage.setItem('user_id', res.data[0].user_id)
            // localStorage.setItem('username', res.data[0].username)
            // localStorage.removeItem('adminUser')
            // window.location.assign('/dashboard')
            // window.location.assign('/profile')
            // setUsername('')
            // setPassword('')
            // });
            // window.location.assign('/profile')
        })
            .catch((err) => {
                console.log(err);
            });
    }

    
    const SelfActivateAccount = async (event) => {
        // alert()
        // return;
        var formData = new FormData();
        formData.append("self_activate_account", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("usdRate", usdRate);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if(res.data[0].status == "insufficient_balance") {
                swal({
                    // title: "Invalid!",
                    text: "you have insufficient balance!",
                    icon: "error",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    // window.location.assign('/profile')
                });
            } else if(res.data[0].status == "success") {
                swal({
                    // title: "Invalid!",
                    text: "Congratulation you are now activated!",
                    icon: "success",
                    timer: 2000,
                    button: false,
                }).then(() => {
                    window.location.assign('/profile')
                });
            }
            // setPayoutList(res.data)
            console.log(res.data)
            // window.location.assign('/profile')
        }).catch((err) => {

        });
    }

    // swal({
    //     title: "Invalid Code!",
    //     text: "please try again",
    //     icon: "error",
    //     // timer: 1000,
    //     button: false,
    // }).then(() => {
    //     // window.location.assign('/profile')
    // }); 


    const payout_list = async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    // const ClickCopied = (user_id) => {
    //     // alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    //     navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id);
    // }

    const onHistory = () => {

    }

    const [accountName, setAccountName] = useState(false);
    const [accountNumber, setAccountNumber] = useState(false);
    const [amountRequest, setAmountRequest] = useState(false);
    const [password, setPassword] = useState(false);

    const sendRequest = async () => {
        var formData = new FormData();
        formData.append("payout_request", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("account_name", accountName);
        formData.append("account_number", accountNumber);
        formData.append("amount", amountRequest);
        formData.append("password", password);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            if (res.data[0].status == 'success') {
                setInsufficientAlert(false)
                setBalance(res.data[0].balance)
                payout_list()
                setPayoutForm(false)
            } else if (res.data[0].status == 'insufficient') {
                setInsufficientAlert(true)
            } else if (res.data[0].status == 'incorect_password') {
                setIncorrectPassword(true)
            }
        }).catch((err) => {

        });

    }

    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setBalance(res.data[0].balance)
            payout_list()
        }).catch((err) => {

        });

    }


    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_list", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);


    useMemo(async () => {

        var formData = new FormData();
        formData.append("total_payout", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setTotalPayout(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);


    const [encodeingfund, setEncodingFund] = useState(0);
    
    useMemo(async () => {

        var formData = new FormData();
        formData.append("encoding_fund", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setEncodingFund(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const btnstatus = (item) => {
        if (item.status == 'deducted') {
            return (<span style={{ color: "red", fontSize: 10 }}>Deducted</span>);
        } else if (item.status == 'cancelled') {
            return (<span style={{ color: "red", fontSize: 10 }}>Cancelled</span>);
        } else if (item.status == 'declined') {
            return (<span style={{ color: "red", fontSize: 10 }}>Declined</span>);
        } else if (item.status == 'approved') {
            return (<span style={{ color: "green", fontSize: 10 }}>Approved</span>);
        } else {
            return (<a onClick={() => CancelRequest(item, 'cancelled')} style={{ width: 100, fontSize: 10, color: "red", textDecoration: "underline" }}>Cancel Payout</a>);
        }
    }

    const Status = (item) => {
        if (item.status == 'pending') {
            return item.status
        }
    }




    const [activitylist, setActivityList] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("showactivity", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setActivityList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);


    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const confirm2 = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Do you want to delete this record?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            // accept,
            reject
        });
    };

    


    // const changeHandlerImage = (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //         let files = event.target.files;
    //         let reader = new FileReader();
    //         reader.readAsDataURL(files[0]);

    //         reader.onload = (e) => {
                
    //             console.log(event.target.files[0])
    //             // setimageArray([...imagearray, { 
    //             //     filename: event.target.files[0].name, 
    //             //     filetype: event.target.files[0].type, 
    //             //     filezise: event.target.files[0].size, 
    //             //     image: e.target.result
    //             // }])
    //             // setimageDisplayOne(e.target.result)
    //             // const split = event.target.files[0].name.split('.')[1]
    //             // console.log(split)
    //         }
    //     }
    // };

    useEffect(() => {
        // payout_list()
    }, []);

    return (
        <>
            {/* <Dialog className='p-dialog-titlebar-close' visible={changepasswordform} style={{ width: '95vw', borderRadius:0 }} onHide={() => setChangePasswordForm(false)}>
                <div>
                    <div>
                        <input style={{width:"100%", height:35, borderRadius:5, border:"1px solid #ddd"}} />
                    </div>
                </div>
            </Dialog> */}
            <div className="wrapper">
                {/* Navbar */}
                {
                    window.innerWidth <= 768 ?
                        <>
                            <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                                <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                    {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                        <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                        <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                    </div> */}
                                    <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                        {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                    </div>
                                </div>
                                {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                            </div>
                        </>
                        :
                        <Header />
                }
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <Navbar userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                    {/* Content Header (Page header) */}
                    {/* <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            
                        </div>
                    </div>
                </section> */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    {/* Profile Image */}
                                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>

                                        <div className="">
                                            <div className="card card-widget widget-user shadow">
                                                <div className="widget-user-header bg-info">
                                                    <h3 className="widget-user-username">{userInfo?.fullname != null ? userInfo?.fullname : 'Loading...'}</h3>
                                                    <h5 className="widget-user-desc">{userInfo?.rank_status}</h5>
                                                </div>
                                                <div className="widget-user-image">
                                                    <FileManager userInfo={userInfo} />
                                                </div>
                                                <div className="card-footer" style={{backgroundColor:"#fff"}}>
                                                    {/* {
                                                        userInfo?.account_status == 1 ?
                                                        <div style={{ textAlign: "center" }}>
                                                            <span style={{ fontSize: 12, fontWeight: 600 }}>ID: {userInfo?.user_id}</span>
                                                        </div>
                                                        :null
                                                    } */}
                                                    <div style={{ textAlign: "center" }}>
                                                        <span style={{ fontSize: 12, fontWeight: 600 }}>ID: {userInfo?.user_id}</span>
                                                    </div>
                                                    <div style={{ textAlign: "center", display:"flex", flexDirection:"column", justifyContent:"center", lineHeight:1 }}>
                                                        <span style={{ fontSize: 25, fontWeight: 600 }}>${userInfo?.balance}</span>
                                                        {/* <span style={{ fontSize: 12, fontWeight: 600, color:"rgb(137 135 135)" }}>PHP {Number((userInfo?.balance)*usdRate).toFixed(2)}</span> */}
                                                    </div>
                                                    <div className="" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center" }}>
                                                        {/* <div className="" style={{width:"33%"}}>
                                                        <div className="description-block">
                                                            <h5 className="description-header">3,200</h5>
                                                            <span className="">SALES</span>
                                                        </div>
                                                    </div> */}
                                                        <div className="" style={{ width: "33%" }}>
                                                            <div className="description-block">
                                                                <h5 className="description-header">{userInfo?.direct_count}</h5>
                                                                <span className="">Direct/s</span>
                                                            </div>
                                                        </div>
                                                        <div className="" style={{ width: "33%" }}>
                                                            <div className="description-block">
                                                                <h5 className="description-header">{downlineInfo?.length}</h5>
                                                                <span className="">Downline</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    
                                                    <div style={{ width: '75%', display:"flex", flexDirection:"row", justifyContent:'flex-start', alignItems:"center", gap:5 }}>
                                                        <QRCodeImage userInfo={userInfo} />
                                                        {
                                                            userInfo?.account_status == 1 ?
                                                                <CopyToClipboard text={ENV.DOMAIN + 'register/' + userInfo.user_id}
                                                                    style={{ border: "1px solid #ddd", padding: 5, borderRadius: 5, fontWeight: 200, fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
                                                                    onCopy={() => {
                                                                        setCopied({ id: userInfo.user_id, status: true })
                                                                        setTimeout(() => {
                                                                            setCopied({ id: userInfo.user_id, status: false })
                                                                        }, 1000)
                                                                    }}>
                                                                    {
                                                                        Copied.id == userInfo.user_id && Copied.status == true ?
                                                                            <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                            :
                                                                            <a className="btn btn-primary btn-block"><b>Referral Link</b></a>
                                                                    }
                                                                </CopyToClipboard>
                                                                : null
                                                        }
                                                        {
                                                            userInfo?.account_status == 0 ?
                                                                <>
                                                                    {/* <p className="text-muted text-center">Unpaid Account</p> */}
                                                                    {
                                                                        OpenActivateForm ?
                                                                            <>
                                                                                {/* <form onSubmit={ActivateAccount}>
                                                                                    <div className="form-group">
                                                                                        <label htmlFor="exampleInputPassword1">Enter Activation Code</label>
                                                                                        <input type="text" onChange={(e) => setActivationCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code " />
                                                                                    </div>
                                                                                    <div className='margin' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                        <button type='button' onClick={() => { setOpenActivateForm(false); setActivationCode('') }} className="btn btn-danger">
                                                                                            <b>Cancel </b>
                                                                                        </button>
                                                                                        <button type='submit' className="btn btn-default">
                                                                                            <b>Activate Now </b>
                                                                                        </button>
                                                                                    </div>
                                                                                </form> */}
                                                                                
                                                                                <a onClick={() => { SelfActivateAccount() }} className="btn btn-primary btn-block" style={{backgroundColor:"#da4e08", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", height:29.6}}>
                                                                                    <b>Confirm ${encodeingfund}</b>
                                                                                </a>
                                                                            </>
                                                                            :
                                                                            <a className="btn btn-primary btn-block" style={{display:"flex", backgroundColor:"#ddd", border:"1px solid #ddd", flexDirection:"row", alignItems:"center", justifyContent:"center", height:29.6}}>
                                                                                {/* <b>Activate my Account</b> */}
                                                                                <b>Referral Link</b>
                                                                                {/* onClick={() => { setOpenActivateForm(true); }} */}
                                                                            </a>
                                                                    }
                                                                </>
                                                                : null
                                                        }
                                                    </div>
                                                    {/* <Button onClick={confirm2} icon="pi pi-times" label="Delete" className="p-button-danger p-button-outlined"></Button> */}

                                                    <div onClick={() => {
                                                        localStorage.removeItem('user_id')
                                                        localStorage.removeItem('username')
                                                        localStorage.removeItem('user_image')
                                                        window.location.assign('/')
                                                    }} style={{ padding: 5, border:"1px solid red", display: "flex", borderRadius:5, width: '20%', height:29.6, flexDirection: "row", justifyContent: "flex-start", position: "absolute", right: 10, alignItems: "center", gap: 10 }}>
                                                        <i className='pi pi-sign-out' style={{ color: "red" }} /> 
                                                        <small style={{ fontSize: 8 }}>Logout</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{paddingTop: 10, paddingBottom: 10}}>
                                            <KYCStatus userInfo={userInfo} />
                                        </div>
                                        <div style={{width:"100%", display:"flex", flexDirection:"column", gap:5}}>
                                            {/* <div style={{width:"100%", display:"flex", flexDirection:"column", gap:0}}>
                                                <div style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, color:"#fff", backgroundColor: "rgb(147 185 247)" }}>
                                                    Administrator 
                                                    <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                                        <span style={{fontSize:12, color:"#fff"}}>Allowed</span>
                                                        <span className='pi pi-chevron-right'></span>
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div style={{width:"100%", display:"flex", flexDirection:"column", gap:0}}>
                                                {/* <div> */}
                                                    {/* <InformationBtn userInfo={userInfo} /> */}
                                                    {/* <div onClick={()=>setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 5, backgroundColor: "#fff" }}>
                                                        My Purchases <span className='pi pi-chevron-right'></span>
                                                    </div> */}
                                                {/* </div> */}
                                                {/* {
                                                    userInfo.user_id == '000000000' || userInfo.user_id == '689730542' || userInfo.user_id == '845736201' ?
                                                    <div onClick={() => null} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 5, backgroundColor: "#fff" }}>
                                                        All Members Address <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span className='pi pi-chevron-right'></span></span>
                                                    </div>
                                                    :null
                                                } */}
                                                <AllMembersAddress userInfo={userInfo} />
                                                <Accounts userInfo={userInfo} />
                                                <AvailableCode userInfo={userInfo} />
                                                <ChangeAddress userInfo={userInfo} />
                                                <ChangeContactNumber userInfo={userInfo} />
                                                <ChangePasswordBtn userInfo={userInfo} />
                                            </div>
                                        </div>




                                        {/* <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:5}}> */}
                                        {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                            <img src={"../../dist/img/user2-160x160.jpg"} style={{ width: 150, height: 150, borderRadius: 100, border: "1px solid #ddd" }} />
                                            
                                            <div onClick={() => {
                                                    localStorage.removeItem('user_id')
                                                    localStorage.removeItem('username')
                                                    window.location.assign('/')
                                                }} style={{ padding: 5, display:"flex", flexDirection:"row", justifyContent:"flex-start", position:"absolute", right:10, alignItems:"center", gap:10 }}><i className='pi pi-sign-out' style={{ color: "red" }} /> <small style={{fontSize:8}}>Logout</small></div>
                                        </div>
                                        <div>{userInfo?.fullname != null ? userInfo?.fullname : 'Loading...'}</div>
                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                                            <small style={{ fontSize: 18 }}>{userInfo?.rank_status}</small>
                                        </div>
                                        <div>
                                            {
                                                userInfo?.direct_sponsor_name != '' ?
                                                    <small style={{ fontSize: 10 }}>Direct Upline: <span style={{ textDecoration: "underline", fontWeight: 600 }}>{userInfo?.direct_sponsor_name}</span></small>
                                                    :
                                                    null
                                            }
                                        </div> */}
                                        {/* <div> */}
                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                <span style={{ fontSize: 15 }}>{userInfo?.direct_count} Direct</span>
                                                <span style={{ width: 5, height: 5, backgroundColor: "#bfbcc2", borderRadius: 100 }}></span>
                                                <span style={{ fontSize: 15 }}>{downlineInfo.length} Downline</span>
                                            </div>
                                            {
                                                
                                                userInfo?.user_id == sessionStorage.getItem('user_id') ?
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 5 }}>
                                                    <span style={{ fontSize: 12 }}>Balance: 
                                                    $ {userInfo?.balance}</span>
                                                </div>
                                                :null
                                            } */}
                                        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", gap: 10 }}>
                                                {
                                                    userInfo?.account_status == 1 ?
                                                        <CopyToClipboard text={ENV.DOMAIN + 'register/' + userInfo.user_id}
                                                            style={{ border: "1px solid #ddd", padding: 2, borderRadius: 5, fontWeight: 200, fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
                                                            onCopy={() => {
                                                                setCopied({ id: userInfo.user_id, status: true })
                                                                setTimeout(() => {
                                                                    setCopied({ id: userInfo.user_id, status: false })
                                                                }, 1000)
                                                            }}>
                                                            {
                                                                Copied.id == userInfo.user_id && Copied.status == true ?
                                                                    <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                    :
                                                                    <a className="btn btn-primary btn-block"><b>Referral Link</b></a>
                                                            }
                                                        </CopyToClipboard>
                                                        : null
                                                }
                                                {
                                                    userInfo?.account_status == 0 ?
                                                        <>
                                                            {
                                                                OpenActivateForm ?
                                                                    <>
                                                                        <form onSubmit={ActivateAccount}>
                                                                            <div className="form-group">
                                                                                <label htmlFor="exampleInputPassword1">Enter Activation Code</label>
                                                                                <input type="text" onChange={(e) => setActivationCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code " />
                                                                            </div>
                                                                            <div className='margin' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                <button type='button' onClick={() => { setOpenActivateForm(false); setActivationCode('') }} className="btn btn-danger"><b>Cancel </b></button>
                                                                                <button type='submit' className="btn btn-default"><b>Activate Now </b></button>
                                                                            </div>
                                                                        </form>
                                                                    </>
                                                                    :
                                                                    <a onClick={() => { setOpenActivateForm(true); }} className="btn btn-primary btn-block"><b>Activate my Account</b></a>
                                                            }
                                                        </>
                                                        : null
                                                }
                                            </div> */}
                                        {/* </div> */}
                                        {/* </div> */}
                                        <div style={{ backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: 10, padding: 5, display: "flex", flexDirection: "column", display: 'none', justifyContent: "flex-start", gap: 10 }}>

                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                <span>
                                                    <img src={"../../dist/img/user2-160x160.jpg"} style={{ width: 50, height: 50, borderRadius: 100, border: "1px solid #ddd" }} />
                                                </span>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                                                    <span style={{ fontSize: 18 }}>
                                                        {userInfo?.fullname != null ? userInfo?.fullname : 'Loading...'}
                                                    </span>
                                                    <small style={{ fontSize: 10 }}>
                                                        {userInfo?.rank_status}
                                                    </small>
                                                    {
                                                        userInfo?.direct_sponsor_name != '' ?
                                                            <small style={{ fontSize: 10 }}>
                                                                Direct Upline: <span style={{ textDecoration: "underline", fontWeight: 600 }}>{userInfo?.direct_sponsor_name}</span>
                                                            </small>
                                                            :
                                                            null
                                                    }
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                        <span style={{ fontSize: 12 }}>
                                                            Balance: ${userInfo?.balance}
                                                        </span>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                                                        <span style={{ fontSize: 12 }}>{userInfo?.direct_count} Direct</span>
                                                        <span style={{ width: 5, height: 5, backgroundColor: "#bfbcc2", borderRadius: 100 }}></span>
                                                        <span style={{ fontSize: 12 }}>{downlineInfo.length} Downline</span>
                                                    </div>
                                                    <>
                                                        {
                                                            userInfo?.account_status == 1 ?
                                                                <>
                                                                    {/* <p className="text-muted text-center">{userInfo?.rank_status}</p>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>
                                                            <span style={{fontSize:12}}>{userInfo?.direct_count} Direct</span>
                                                            <span style={{width:5, height:5, backgroundColor:"#000", borderRadius:100}}></span>
                                                            <span style={{fontSize:12}}>{downlineInfo.length} Downline</span>
                                                        </div> */}
                                                                    {/* <ul className="list-group list-group-unbordered mb-3">
                                                            <li style={{ cursor: "pointer" }} onClick={() => window.location.href = "/unpaid"} className="list-group-item">
                                                                <b>Unpaid</b>  <a className="float-right">{userInfo?.unpaid_account}</a>
                                                            </li>
                                                            <li className="list-group-item">
                                                                <b>Direct</b> <a className="float-right">{userInfo?.direct_count}</a>
                                                            </li>
                                                            <li className="list-group-item"><b>Downline</b> <a className="float-right">{userInfo?.downline_count}</a></li>
                                                        </ul> */}
                                                                    {/* onClick={() => ClickCopied(userInfo.user_id)} */}
                                                                    <CopyToClipboard text={ENV.DOMAIN + 'register/' + userInfo.user_id}
                                                                        style={{ border: "1px solid #ddd", padding: 2, borderRadius: 5, fontWeight: 200, fontSize: 12, textAlign: "left" }}
                                                                        onCopy={() => {
                                                                            setCopied({ id: userInfo.user_id, status: true })
                                                                            setTimeout(() => {
                                                                                setCopied({ id: userInfo.user_id, status: false })
                                                                            }, 1000)
                                                                        }}>
                                                                        {
                                                                            Copied.id == userInfo.user_id && Copied.status == true ?
                                                                                <a className="btn btn-yellow btn-block">
                                                                                    <b>Copied</b>
                                                                                </a>
                                                                                :
                                                                                <a className="btn btn-primary btn-block">
                                                                                    <b>Referral Link</b>
                                                                                </a>
                                                                        }
                                                                    </CopyToClipboard>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    userInfo?.account_status == 0 ?
                                                        <>
                                                            <p className="text-muted text-center">Unpaid Account</p>
                                                            {
                                                                OpenActivateForm ?
                                                                    <>
                                                                        <form onSubmit={ActivateAccount}>
                                                                            <div className="form-group">
                                                                                <label htmlFor="exampleInputPassword1">Enter Activation Code</label>
                                                                                <input type="text" onChange={(e) => setActivationCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code " />
                                                                            </div>
                                                                            <div className='margin' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                <button type='button' onClick={() => { setOpenActivateForm(false); setActivationCode('') }} className="btn btn-danger"><b>Cancel </b></button>
                                                                                <button type='submit' className="btn btn-default"><b>Activate Now </b></button>
                                                                            </div>
                                                                        </form>
                                                                    </>
                                                                    :
                                                                    <a onClick={() => { setOpenActivateForm(true); }} className="btn btn-primary btn-block">
                                                                        <b>Activate my Account</b>
                                                                    </a>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">
                                        <div className="text-center">
                                            <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user2-160x160.jpg" alt="User profile picture" />
                                        </div>
                                        <h3 className="profile-username text-center">{userInfo?.fullname != null ? userInfo?.fullname : 'Loading...'}</h3>
                                        {
                                            userInfo?.account_status == 0 ?
                                                <>
                                                    <p className="text-muted text-center">Unpaid Account</p>
                                                    {
                                                        OpenActivateForm ?
                                                            <>
                                                                <form onSubmit={ActivateAccount}>
                                                                    <div className="form-group">
                                                                        <label htmlFor="exampleInputPassword1">Enter Activation Code</label>
                                                                        <input type="text" onChange={(e) => setActivationCode(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder="Code " />
                                                                    </div>
                                                                    <div className='margin' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                        <button type='button' onClick={() => { setOpenActivateForm(false); setActivationCode('') }} className="btn btn-danger"><b>Cancel </b></button>
                                                                        <button type='submit' className="btn btn-default"><b>Activate Now </b></button>
                                                                    </div>
                                                                </form>
                                                            </>
                                                            :
                                                            <a onClick={() => { setOpenActivateForm(true); }} className="btn btn-primary btn-block"><b>Activate my Account</b></a>
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            userInfo?.account_status == 1 ?
                                                <>
                                                    <p className="text-muted text-center">{userInfo?.rank_status}</p>
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>
                                                        <span style={{fontSize:12}}>{userInfo?.direct_count} Direct</span>
                                                        <span style={{width:5, height:5, backgroundColor:"#000", borderRadius:100}}></span>
                                                        <span style={{fontSize:12}}>{downlineInfo.length} Downline</span>
                                                    </div>
                                                    <CopyToClipboard text={ENV.DOMAIN + 'register/' + userInfo.user_id}
                                                        style={{ border: "1px solid #ddd", padding: 5, borderRadius: 10 }}
                                                        onCopy={() => {
                                                            setCopied({ id: userInfo.user_id, status: true })
                                                            setTimeout(() => {
                                                                setCopied({ id: userInfo.user_id, status: false })
                                                            }, 1000)
                                                        }}>
                                                        {
                                                            Copied.id == userInfo.user_id && Copied.status == true ?
                                                                <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                :
                                                                <a className="btn btn-primary btn-block"><b>Referral Link</b></a>
                                                        }
                                                    </CopyToClipboard>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div> */}

                                    <div style={{ display: "none" }}>
                                        <div style={{ paddingTop: 0, paddingBottom: 5 }}>
                                            <span>Dashboard</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-6">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h4>
                                                            $ {userInfo?.total_earning ? userInfo?.total_earning : '0.00'}</h4>
                                                        <p>Total Earnings</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="ion ion-bag" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-6">
                                                {/* small box */}
                                                <div className="small-box bg-warning">
                                                    <div className="inner">
                                                        <h4>
                                                            $ {userInfo?.referral_bonus ? userInfo?.referral_bonus : '0.00'}</h4>
                                                        <p>Weekly Bonus</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="ion ion-person-add" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-lg-3 col-6">
                                                {/* small box */}
                                                <div className="small-box bg-primary">
                                                    <div className="inner">
                                                        <h4>
                                                            $ {userInfo?.matrix_bonus ? userInfo?.matrix_bonus : '0.00'}</h4>
                                                        <p>Monthly Bonus</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="ion ion-pie-graph" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-lg-3 col-6">
                                                {/* small box */}
                                                <div className="small-box bg-danger">
                                                    <div className="inner">
                                                        <h4>
                                                            $ 0.00</h4>
                                                        <p>Year-end Profit Sharing</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="ion ion-pie-graph" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.col */}
                                {/* <div style={{width:"100%"}}>
                                <div style={{padding:5, border:"1px solid #ddd", width:"100%", backgroundColor:"#fff"}}>Change Password</div>
                            </div> */}
                                {
                                    userInfo?.account_status == 1 ?
                                        <div className="col-md-9" style={{ display: "none" }}>
                                            <div className="card">
                                                <div className="card-header p-2">
                                                    <ul className="nav nav-pills">
                                                        <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" href="#wallet" data-toggle="tab">
                                                                <span>My Wallet</span>
                                                            </a>
                                                        </li>
                                                        <li className="nav-item" style={{ display: "none" }}>
                                                            <a className="nav-link" href="#purchases" data-toggle="tab">
                                                                <span>My Purchases</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* /.card-header */}
                                                <div className="card-body">
                                                    <div className='tab-content'>
                                                        <div className='active tab-pane' id="activity">
                                                            {
                                                                activitylist.map((item, key) => {
                                                                    return (
                                                                        <div className="card" style={{ padding: 10, marginBottom: 5 }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                                <div>
                                                                                    {item.description}
                                                                                    {/* <b>{item.fullname}</b> */}
                                                                                    <br />
                                                                                </div>
                                                                                {
                                                                                    item.status == 'matrix_refill' ?
                                                                                        <small>Refilled</small>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item.status == 'matrix' ?
                                                                                        <small>Monthly Bonus</small>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item.status == 'unilevel' ?
                                                                                        <small>Unilevel Bonus</small>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {/* <small>{item.status}</small> */}
                                                                            </div>
                                                                            <div style={{ border: "0.5px solid #ddd" }}></div>
                                                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "50%" }}>
                                                                                    <small>{item.date_time}</small>
                                                                                </div>
                                                                                {/* <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                            <small><b>Placement</b></small>
                                                                            <small>{item.placement_name}</small>
                                                                            <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                        </div> */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        <div className='tab-pane' id="wallet">
                                                            {
                                                                payoutForm ?
                                                                    <>
                                                                        <div><span style={{ fontSize: 18, fontWeight: "500" }}>Payout</span></div>
                                                                        <div><span style={{ fontSize: 25, fontWeight: "800" }}>GCash Account</span></div>
                                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}><span>Bal:</span>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                            </svg>
                                                                            <span style={{ fontSize: 25, fontWeight: "800" }}>{balance ? balance : userInfo?.balance}</span></div>
                                                                        <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                                                                            <div>
                                                                                <label>Account Name</label>
                                                                                <input type="text" onChange={(e) => setAccountName(e.target.value)} className="form-control" placeholder="" />
                                                                            </div>
                                                                            <div>
                                                                                <label>Account Number</label>
                                                                                <input type="text" onChange={(e) => setAccountNumber(e.target.value)} className="form-control" placeholder="" />
                                                                            </div>
                                                                            <div>
                                                                                <label>Enter Amount</label>
                                                                                <input type="text" onChange={(e) => setAmountRequest(e.target.value)} className="form-control" placeholder="0.00" />
                                                                            </div>
                                                                            {
                                                                                insufficientalert ?
                                                                                    <div style={{ color: "red" }}>Insufficient</div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div>
                                                                                <label>Enter Password</label>
                                                                                <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="" />
                                                                            </div>
                                                                            {
                                                                                incorrectpassword ?
                                                                                    <div style={{ color: "red" }}>Incorrect Password</div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                            <div style={{ display: "flex", flexGrow: 1, gap: 5 }}>
                                                                                <button onClick={() => setPayoutForm(false)} className="btn btn-danger">Cancel</button>
                                                                                <button className="btn btn-primary btn-block" onClick={() => sendRequest()}>Request Payout</button>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>Balance:
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                                </svg>
                                                                                <span style={{ fontSize: 25, fontWeight: "800" }}>{balance ? balance : userInfo?.balance}</span></div>

                                                                            <a className="nav-link" href="#wallet" data-toggle="tab" onClick={() => {
                                                                                setPayoutForm(true);
                                                                                // alert('SOON to Payout')
                                                                            }}>Payout</a>
                                                                        </div>
                                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                                                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>Total Payout:
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                                </svg>
                                                                                <span style={{ fontSize: 25, fontWeight: "800" }}>{totalpayout}</span></div>
                                                                            {/* <a className="nav-link" href="#wallet" data-toggle="tab" onClick={() => onHistory()}>History</a> */}
                                                                        </div>
                                                                        {/* {
                                                                !useCheckMobileScreen() ?  */}
                                                                        <div style={{ width: "100%", display: "flex", flexDirection: "column-reverse", gap: 2 }}>
                                                                            {
                                                                                payoutlist.map((item) => {
                                                                                    return (
                                                                                        <div style={{ padding: 5, border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#fff", display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                                                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start" }}>
                                                                                                <div style={{ width: 150, fontSize: 12, display: "flex", flexDirection: "row", alignItems: "center" }}>{item.date_payout}</div>
                                                                                                <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>Amnt:
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                                                    </svg>
                                                                                                    {item.amount}</div>
                                                                                                <div style={{ width: 150, fontSize: 12, textAlign: "center", display: "flex", flexDirection: "row", alignItems: "center" }}>Fee:
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                                        <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                                                    </svg>
                                                                                                    {item.fee}</div>
                                                                                                <div style={{ width: 100, fontSize: 12, textTransform: "capitalize", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                                                                    {Status(item)}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start" }}>
                                                                                                {
                                                                                                    item.date_approved != '' && item.status != 'deducted' ?
                                                                                                        <>
                                                                                                            <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                                                                                                <small>Account Name</small>
                                                                                                                <br />
                                                                                                                {item.account_name}
                                                                                                            </div>
                                                                                                            <div style={{ width: 150, fontSize: 12, textAlign: "left" }}>
                                                                                                                <small>Account Number</small>
                                                                                                                <br />
                                                                                                                {item.account_number}
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <></>
                                                                                                }
                                                                                                {
                                                                                                    item.date_approved == '' && item.status == 'pending' ?
                                                                                                        btnstatus(item)
                                                                                                        :
                                                                                                        null
                                                                                                    // item.status == 'cancelled'?
                                                                                                    // <span style={{color:"red", fontSize:10}}>Cancelled</span>
                                                                                                    // :
                                                                                                    // <a onClick={()=>CancelRequest(item)} style={{ width: 100, fontSize:10, color:"red", textDecoration:"underline" }}>Cancel Payout</a>
                                                                                                }

                                                                                            </div>
                                                                                            {
                                                                                                item.date_approved != '' && item.status == 'cancelled' ?
                                                                                                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                                                                                        <div>{item.date_approved}</div>
                                                                                                        <div>{btnstatus(item)}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                item.date_approved != '' && item.status == 'declined' ?
                                                                                                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                                                                                        <div>{item.date_approved}</div>
                                                                                                        <div>{btnstatus(item)}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                item.date_approved != '' && item.status == 'approved' ?
                                                                                                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                                                                                        <div>{item.date_approved}</div>
                                                                                                        <div>{btnstatus(item)}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                item.date_approved != '' && item.status == 'deducted' ?
                                                                                                    <div style={{ borderTop: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 2, fontSize: 10 }}>
                                                                                                        <div>{item.date_approved}</div>
                                                                                                        <div>{btnstatus(item)}</div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        {/* :
                                                                    null
                                                            } */}
                                                                    </>
                                                            }
                                                        </div>
                                                        <div className='tab-pane' id="purchases">
                                                            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                                                <button style={{ borderRadius: 5, border: "1px solid #ddd", backgroundColor: "#007bff", color: "#fff", width: 100, height: 40 }}>Order</button>
                                                            </div>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column-reverse', gap: 2 }}>
                                                                <div style={{ padding: 5, border: '1px solid rgb(221, 221, 221)', borderRadius: 5, backgroundColor: 'rgb(255, 255, 255)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }}>
                                                                        <div style={{ width: 150, fontSize: 12, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>2023-08-11 02:04:00</div>
                                                                        <div style={{ width: 150, fontSize: 12, textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            Amnt:
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                            </svg>
                                                                            22.50
                                                                        </div>
                                                                        <div style={{ width: 150, fontSize: 12, textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                            Fee:
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                                                                <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                            </svg>
                                                                            2.50
                                                                        </div>
                                                                        <div style={{ width: 100, fontSize: 12, textTransform: 'capitalize', display: 'flex', flexDirection: 'row', alignItems: 'center' }} />
                                                                    </div>
                                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, justifyContent: 'flex-start' }}>
                                                                        <div style={{ width: 150, fontSize: 12, textAlign: 'left' }}>
                                                                            <small>Account Name</small><br />
                                                                            Ricky O Trinidad
                                                                        </div>
                                                                        <div style={{ width: 150, fontSize: 12, textAlign: 'left' }}>
                                                                            <small>Account Number</small><br />
                                                                            09612882024
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ borderTop: '1px solid rgb(221, 221, 221)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 2, fontSize: 10 }}>
                                                                        <div>2023-08-11 15:36:43</div>
                                                                        <div><span style={{ color: 'green', fontSize: 10 }}>Approved</span></div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                            {/* /.card */}
                                        </div>
                                        :
                                        null
                                }
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                        <div onClick={() => window.location.assign('/dashboard')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                            <i className='pi pi-microsoft' />
                            <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                        </div>
                        {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className='pi pi-shopping-bag' />
                            <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                        </div> */}
                        <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className='pi pi-shopping-bag' />
                            <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                            <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                        </div>
                        <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className='pi pi-wallet' />
                            <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                        </div>
                        <div style={{ color:"#fff", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#71a3f5", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                            <i className='pi pi-user' />
                            <span style={{fontSize: 12, color:"#fff"}}>Profile</span>
                        </div>
                    </div>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
                {/* /.control-sidebar */}
                <div id="sidebar-overlay" />
            </div>
        </>
    );
}

export default Profile;
