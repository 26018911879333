import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminPayout = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payoutlist, setPayoutList] = useState([])
    const [totalPayout, setTotalPayout] = useState(0)
    const [inputvalue, setInputValue] = useState({"comment":""})


    const payout_request_list = async () => {

        var formData = new FormData();
        formData.append("payout_request_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }

    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_request_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])





    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);
        formData.append("comment", inputvalue.comment);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });

    }

    const ApprovedRequest = async (item, status, inputvalue) => {
        console.log(item.user_id, item.id)
        var formData = new FormData();
        formData.append("approve_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("ref_number", null);
        // formData.append("fee", item.fee);
        formData.append("status", status);
        formData.append("comment", inputvalue.comment);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });
    }

    const calculate = (num, _num) => {
        return (num + _num)
    }


    useEffect(() => {

    }, [])

    const payoutlistfiltered = payoutlist.filter((item) => item.status == 'pending');
    
    const TotalPayout = payoutlistfiltered?.reduce((amount, item) => amount = Number(item.amount) + amount, 0).toFixed(2);
    const TotalFee = payoutlistfiltered?.reduce((amount, item) => amount = Number(item.fee) + amount, 0);

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-12" style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                <h1 className="m-0">Payout Request</h1>
                                <div>
                                    <div style={{fontSize:20, fontWeight:"bolder"}}>Payout: {TotalPayout}</div>
                                    <div style={{fontSize:20, fontWeight:"bolder"}}>Service Fee: {TotalFee}</div>
                                </div>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 5 }}>
                                    {
                                        payoutlist.map((item) => {
                                            if (item.status == 'pending') {
                                                return (
                                                    <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center" }}>
                                                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                                                                    <div>{item.date_payout}</div>
                                                                </div>
                                                                <div>{item.status}</div>
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <span style={{ fontWeight: "600", fontSize: 20 }}>{item.payout_method}</span>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                                    <span style={{ fontWeight: "600", fontSize: 20 }}>{item.fullname}</span>
                                                                </div>
                                                                <div>ID: <span style={{ fontWeight: "600", fontSize: 20 }}>{item.user_id}</span></div>
                                                                <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center" }}>
                                                                    <div style={{ width: 100, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Amount</small>
                                                                        <div>${item.amount}</div>
                                                                    </div>
                                                                    <div style={{ width: 100, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Fee</small>
                                                                        <div>${item.fee}</div>
                                                                    </div>
                                                                    <div style={{ width: 150, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Total</small>
                                                                        <div>${calculate(Number(item.amount), Number(item.fee))}</div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                    <div style={{ width: 150, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Account Name</small>
                                                                        <div>{item.account_name}</div>
                                                                    </div>
                                                                    <div style={{ width: 150, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Account Number</small>
                                                                        <div>{item.account_number}</div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: 100, textAlign: "left" }}>
                                                                    <small style={{ fontWeight: "600" }}>Selfie Picture</small>
                                                                    <div>
                                                                        <img src={ENV.DOMAIN+'dist/img/payout/'+item.selfie_image} style={{width: 150, height: 200, borderRadius: 10, border: "2px solid #ddd"}} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: 10, justifyContent: "flex-start", alignItems: "flex-start" }}>
                                                                    <div style={{ width: 150, textAlign: "left" }}>
                                                                        <small style={{ fontWeight: "600" }}>Comment</small>
                                                                        <div>
                                                                            <textarea name='comment' onChange={(e)=>setInputValue(prev=>({...prev, [e.target.name]: e.target.value}))} style={{width: "80vw", height:"100%", borderRadius:5, border:"1px solid #ddd", resize:"none"}}></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div style={{ display: "flex", flexDirection: "column", gap: 10, paddingTop:10, paddingBottom:5, justifyContent: "flex-end" }}>
                                                                    <div style={{ width: 100, display: "flex", flexDirection: "row", gap: 5 }}>
                                                                        <button style={{ backgroundColor: "#007bff", borderRadius: 5, border: "1px solid #ddd", color: "#fff" }} onClick={() => ApprovedRequest(item, 'approved', inputvalue)}>Approve</button>
                                                                        <button style={{ backgroundColor: "red", borderRadius: 5, border: "1px solid #ddd", color: "#fff" }} onClick={() => CancelRequest(item, 'declined', inputvalue)}>Decline</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div>
            </footer> */}
        </div>

    );
}

export default AdminPayout;
