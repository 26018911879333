import React from 'react'
//stgcinc888@gmail.com

const SUBSCRIPTION = '';

// const DOMAIN = 'http://bqmhospital.com/';
// const DOMAIN = 'https://pluggedin.tagaytaycafe.org/php/';
// const DOMAIN = 'http://127.0.0.1/drinkgood/';
// const DOMAIN = 'http://192.168.61.116/drinkgood/';
// const DOMAIN = 'http://192.168.1.20/drinkgood/';
const DOMAIN = 'https://drdecuplepi.org/';
// const DOMAIN = 'https://drinkgood.tagaytaycafe.org/';
// const DOMAIN = 'http://192.168.14.116/pluggedin/php/';
// const DOMAIN = 'https://directselling.gblessnetwork.net/';

const ENV =  {
    DOMAIN,
}

export default ENV
