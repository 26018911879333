import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';
import ConfirmPaymentEntry from './extra/admin/ConfirmPaymentEntry';

const AdminAccessAccount = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [useraccountlist, setUserAccountlist] = useState([])
    const [userlist, setUserlist] = useState([])
    const [search, setSearch] = useState('')


    const refreshCodeList = async () => {
        var formData = new FormData();
        formData.append("kyc_list", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
        }).catch((err)=>{

        });
    }


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("user_accounts", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            setUserAccountlist(res.data)
        }).catch((err)=>{

        });
    }, [ENV])


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("users", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'kyc_form.php',
            data: formData,
        }).then((res)=>{
            setUserlist(res.data)
        }).catch((err)=>{

        });
    }, [ENV])



    const SearchData = (data, value)  => {
        if (value!='') {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.owner_name.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase())) 
            || (i?.code.toLowerCase().includes(value.trim().toLowerCase()))
            || (i?.confirm == 0 ? 'paid' : 'unpaid').toLowerCase().includes(value.trim().toLowerCase())
            );
        }
        return data;
    }

    const buttonProcess = (status) => {
        if (status === 'approve') {
            alert("Under Construction")
        } else if (status === 'decline') {
            alert("Under Construction")
        }
    }

// console.log(useraccountlist)

    if(window.innerWidth <= 768) {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0">Paid Code</h5>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div style={{padding:5}}>
                            <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search...' style={{padding:5, height:30, borderRadius:5, border:"1px solid #ddd"}} />
                        </div>
                        <div style={{display:"flex", flexDirection:"column", gap:5}}>
                        {
                                    SearchData(useraccountlist, search).map((item) => {
                                        if(item.owner_id !='' && item.paid == 1) {
                                            return (
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: item.user_id != '' ? "#cf9999" : "#fff", color: item.user_id != '' ? "#fff" : "#000", borderRadius: 5, padding: 5, borderBottom:"1px solid #ddd" }}>
                                                    <div style={{ display: "flex", width: "50%", flexDirection: "column" }}>
                                                        <div style={{ fontSize: 15 }}>Name: {item.owner_name}</div>
                                                        <div style={{ fontSize: 15 }}>Code: {item.code}</div>
                                                        {
                                                            item.user_id != '' ?
                                                            <>
                                                                <div style={{ fontSize: 15 }}>Used By: {item.fullname}</div>
                                                                <div style={{ fontSize: 15 }}>Date: {item.date_used}</div>
                                                            </>
                                                            :null
                                                        }
                                                    </div>
                                                    <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                                        <div style={{ textAlign: "right", fontSize: 12 }}>Created: {item.date_time}</div>
                                                        <div style={{ textAlign: "right", fontSize: 15 }}>
                                                            <div style={{color:item.paid == 0 ? "red" : "green"}}>{item.paid == 0 ? "Unpaid" : "Paid"}</div>
                                                            <div>{item.comment}</div>
                                                            {
                                                                item.confirm == 1 ?
                                                                <ConfirmPaymentEntry refreshCodeList={refreshCodeList} item={item} />
                                                                :null
                                                            }
                                                        </div>
    
    
    
                                                        {/* <CopyToClipboard text={item.code}
                                                            style={{ border: "1px solid #ddd", padding: 2, borderRadius: 5, fontWeight: 200, fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
                                                            onCopy={() => {
                                                                setCopied({ code: item.code, status: true })
                                                                setTimeout(() => {
                                                                    setCopied({ code: item.code, status: false })
                                                                }, 1000)
                                                            }}>
                                                            {
                                                                Copied.code == item.code && Copied.status == true ?
                                                                    <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                    :
                                                                    <a className="btn btn-primary btn-block"><b>Copy</b></a>
                                                            }
                                                        </CopyToClipboard> */}
                                                        {/* <button style={{borderRadius:5, outline:"none", border:"1px solid #ddd", fontSize:13, width:100}}>Copy</button> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                        </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <h1 className="m-0">User Account</h1>
                                    <span>
                                        <input type='text' onChange={(e)=>setSearch(e.target.value)} style={{width: 300, height: 40, borderRadius: 5, border:"1px solid #ddd", padding: 5}} placeholder='Search' />
                                    </span>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                {/* Left col */}
                                {/* /.Left col */}
                                {/* right col (We are only adding the ID to make the widgets sortable)*/}
                                
                                <div className='col-sm-12'>
                                    <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                                    {
                                        [...useraccountlist].filter((i)=>i?.fullname.toLowerCase().includes(search.trim().toLowerCase()) && i?.user_id !== '000000000').map((item)=>{
                                            return (
                                                <div style={{width: "100%", border: "1px solid #ddd", padding: 5, backgroundColor: "#fff"}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>Account ID: <span style={{fontWeight: "bolder"}}>{item.user_id}</span></span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>Name: <span style={{fontWeight: "bolder"}}>{item.fullname}</span></span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>Username: <span style={{fontWeight: "bolder"}}>{item.username}</span></span>
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <span>Password: <span style={{fontWeight: "bolder"}}>{item.password}</span></span>
                                                    </div>
                                                </div>
                                            )
                                        }).slice(0,10)
                                    }
                                    </div>
                                </div>


                                {/* right col */}
                            </div>
                            {/* /.row (main row) */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>

        );
    }



}

export default AdminAccessAccount;
