import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { SpeedDial } from 'primereact/speeddial';
import { Toast } from 'primereact/toast';

const Cart = ({userInfo, cartlist, setCartList, cartLoad}) => {

    const toast = useRef(null);
    // { cartlist, setCartList, cartLoad, item, userInfo, checked, setChecked }


    const [subtotal, setSubTotal] = useState(0);
    const [modepayment, setModePayment] = useState('COD');
    const [discounted, setDiscount] = useState(0);
    const [shippingfee, setShippingfee] = useState(0.25);


    const [checked, setChecked] = useState([]);
    const handleCheck = (event, item) => {
        const intex = cartlist.findIndex(x => x.cart_id == item?.cart_id);

        var updatedList = [...checked];
        if (event == 'checked') {
            updatedList = [...checked, { ...item }];
            cartlist[intex].checked = true;
            console.log(cartlist)
        } else {
            cartlist[intex].checked = false;
            updatedList = updatedList.filter((i) => {
                return i.cart_id !== item.cart_id;
            });
        }



        // console.log(updatedList)
        setCartList(cartlist)
        setChecked(updatedList);
    };

    const handleRemove = async (e, item) => {
        var formData = new FormData();
        formData.append("remove_cart", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("cart_id", item.cart_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;

            cartLoad();

            let updatedList = cartlist.filter((i) => {
                return i.cart_id !== item.cart_id;
            });
            setChecked(updatedList);
            // setCartList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }


    
    const SubTotal = checked?.reduce((amount, item) => amount = (item.price.split('$')[1] * item.qty) + amount, 0);

    const placeOrder = async () => {
        alert(JSON.stringify(checked)) //(SubTotal + shippingfee)
        var formData = new FormData();
        formData.append("placeorder", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("modepayment", modepayment);
        formData.append("shipping_address", userInfo.address);
        formData.append("subtotal", (SubTotal + userInfo.shippingfee));
        formData.append("shippingfee", userInfo.shippingfee);
        formData.append("items", JSON.stringify(checked));
        // (SubTotal + shippingfee)
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            cartLoad();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            // setChecked(updatedList);
        }).catch((err) => {

        });
    }

    useMemo(async()=>{
        var formData = new FormData();
        formData.append("my_order", 1);
        formData.append("user_id", userInfo.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            // cartLoad();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            // setChecked(updatedList);
        }).catch((err) => {

        });
    }, [ENV]);

    
    const items = [
        {
            label: 'Shop',
            icon: 'pi pi-server',
            command: () => {
                toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            }
        },
        // {
        //     label: 'Update',
        //     icon: 'pi pi-refresh',
        //     command: () => {
        //         toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        //     }
        // },
        // {
        //     label: 'Delete',
        //     icon: 'pi pi-trash',
        //     command: () => {
        //         toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        //     }
        // },
        // {
        //     label: 'React Website',
        //     icon: 'pi pi-external-link',
        //     command: () => {
        //         window.location.href = 'https://facebook.github.io/react/';
        //     }
        // }
    ];



    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 1, marginBottom: 115 }}>
                {
                    cartlist.map((item, key) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", backgroundColor: item.checked == true ? "#ccddff" : "#fff", borderRadius: 0, paddingTop: 5, paddingBottom: 5, boxShadow: ".5px 1.5px 2px .8px rgb(0,0,0,0.5)" }}>
                                <div style={{ padding: 5, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                    {/* <Checkbox type='checkbox' value={item.cart_id} onChange={e =>  handleCheck(e, item)}></Checkbox> */}
                                    {/* <InputText type='checkbox' /> */}

                                    
                                    <div style={{padding:10}}>
                                    {
                                        item.checked == true ?
                                        <span className='pi pi-check-square' style={{fontSize:20}} onClick={(e)=>handleCheck('unchecked', item)}></span>
                                        :
                                        <span className='pi pi-stop' style={{fontSize:20}} onClick={(e)=>handleCheck('checked', item)}></span>
                                    }
                                    </div>
                                    {/* <input type='checkbox' value={item.cart_id} onChange={(e) => handleCheck(e, item)} style={{ width: 30 }} /> */}
                                </div>
                                <div style={{ width: 70, height: 70, backgroundColor: "#ddd", borderRadius: 10 }}>
                                    <img src={ENV.DOMAIN + item.images} style={{ width: 70, borderRadius: 5 }} />
                                </div>
                                <div style={{ backgroundColor: "#fff", height: "auto", flexGrow: 1, padding: 5, backgroundColor: item.checked == true ? "#ccddff" : "#fff", color: item.checked == true ? "#000" : "#000" }}>
                                    <div style={{ fontWeight: 600, fontSize: 18 }}>{item.product_name}</div>
                                    {
                                        item.size ?
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: 13 }}>
                                                <div style={{ fontWeight: 500 }}>Size: Medium</div>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        item.type ?
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: 13 }}>
                                                <div style={{ fontWeight: 500 }}>Type: Water</div>
                                            </div>
                                            :
                                            null
                                    }
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <div style={{ fontWeight: 500 }}>{item.price}</div>
                                        <div style={{ fontWeight: 500 }}>{item.qty ?? 0}</div>
                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                         <div style={{ fontWeight: 500 }}></div>
                                        <div style={{ fontWeight: 500 }}>{'$' + (Number(item.price.split('$')[1]) * Number(item.qty)) ?? 0}</div>
                                    </div>
                                    {
                                        // item.checked == true ?
                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                            <div></div>
                                            <div style={{ color: "red", textDecoration: "underline" }} onClick={(e) => handleRemove(e, item)}>Remove</div>
                                        </div>
                                        // :null
                                    }
                                </div>
                            </div>)
                    })
                }
            </div>

            
            <div style={{ position: "fixed", zIndex: 2, bottom: 0, borderTop: "1px solid #ddd", backgroundColor: "#fff", width: "100%", height: null, display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                <div style={{ padding: 5 }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div>
                            Mode of Payment
                            <select onChange={(e) => setModePayment(e.target.value)} value={modepayment} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 150, height: 35, borderRadius: 5, border: "1px solid #ddd" }}>
                                <option selected disabled>Select</option>
                                <option value={"DG-Wallet"}>DG-Wallet</option>
                                <option value={"COD"}>COD</option>
                            </select>
                        </div>
                        <div style={{ lineHeight: 1, textAlign: "right" }}>
                            <div style={{ fontSize: 15, fontWeight: 600 }}>SubTotal: ${(SubTotal ?? 0).toFixed(2)}</div>
                            {/* <span style={{ fontSize: 12, color: "blue" }}>discounted: -${discounted.toFixed(2)}</span> */}
                            <span style={{ fontSize: 12, color: "blue" }}>Shipping fee: ${userInfo.shippingfee.toFixed(2)}</span>
                            <div style={{ fontSize: 14, fontWeight: 600 }}>Total Payment: ${(modepayment === "DG-Wallet" ? SubTotal > 0 ? (SubTotal + userInfo.shippingfee) - discounted > 0 ? (SubTotal + userInfo.shippingfee) - discounted : 0 : 0 : SubTotal > 0 ? (SubTotal + userInfo.shippingfee) - discounted > 0 ? (SubTotal + userInfo.shippingfee) - discounted : 0 : 0 ?? 0).toFixed(2)}</div>
                        </div>
                    </div>
                </div>
                {
                    modepayment === "DG-Wallet" ?
                        <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: 5 }}>
                            <span>Bal: ${(Number(userInfo?.balance) ?? 0).toFixed(2)}</span>
                            {
                                userInfo?.balance >= SubTotal ?
                                    <></>
                                    :
                                    <span style={{ color: "red", fontSize: 10 }}>[Insufficient Balance]</span>
                            }
                            <span></span>
                        </div>
                        : null
                }
                <div style={{ padding: 10, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, justifyContent: "center", alignItems: "center", textAlign: "center", color: "blue", fontSize: 13 }}>
                        <div>
                            <span className='pi pi-phone'></span> {userInfo.telephone}
                        </div>
                        <div>
                            <span className='pi pi-map-marker'></span> Shipping Address
                        </div>
                    </div>
                    <div style={{ width: 180 }}>
                        {
                            SubTotal - discounted > 0 ?
                                <>
                                    {
                                        userInfo?.balance >= SubTotal ?
                                            <button onClick={() => placeOrder()} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, color: "#fff", fontWeight: 600, height: 35, borderRadius: 5, backgroundColor: "#00b090", border: "1px solid #ddd" }}>Place Order</button>
                                            :
                                            <>
                                                {
                                                    modepayment === "COD" ?
                                                        <button onClick={() => placeOrder()} style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, color: "#fff", fontWeight: 600, height: 35, borderRadius: 5, backgroundColor: "#00b090", border: "1px solid #ddd" }}>Place Order</button>
                                                        :
                                                        <button style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, color: "#fff", fontWeight: 600, height: 35, borderRadius: 5, backgroundColor: "#ddd", border: "1px solid #ddd" }}>Place Order</button>
                                                }
                                            </>
                                    }
                                </>
                                :
                                <button style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 180, color: "#fff", fontWeight: 600, height: 35, borderRadius: 5, backgroundColor: "#ddd", border: "1px solid #ddd" }}>Place Order</button>
                        }
                    </div>
                </div>
                {/* <SpeedDial model={items} direction="up" transitionDelay={80} style={{right:5, bottom: 135}} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined" /> */}
            </div>

        </>
    );
}

export default Cart;
