import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const PUCApplication = (props) => {
    const [visible, setVisible] = useState(false);
    const [application, setApplication] = useState([]);
    const [buttons, setButtons] = useState({"matrix_code": false, "reports": false});
    const [inputvalue, setInputValue] = useState({ "fullname": "", "contact": "contact", "location": "", "referred_id": "", "user_id": props.userInfo.user_id });
    const [reports, setReports] = useState([])
    const [matrixcode, setMatrixCode] = useState([])

    const openDialog = () => {
        setVisible(true);
        setButtons({"matrix_code": false, "reports": false});
        show_matrix_code();
    }

    const hideDialog = () => {
        setVisible(false);
        setButtons({"matrix_code": false, "reports": false});
    }

    const show_matrix_code = async () => {
        var formData = new FormData();
        formData.append("show_matrix_code", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setMatrixCode(res.data)
        });
    }

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_matrix_code", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setMatrixCode(res.data)
        });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_matrix_report", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setReports(res.data)
            // console.log(res.data)
        });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_application", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setApplication(res.data)
            // console.log(res.data)
        });
    }, [ENV]);


    const SubmitApplication = async () => {
        var formData = new FormData();
        formData.append("submit_application", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        if (inputvalue.fullname !== '' && inputvalue.contact !== '' && inputvalue.location !== '') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                if (res.data[0].status === 'success') {
                    window.location.assign('/pickupcenter')
                }
                // console.log(res.data)
            });
        }
    }

    return (
        <>
            <Sidebar visible={visible} icons={
                [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'pending').length > 0 ?
                    null
                    :
                    [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'approved').length > 0 ?
                    <div style={{width: "100%", fontWeight: "bolder"}}>PUC Profile</div>
                    :
                    <div style={{ textAlign: "left" }}>Pick-Up Center Application</div>
            } blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 500, borderRadius: 0 }} onHide={() => hideDialog()}>
                {
                    [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'pending').length > 0 ?
                        <div style={{ textAlign: "center", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>Pending Application</div>
                        :
                        [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'approved').length > 0 ?
                            <>
                                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                    <span onClick={()=>setButtons({"matrix_code": true, "reports": false})}className='btn btn-info btn-sm'>Matrix Code</span>
                                    <span onClick={()=>setButtons({"matrix_code": false, "reports": true})}className='btn btn-danger btn-sm'>Reports</span>
                                </div>
                                <hr />
                                {
                                    buttons.matrix_code === true ?
                                        <>
                                            <div style={{display: "flex", flexDirection: "column", gap: 2}}>
                                                {
                                                    [...matrixcode].filter((i) => i.puc_id === inputvalue.user_id && i.status === 'open').map((item) => {
                                                        return (
                                                            <div onClick={()=>alert(JSON.stringify(item))} style={{ padding: 5, borderRadius: 5, border: "1px solid #ddd", display: "flex", flexDirection: "column" }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div>Code: <span style={{ fontWeight: "bolder" }}>{item.code}</span></div>
                                                                    <div>Price: {item.amount}</div>
                                                                </div>
                                                                {/* <div>Scan QrCode</div> */}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                    :
                                    buttons.reports === true ?
                                    <>
                                        <div>
                                            {/* {
                                                [...reports].filter((i)=>i.puc_id === inputvalue.user_id && i.status === 'open').map((item)=>{
                                                    return (
                                                        <div>
                                                            <div>{item.code}</div>
                                                        </div>
                                                    )
                                                })
                                            } */}
                                        </div>
                                    </>
                                    :
                                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                        {/* <div>
                                            <div style={{width: "100%", height: 100, backgroundColor: "#ddd", border: "1px solid #ddd"}}></div>
                                            <button>Upload Image</button>
                                        </div> */}
                                        <span style={{fontSize: 30, fontWeight: "bolder"}}>SOON</span>
                                        <span style={{fontWeight: "bolder"}}>Under Construction</span>
                                    </div>
                                }
                            </>
                            :
                            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                                <div>
                                    <span>Fullname <span style={{ color: "red" }}>*</span></span>
                                    <input name='fullname' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <span>Contact No. <span style={{ color: "red" }}>*</span></span>
                                    <input name='contact' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <span>Location <span style={{ color: "red" }}>*</span></span>
                                    <input name='location' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <span>Referred By <span style={{color: "#ddd"}}>(Account ID)</span> <span style={{ color: "red" }}>*</span> </span>
                                    <input name='referred_id' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                                </div>
                                <div>
                                    <button className='btn btn-info btn-sm' onClick={() => SubmitApplication()}>Submit Application</button>
                                </div>
                            </div>
                }
            </Sidebar>
            {
                application.length > 0 ?
                    [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'pending').length > 0 ?
                        <div onClick={() => openDialog()} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "orange" }}>
                            Pending Application
                        </div>
                        :
                        [...application].filter((i) => i.user_id === props.userInfo.user_id && i.status === 'approved').length > 0 ?
                            <div onClick={() => openDialog()} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "green", color: "#fff" }}>PUC Profile</div>
                            :
                            <div onClick={() => openDialog()} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "green", color: "#fff" }}>PUC Application</div>
                    :
                    <div onClick={() => openDialog()} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "green", color: "#fff" }}>PUC Application</div>
            }
        </>
    );
}

export default PUCApplication;
