import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminNavbar from './component/AdminNavbar';
import AdminHeader from './component/AdminHeader';
import ENV from './ENV';
import axios from 'axios';
import { Chart as ChartJS } from "chart.js/auto"
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import CanvasJSReact from '@canvasjs/react-charts';
import WeeklyEntrySummary from './extra/WeeklyEntrySummary';
import { format } from 'date-fns';
import { Tooltip } from 'primereact/tooltip';

const AdminDashboard = ({ userInfo }) => {
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';


    const [registered, setRegistered] = useState(0);
    const [usdRate, setUSDRate] = useState(0);
    const [lastpayoutlist, setLastPayoutList] = useState([]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("admin_dashboard", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setRegistered(res.data[0])
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    useMemo(async () => {
        var formData = new FormData();
        formData.append("admin_last_payout", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setLastPayoutList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);



    useMemo(async () => {
        // var formData = new FormData();
        // formData.append("admin_dashboard", 1);
        await axios({
            method: "GET",
            url: 'https://api.exchangerate-api.com/v4/latest/USD',
            // data: formData,
        }).then((res) => {
            // console.log(res.data.rates.PHP)
            setUSDRate(res.data.rates.PHP)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    
    const [dataPoints, setdataPoints] = useState([])
    const [salesMatrix, setSalesMatrix] = useState([])
    const [monthlypayout, setMonthlyPayout] = useState([])
    const [performerdirectreferral, setPerformerDirectReferral] = useState([])
    const [performerbuildingnetwork, setPerformerBuildingNetwork] = useState([])
    const [weeklyentry, setWeeklyEntry] = useState([])
    const [matrixrefill, setMatrixRefill] = useState([])

    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("monthly_entry", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setdataPoints(res.data)
            // console.log(res.data)
        });
    }, [setdataPoints]);

    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("sales_matrix", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setSalesMatrix(res.data)
            // console.log(res.data)
        });
    }, [setSalesMatrix]);

    const optionss = {
        title: {
            fontSize: 15,
            text: "Monthly Entry"
        },
        data: [{
            type: "column",
            dataPoints: dataPoints
        }]
    }

    const salesData = {
        // theme: "light2", // "light1", "light2", "dark1"
        // text: "Top 10 Most Viewed YouTube Videos",
        title: {
            fontSize: 15,
            text: "Daily Sales"
        },
        axisX: {
          margin: 10,
          labelPlacement: "inside",
          tickPlacement: "inside"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "$"
        },
        data: [{
            type: "column",
            yValueFormatString: "$##,###.##",
            dataPoints: salesMatrix
        }]
    }

    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("monthly_payout", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setMonthlyPayout(res.data)
            // console.log(res.data)
        });
    }, [setdataPoints]);

    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("top_performer_direct_referral", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPerformerDirectReferral(res.data)
            // console.log(res.data)
        });
    }, [ENV]);

    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("top_performer_building_network", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPerformerBuildingNetwork(res.data)
            // console.log(res.data)
        });
    }, [ENV]);

    const [adminfund, setAdminFund] = useState(0)
    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("admin_fund", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setAdminFund(res.data)
            // console.log(res.data)
        })
    }, [ENV]);

    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("week_entry", 1);
        await axios({
            method: "post",
            // url: 'https://drinkgood.tagaytaycafe.org/sample.php',
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setWeeklyEntry(res.data)
            // console.log(res.data)
        })
    }, [ENV]);

    const [payinlist, setPayinList] = useState([])

    useMemo(async () => {

        var formData = new FormData();
        formData.append("payin_summary", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayinList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    useMemo(async()=>{        
        // var formData = new FormData();
        // formData.append("matrix_refill_report", 1);
        // await axios({
        //     method: "post",
        //     url: ENV.DOMAIN + 'process.php',
        //     data: formData,
        // }).then((res) => {
        //     setMatrixRefill(res.data)
        //     console.log(res.data)
        // })
    }, [ENV]);

    const [justActivationList, setJustActivationList] = useState([])
    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("just_enter_activation", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setJustActivationList(res.data)
            // console.log(res.data)
        })
    }, [ENV]);

    const [MatrixRefillList, setMatrixRefillList] = useState([])
    useMemo(async()=>{        
        var formData = new FormData();
        formData.append("matrix_refill_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setMatrixRefillList(res.data)
            // console.log(res.data)
        })
    }, [ENV]);

    const monthly_payout = {
        title: {
            fontSize: 15,
            text: "Total Monthly Payout"
        },
        axisY: {
            // title: "(in USD)",
            prefix: "$"
        },
        data: [{
            type: "column",
            dataPoints: monthlypayout
        }]
    }
    
    const options = {
        animationEnabled: true,
        title: {
            text: "Number of Members"
        },
        // axisY: {
        //     title: "Number of Customers"
        // },
        toolTip: {
            shared: true
        },
        data: [{
            type: "spline",
            name: "this Week",
            showInLegend: true,
            dataPoints: [
                { y: 155, label: "Jan" },
                { y: 150, label: "Feb" },
                { y: 152, label: "Mar" },
                { y: 148, label: "Apr" },
                { y: 142, label: "May" },
                { y: 150, label: "Jun" },
                { y: 146, label: "Jul" },
                { y: 149, label: "Aug" },
                { y: 153, label: "Sept" },
                { y: 158, label: "Oct" },
                { y: 154, label: "Nov" },
                { y: 150, label: "Dec" }
            ]
        },
        {
            type: "spline",
            name: "last Week",
            showInLegend: true,
            dataPoints: [
                { y: 172, label: "Jan" },
                { y: 173, label: "Feb" },
                { y: 175, label: "Mar" },
                { y: 172, label: "Apr" },
                { y: 162, label: "May" },
                { y: 165, label: "Jun" },
                { y: 172, label: "Jul" },
                { y: 168, label: "Aug" },
                { y: 175, label: "Sept" },
                { y: 170, label: "Oct" },
                { y: 165, label: "Nov" },
                { y: 169, label: "Dec" }
            ]
        }]
    }

    const num = 1;


    return (
        <>
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}

                {/* Main Sidebar Container */}
                <AdminNavbar userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">Dashboard</h1>
                                </div>
                                {/* /.col */}
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                                {/* /.col */}
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            {/* Small boxes (Stat box) */}
                            {/* <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{Number(adminfund).toFixed(2)}</h3>
                                            <p>Fund</p>
                                            <p><button className='btn btn-primary btn-sm'>Send Fund</button> <a href='#' style={{color:"#fff"}}>History</a></p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-cash" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row" style={{marginBottom:0}}>
                                <div className="col-lg-6 col-12" style={{marginBottom:0}}>
                                    <div className="small-box bg-info" style={{marginBottom:0}}>
                                        <div className="inner">
                                            <h3>${registered.total_entry_code}</h3>
                                            <span style={{fontSize:18}}>Total Pay-in</span>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-cash" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12" style={{marginBottom:0}}>
                                    <div className="small-box bg-danger" style={{marginBottom:0}}>
                                        {/* <div className="inner">
                                            <h3>${registered.total_payout}</h3>
                                            <p>Total Payout</p>
                                        </div> */}
                                        <div className="inner">
                                            <div>
                                                <h5>${registered.total_payout}</h5>
                                                <span>Total Payout</span>
                                            </div>
                                            <span style={{fontSize:12}}>Pending Payout: ${registered.pending_payout}</span>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-cash" />
                                        </div>
                                        {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{registered.registered}</h3>
                                            <p>Total Registered</p>
                                        </div>
                                        <div className="icon">
                                            <i className="fa fa-users" />
                                        </div>
                                        {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>{registered.paid_account}</h3>
                                            <p>Paid Account</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-cash" />
                                        </div>
                                        {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{registered.unpaid_account}</h3>
                                            <p>Unpaid Account</p>
                                        </div>
                                        <div className="icon">
                                            {/* <i className="ion ion-person-add" /> */}
                                        </div>
                                        {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a> */}
                                    </div>
                                </div>
                            </div>
                            {/* /.row */}
                            {/* Main row */}
                            {
                                localStorage.getItem('adminusername') == 'account' || localStorage.getItem('adminusername') == 'master_admin' ?
                                <>
                                <div className="row">
                                    <div className='col-lg-4' style={{marginBottom:10}}>
                                        <div style={{
                                            backgroundColor:"#17a2b8",
                                            color:"#fff",
                                            width:"100%",
                                            height:null, 
                                            display:"flex",
                                            flexDirection:"column",
                                            padding:10,
                                        }}>
                                            <div style={{fontWeight:800}}>Pay-ins of {format(new Date(), 'yyyy')}</div>
                                            <div></div>
                                        </div>
                                        <div style={{backgroundColor:"#fff", overflow:"scroll", maxHeight:300}} className='hidescroll'>
                                            {
                                                payinlist.map((item) => {
                                                    if (item.Year == format(new Date(), 'yyyy')) {
                                                        return (
                                                            <>
                                                                <Tooltip visible={false} target={".tooltip-button-" + item.id_date_used} autoHide={true} position="right" style={{ display: "flex", flexDirection: "column" }}>
                                                                    <div style={{ color: "#cbb33f", fontWeight: "800" }}>{item.date_used}</div>
                                                                    <div style={{ width: "100%", borderTop: "1px solid white" }}></div>
                                                                    {
                                                                        (item?.list).map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    {item?.date_used} {item?.fullname}
                                                                                    {/* <span style={{ minWidth: '5rem' }}>Count: {count}</span> */}
                                                                                    {/* <Button type="button" icon="pi pi-plus" onClick={() => setCount(count + 1)} className="p-button-rounded p-button-success ml-2"></Button>
                                                                                        <Button type="button" icon="pi pi-minus" onClick={() => setCount(count - 1)} className="p-button-rounded p-button-danger ml-2"></Button> */}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Tooltip>
                                                                <div className={"tooltip-button-" + item.id_date_used} style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "100%", border:"1px solid #ddd" }}>
                                                                    {/* <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                                        <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                                            <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff" }}>Date Of {item.date_used}</div>
                                                                            {
                                                                                item?.list?.map((_item)=>{
                                                                                    return (
                                                                                        <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #ddd"}}>
                                                                                            <div>{_item.date_used}</div>
                                                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10}}>
                                                                                                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0}}>
                                                                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                                        <div>[{_item.user_id}]</div>
                                                                                                        <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.fullname}</span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }                                            
                                                                        </div>
                                                                    </div> */}
                                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                        <div style={{padding:5}}><span style={{fontWeight:"bold"}}>{item.date_used}</span> [{item?.list?.length} Accounts]</div>
                                                                        <div style={{padding:5, display:"flex", flexDirection:"column"}}>
                                                                            <span style={{fontWeight:"bold", textDecoration:"underline"}}>${item.overall}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    } else  {
                                                        return (
                                                            <>
                                                                <Tooltip visible={false} target={".tooltip-button-" + item.id_date_used} autoHide={true} position="right" style={{ display: "flex", flexDirection: "column" }}>
                                                                    <div style={{ color: "#cbb33f", fontWeight: "800" }}>{item.date_used}</div>
                                                                    <div style={{ width: "100%", borderTop: "1px solid white" }}></div>
                                                                    {
                                                                        (item?.list).map((item) => {
                                                                            return (
                                                                                <div>
                                                                                    {item?.date_used} {item?.fullname}
                                                                                    {/* <span style={{ minWidth: '5rem' }}>Count: {count}</span> */}
                                                                                    {/* <Button type="button" icon="pi pi-plus" onClick={() => setCount(count + 1)} className="p-button-rounded p-button-success ml-2"></Button>
                                                                                        <Button type="button" icon="pi pi-minus" onClick={() => setCount(count - 1)} className="p-button-rounded p-button-danger ml-2"></Button> */}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Tooltip>
                                                                <div className={"tooltip-button-" + item.id_date_used} style={{ padding: 0, backgroundColor: "#ddd", display: "flex", flexDirection:"column", width: "100%", borderBottom:"1px solid #a6a6a6" }}>
                                                                    {/* <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                                        <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                                            <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff" }}>Date Of {item.date_used}</div>
                                                                            {
                                                                                item?.list?.map((_item)=>{
                                                                                    return (
                                                                                        <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #ddd"}}>
                                                                                            <div>{_item.date_used}</div>
                                                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10}}>
                                                                                                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0}}>
                                                                                                    <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                                        <div>[{_item.user_id}]</div>
                                                                                                        <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.fullname}</span></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }                                            
                                                                        </div>
                                                                    </div> */}
                                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                        <div style={{padding:5}}><span style={{fontWeight:"bold"}}>{item.date_used}</span> [{item?.list?.length} Accounts]</div>
                                                                        <div style={{padding:5, display:"flex", flexDirection:"column"}}>
                                                                            <span style={{fontWeight:"bold", textDecoration:"underline"}}>${item.overall}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-4' style={{marginBottom:10}}>
                                        <div style={{
                                            backgroundColor:"#17a2b8",
                                            color:"#fff",
                                            width:"100%",
                                            height:null, 
                                            display:"flex",
                                            flexDirection:"column",
                                            padding:10,
                                        }}>
                                            <div style={{fontWeight:800}}>Just entered the activation</div>
                                            <div></div>
                                        </div>
                                        <div style={{backgroundColor:"#fff", overflow:"scroll", maxHeight:300}} className='hidescroll'>
                                            {
                                                justActivationList.map((item, key) => {
                                                    return (
                                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#fff", borderRadius: 5, padding: 5, borderBottom:"1px solid #ddd" }}>
                                                            <div style={{ display: "flex", width: "50%", flexDirection: "column" }}>
                                                                <div style={{ fontSize: 15 }}>Name: <span style={{textDecoration:"underline", fontWeight:600}}>{item.owner_name}</span></div>
                                                                <div style={{ fontSize: 15 }}>Code: <span style={{textDecoration:"underline", fontWeight:600}}>{item.code}</span></div>
                                                                {
                                                                    item.user_id != '' ?
                                                                    <>
                                                                        <div style={{ fontSize: 15 }}>Used By: {item.fullname}</div>
                                                                        <div style={{ fontSize: 15 }}>Date: {item.date_used}</div>
                                                                    </>
                                                                    :null
                                                                }
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                                                <div style={{ textAlign: "right", fontSize: 12 }}>Created: {item.date_time}</div>
                                                                <div style={{ textAlign: "right", fontSize: 15 }}>
                                                                    <span style={{color:item.paid == 0 ? "red" : "green", fontWeight:600}}>{item.paid == 0 ? "Unpaid" : "Paid"}</span>
                                                                </div>
                                                                <div style={{ textAlign: "right", fontSize: 15 }}>
                                                                    <span>{item.comment}</span>
                                                                </div>
            
            
            
                                                                {/* <CopyToClipboard text={item.code}
                                                                    style={{ border: "1px solid #ddd", padding: 2, borderRadius: 5, fontWeight: 200, fontSize: 12, display: "flex", flexDirection: "row", justifyContent: "center" }}
                                                                    onCopy={() => {
                                                                        setCopied({ code: item.code, status: true })
                                                                        setTimeout(() => {
                                                                            setCopied({ code: item.code, status: false })
                                                                        }, 1000)
                                                                    }}>
                                                                    {
                                                                        Copied.code == item.code && Copied.status == true ?
                                                                            <a className="btn btn-yellow btn-block"><b>Copied</b></a>
                                                                            :
                                                                            <a className="btn btn-primary btn-block"><b>Copy</b></a>
                                                                    }
                                                                </CopyToClipboard> */}
                                                                {/* <button style={{borderRadius:5, outline:"none", border:"1px solid #ddd", fontSize:13, width:100}}>Copy</button> */}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className='col-lg-4' style={{marginBottom:10}}>
                                        <div style={{
                                            backgroundColor:"#17a2b8",
                                            color:"#fff",
                                            width:"100%",
                                            height:null, 
                                            display:"flex",
                                            flexDirection:"column",
                                            padding:10,
                                        }}>
                                            <div style={{fontWeight:800}}>Matrix Refill</div>
                                            <div></div>
                                        </div>
                                        <div style={{backgroundColor:"#fff", overflow:"scroll", maxHeight:300}} className='hidescroll'>
                                            {
                                                MatrixRefillList.map((item, key) => {
                                                    return (
                                                        <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "100%", border:"1px solid #ddd" }}>
                                                            <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                                <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                                    <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff" }}>Date Of {item.date_time}</div>
                                                                    {
                                                                        item?.list?.map((_item)=>{
                                                                            return (
                                                                                <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #ddd"}}>
                                                                                    <div>{_item.date_time}</div>
                                                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10}}>
                                                                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:5}}>
                                                                                            <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                                <div style={{fontWeight:"bold"}}>{_item.payout_method}</div>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                                <div>[{_item.user_id}]</div>
                                                                                                <div><span style={{fontWeight:"bold"}}>{_item.fullname}</span></div>
                                                                                            </div>
                                                                                            <div style={{ fontSize: 15 }}>Pickup Center: <span style={{textDecoration:"underline", fontWeight:600}}>UNKNOWN</span></div>
                                                                                            
                                                                                        </div>
                                                                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:10}}>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                                                                                                <div>Amount: <span style={{fontWeight:"bold"}}>${_item.amount}</span></div>
                                                                                                <div>Vat: <span style={{fontWeight:"bold"}}>${_item.vat}</span></div>
                                                                                                <div>Total: <span style={{fontWeight:"bold"}}>${Number(Number(_item.amount)+Number(_item.vat)).toFixed(2)}</span></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }                                            
                                                                </div>
                                                            </div>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                                <div></div>
                                                                <div style={{padding:5}}><span style={{fontWeight:"bold"}}>Overall Sales: ${item.overall}</span></div>
                                                            </div>
                                                        </div>
                                                    )

                                                    // return (
                                                    //     <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", backgroundColor: "#fff", borderRadius: 5, padding: 5, borderBottom:"1px solid #ddd" }}>
                                                    //         <div style={{ display: "flex", width: "50%", flexDirection: "column" }}>
                                                    //             <div style={{ fontSize: 15 }}>Name: <span style={{textDecoration:"underline", fontWeight:600}}>{item.fullname}</span></div>
                                                    //             <div style={{ fontSize: 15 }}>Pickup Center: <span style={{textDecoration:"underline", fontWeight:600}}>UNKNOWN</span></div>
                                                    //             <div style={{ fontSize: 15 }}>Amount: <span style={{textDecoration:"underline", fontWeight:600}}>${item.amount}</span></div>
                                                    //         </div>
                                                    //         <div style={{ width: "50%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-end" }}>
                                                    //             <div style={{ textAlign: "right", fontSize: 12 }}>Date: {item.date_time}</div>
                                                    //             <div style={{ textAlign: "right", fontSize: 15 }}>
                                                                    
                                                    //             </div>
                                                    //         </div>
                                                    //     </div>
                                                    // )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                </>
                                :null
                            }
                            {/* <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Weekly Entry (Summary)</div>
                                        <div></div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        <div style={{paddingLeft:1, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:95}}>Range Start</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:95}}>Range End</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:45, textAlign:"center"}}>Entry</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:90, textAlign:"right"}}>Amount</div>
                                            </div>
                                        </div>
                                        {
                                            weeklyentry?.map((item, key) => {
                                                return (<WeeklyEntrySummary item={item} key={key} usdRate={usdRate} num={num} />)
                                            })
                                        }
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Matrix Refill (Summary)</div>
                                        <div></div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        <div style={{paddingLeft:1, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:95}}>Range Start</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:95}}>Range End</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:45, textAlign:"center"}}>Entry</div>
                                                <div style={{paddingLeft:5, paddingRight:5, fontWeight:600, width:90, textAlign:"right"}}>Amount</div>
                                            </div>
                                        </div>
                                        {
                                            weeklyentry?.map((item, key) => {
                                                return (<WeeklyEntrySummary item={item} key={key} usdRate={usdRate} num={num} />)
                                            })
                                        }
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Payin</div>
                                        <div>
                                            <div>{userInfo?.month}, {userInfo?.year} </div>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        {
                                            performerdirectreferral.map((item, key) => {
                                                return (
                                                    <div style={{paddingLeft:10, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:30}}>{item.num}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:100}}>{item.user_id}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5}}>{item.fullname}</div>
                                                        </div>
                                                        <div style={{paddingLeft:5, paddingRight:5}}>{item.totaldirect}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Monthly Sales</div>
                                        <div>
                                            <div>{userInfo?.month}, {userInfo?.year} </div>
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        {
                                            performerdirectreferral.map((item, key) => {
                                                return (
                                                    <div style={{paddingLeft:10, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:30}}>{item.num}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:100}}>{item.user_id}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5}}>{item.fullname}</div>
                                                        </div>
                                                        <div style={{paddingLeft:5, paddingRight:5}}>{item.totaldirect}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <CanvasJSChart options={salesData} containerProps={{ width: '100%', height: '300px' }} />
                                </div>
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <CanvasJSChart options={optionss} containerProps={{ width: '100%', height: '300px' }} />
                                </div>
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <CanvasJSChart options={monthly_payout} containerProps={{ width: '100%', height: '300px' }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-12' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Last Payout</div>
                                        <div>
                                            {/* <div>{userInfo?.month}, {userInfo?.year} </div> */}
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        {
                                            lastpayoutlist.map((item, key) => {
                                                return (
                                                    <div style={{paddingLeft:10, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:30}}>{item.num}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:300, display:"flex", flexDirection:"row", justifyContent:"center", gap:20}}><span>(${Number(item.total).toLocaleString('en-US')})</span> <span>(Php{Number(item.total*55).toLocaleString('en-US')})</span></div>
                                                            <div style={{paddingLeft:5, paddingRight:5}}>{item.date_approved}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-6' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Top Performer Direct Referral</div>
                                        <div>
                                            {/* <div>{userInfo?.month}, {userInfo?.year} </div> */}
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        {
                                            performerdirectreferral.map((item, key) => {
                                                return (
                                                    <div style={{paddingLeft:10, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:30}}>{item.num}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:100}}>{item.user_id}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5}}>{item.fullname}</div>
                                                        </div>
                                                        <div style={{paddingLeft:5, paddingRight:5}}>{item.totaldirect}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='col-lg-6' style={{marginBottom:10}}>
                                    <div style={{
                                        backgroundColor:"#fff",
                                        width:"100%",
                                        height:null, 
                                        display:"flex",
                                        flexDirection:"column",
                                        padding:10,
                                    }}>
                                        <div style={{fontWeight:800}}>Top Performer Building Network</div>
                                        <div>
                                            {/* <div>{userInfo?.month}, {userInfo?.year} </div> */}
                                        </div>
                                    </div>
                                    <div style={{backgroundColor:"#fff"}}>
                                        {
                                            performerbuildingnetwork.map((item, key) => {
                                                return (
                                                    <div style={{paddingLeft:10, display:"flex", flexDirection:"row", borderTop:"1px solid #ddd", justifyContent:"space-between", alignItems:"center"}}>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:30}}>{item.num}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5, width:100}}>{item.user_id}</div>
                                                            <div style={{paddingLeft:5, paddingRight:5}}>{item.fullname}</div>
                                                        </div>
                                                        <div style={{paddingLeft:5, paddingRight:5}}>{item.totalnetwork}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* <Bar
                            data={{
                                labels: chart.map((item) => item.label),
                                datasets: [
                                    {
                                        label: "Revenue",
                                        data: chart.map((item) => item.value),
                                        backgroundColor: [
                                            "rgba(43,63,229,0.8)",
                                        ]
                                    }
                                ]
                            }} style={{width:window.innerWidth, height:200}} /> */}
                            </div>
                            {/* /.row (main row) */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>
        </>

    );
}

export default AdminDashboard;
