import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import { Sidebar } from 'primereact/sidebar';
import { he } from 'date-fns/locale';

const KYCStatus = (props) => {
    const [visible, setVisible] = useState(false);
    const [kyclist, setKYCList] = useState({})
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("kyc_check", 1);
        formData.append("user_id", props.userInfo?.user_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'kyc_form.php',
            // url: 'http://localhost/drinkgood/kyc_form.php',
            data: formData,
        }).then((res) => {
            setKYCList(res.data[0])
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV]);

    if (kyclist.status === 'pending') {
        return (
            <div style={{ width: "100%", height: 20, borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center" }}>
                <span><i className="pi pi-search" style={{ fontWeight: "bolder", color: "green" }}></i></span>
                <span>KYC Under Review</span>
            </div>
        )
    } else if (kyclist.status === 'approve') {
        if (props.children) {
            return (props.children)
        }
        return (
            <div style={{ width: "100%", height: 20, borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center" }}>
                <span><i className="pi pi-verified" style={{ fontWeight: "bolder", color: "green" }}></i></span>
                <span>Fully Verified</span>
            </div>
        )
    } else if (kyclist.status === 'decline') {
        return (
            <div onClick={() => window.location.assign('/kyc')} style={{ width: "100%", padding: 5, borderRadius: 5, backgroundColor: "rgb(237 207 207)", border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center"}}>
                <span><i className="pi pi-ban" style={{ fontWeight: "bolder", color: "red" }}></i></span>
                <span>KYC Application Declined</span>
            </div>
        )
    } else {
        return (
            <>
                <Sidebar visible={visible} blockScroll={true} showCloseIcon={true} position="bottom" style={{ height: window.innerHeight, borderRadius: 0, position: "relative", backgroundColor: "#00a8fd" }} onHide={() => setVisible(false)}>
                    
                    <div>
                        <img src={require('./marketing-data-marketers-information-verification.jpg')} style={{width: "100%", height: "100%"}} />
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "#fff"}}>
                        <span style={{fontWeight: "bolder", color: "#fff", fontSize: 25}}>Let's Verify KYC</span>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                            <span style={{color: "#fff"}}>Please submit the following documents</span>
                            <span style={{color: "#fff"}}>verify your account</span>
                        </div>
                        <br />
                        <br />
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 20}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: 10, width: "100%"}}>
                                <div style={{width: 30, display: "flex", justifyContent: "center"}}>
                                    <span className='pi pi-id-card' style={{fontSize: 30}}></span>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
                                    <span style={{fontSize: 18, fontWeight: "bold"}}>Take a picture of your ID</span>
                                    <span style={{fontSize: 12}}>To check your personal informations</span>
                                    <span style={{fontSize: 12}}>are correct</span>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start", gap: 10, width: "100%"}}>
                                <div style={{width: 30, display: "flex", justifyContent: "center"}}>
                                    <span className='pi pi-camera' style={{fontSize: 30}}></span>
                                </div>
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start"}}>
                                    <span style={{fontSize: 18, fontWeight: "bold"}}>Take a Selfie</span>
                                    <span style={{fontSize: 12}}>To match your face to your ID photo</span>
                                </div>
                            </div>
                            <div>
                                <button onClick={() => window.location.assign('/kyc')} style={{width: 200, height: 50, borderRadius: 10, border: "1px solid #00a8fd"}}>Let's Start</button>
                            </div>
                        </div>
                    </div>
                    
                </Sidebar>
                <div onClick={() => setVisible(true)} style={{ cursor: "pointer", width: "100%", padding: 5, borderRadius: 5, backgroundColor: "#fff", border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 5, alignItems: "center"}}>
                    <span>KYC Verification</span>
                    <span className='pi pi-chevron-right'></span>
                </div>
            </>
        )
    }
    // return (
    //     <div>
            
    //         {
    //             kyclist.status === 'pending' ?
    //             <div style={{ width: "100%", height: 20, borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center" }}>
    //                 <span><i className="pi pi-search" style={{ fontWeight: "bolder", color: "green" }}></i></span>
    //                 <span>KYC Under Review</span>
    //             </div>
    //                 : kyclist.status === 'approve' ?
    //                     <div style={{ width: "100%", height: 20, borderRadius: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center" }}>
    //                         <span><i className="pi pi-verified" style={{ fontWeight: "bolder", color: "green" }}></i></span>
    //                         <span>Fully Verified</span>
    //                     </div>
    //                 : kyclist.status === 'decline' ?
    //                     <div onClick={() => window.location.assign('/kyc')} style={{ width: "100%", padding: 5, borderRadius: 5, backgroundColor: "rgb(237 207 207)", border: "1px solid #ddd" , display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 5, alignItems: "center"}}>
    //                         <span><i className="pi pi-ban" style={{ fontWeight: "bolder", color: "red" }}></i></span>
    //                         <span>KYC Application Declined</span>
    //                     </div>
    //                     :
    //                     <div onClick={() => window.location.assign('/kyc')} style={{ width: "100%", padding: 5, borderRadius: 5, backgroundColor: "#fff", border: "1px solid #ddd" }}>
    //                         KYC Application
    //                     </div>
    //         }
    //     </div>
    // );
}

export default KYCStatus;
