import { Sidebar } from 'primereact/sidebar';
import { ProgressSpinner } from "primereact/progressspinner";
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const Accounts = ({ userInfo }) => {

    const [changepasswordform, setChangePasswordForm] = useState(false);


    const [address, setAddress] = useState(false);
    const [input, setInput] = useState({number: '', code: ''});

    const [accountslist, setAccountList] = useState([]);

    const saveAddress = async () => {
        var formData = new FormData();
        formData.append("set_address", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("address", address);
        if (address!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                if (res.data[0].status == 'success') {
                    swal({
                        title: "Done!",
                        text: "Successfull saved",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        // console.log(input)
                        setChangePasswordForm(false)
                        window.location.assign('/profile')
                    });
                } else {

                }
            });   
        }
    }

    // console.log(userInfo?.mobile)
    useMemo(async () => {
        var formData = new FormData();
        formData.append("myOtherAccounts", 1);
        formData.append("mobile", userInfo?.mobile);
        formData.append("email", userInfo?.email);
        if(userInfo?.email!='' || userInfo?.mobile!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                // console.log(res.data)
                setAccountList(res.data)
            })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [userInfo]);

    const [switchAccount, setSwitchAccount] = useState({})

    const SwitchAccount = (item) => {
        // console.log(item)
        setSwitchAccount(item)
    }

    
    const SwitchNow = (item) => {
        // console.log(item)
        localStorage.setItem('user_id', item.user_id)
        localStorage.setItem('username', item.username)
        // localStorage.removeItem('adminUser')
        if (item.account_status == 1) {
            window.location.assign('/dashboard')
        } else {
            window.location.assign('/profile')
        }
    }


    // var email = "joe_awesome@example.com";
    function formatEmail(emilString){
        var splitEmail = emilString.split("@")
        var domain = splitEmail[1];
        var name = splitEmail[0];
        return  name.substring(0,5).concat("****@").concat(domain)
    }
    // console.log(formatEmail(email));
    

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <Sidebar visible={changepasswordform} blockScroll={true} position="bottom" style={{ height:"auto", borderRadius: 0 }} onHide={() => setChangePasswordForm(false)}>
                <div style={{display:"flex", flexDirection:"column", gap:2}}>
                    {
                        accountslist.length>0 ?
                        <>
                            {
                                accountslist.map((item, key)=>{
                                    if (switchAccount.user_id == item.user_id) {
                                        return (
                                            <div onClick={()=>SwitchAccount({})} style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", height:"auto", borderRadius:5, border:"1px solid #ddd"}}>
                                                <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                    <div style={{padding:5}}>
                                                        <img className="img-circle elevation-2"  src={item.image ? ENV.DOMAIN+item.image : "../dist/img/user1-128x128.jpg"} style={{width: 40, height: 40}} />
                                                    </div>
                                                    <div style={{padding:5}}>
                                                        <div style={{fontSize:12}}>{item.fullname}</div>
                                                        <div style={{fontSize:9}}>{item.telephone}</div>
                                                        <div style={{fontSize:9}}>{item.user_id}</div>
                                                        {
                                                            item.email !='' ?
                                                            <div style={{fontSize:9}}>{formatEmail(item.email)}</div>
                                                            :null
                                                        }
                                                    <div style={{fontSize:9}}>{item.account_status == 1 ? <span style={{color:"green"}}>Active</span> : <span style={{color:"red"}}>Inactive</span> }</div>
                                                    </div>
                                                </div>
                                                <div style={{width:70, fontSize:12, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                    <button onClick={()=>SwitchNow(item)} style={{fontSize:12, borderRadius:5, border:"none", width:50, backgroundColor:"#17a2b8", color:"#fff"}}>Switch</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div onClick={()=>item.user_id == userInfo.user_id ? null : SwitchAccount(item)} style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", height:"auto", borderRadius:5, border:"1px solid #ddd"}}>
                                            <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                                                <div style={{padding:5}}>
                                                    <img className="img-circle elevation-2"  src={item.image ? ENV.DOMAIN+item.image : "../dist/img/user1-128x128.jpg"} style={{width: 40, height: 40}} />
                                                </div>
                                                <div style={{padding:5}}>
                                                    <div style={{fontSize:12}}>{item.fullname}</div>
                                                    <div style={{fontSize:9}}>{item.telephone}</div>
                                                    <div style={{fontSize:9}}>ID: {item.user_id}</div>
                                                    {
                                                        item.email !='' ?
                                                        <div style={{fontSize:9}}>{formatEmail(item.email)}</div>
                                                        :null
                                                    }
                                                    <div style={{fontSize:9}}>{item.account_status == 1 ? <span style={{color:"green"}}>Active</span> : <span style={{color:"red"}}>Inactive</span> }</div>
                                                </div>
                                            </div>
                                            {
                                                item.user_id == userInfo.user_id ?
                                                    <div style={{width:40, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                                        <span style={{color:"green"}} className='pi pi-check-circle'></span>
                                                    </div>
                                                :null
                                            }
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <>Loading... </>
                    }
                </div>
            </Sidebar>
            {
                // userInfo.address != '' ?
                // <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                //     Change Address <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span className='pi pi-chevron-right'></span></span>
                // </div>
                // :
                // accountslist.length>1 ?
                    <div onClick={() => accountslist.length>0 ? setChangePasswordForm(true) : null } style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:10}}>
                            Accounts 
                            {/* <div style={{fontSize:12, width:40, height:15, color:"#fff", borderRadius:"100", backgroundColor:"red", borderRadius:100, display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                NEW
                            </div> */}
                        </div> 
                        <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                        {
                            accountslist.length>0 ?
                            <span className='pi pi-chevron-right'></span>
                            :
                            <ProgressSpinner style={{width: '15px', height: '15px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                        </div>
                    </div>
                // :null
            }
        </>
    );
}

export default Accounts;
