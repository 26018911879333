import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import ENV from '../ENV';
import MonthlyMatrix from './MonthlyMatrix';

const RowMemberBtn = ({ item }) => {
    const [visible, setVisible] = useState(false);
    const [openpurchaseunilevel, setOpenPurchaseUnilevel] = useState(false);
    const [openpurchasematrix, setOpenPurchaseMatrix] = useState(false);
    const [price, setPrice] = useState(0);
    const [vat, setVat] = useState(0.12);

    

    const [unilevelproduct, setUnilevelProduct] = useState([]);
    const [matrixproduct, setMatrixProduct] = useState([]);
    const [transactionlist, setTransactionList] = useState([]);
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("unilevel_product_list", 1);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setUnilevelProduct(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);
    
    useMemo(async () => {
        var formData = new FormData();
        formData.append("matrix_product_list", 1);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setMatrixProduct(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [ENV]);

    
    const [selectitem, setSelectedItem] = useState(0);

    const SelectItem = (item) => {
        setSelectedItem(JSON.parse(item))
    }


    // console.log(item.user_id, selectitem)
    const proceedPurchaseUnilevel = async () => {
        // console.log(item.user_id, selectitem)
        var formData = new FormData();
        formData.append("enter_unilevel", 1);
        formData.append("user_id", item.user_id);
        formData.append("description", selectitem.name);
        formData.append("price", selectitem.price);
        formData.append("vat", selectitem.vat);
        formData.append("bonus", selectitem.bonus);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            // setMatrixProduct(res.data)
            setVisible(false)
        })
            .catch((err) => {
                console.log(err);
            });
    }
    
    const proceedMaintenanceMatrix = async () => {
        var formData = new FormData();
        formData.append("enter_matrix", 1);
        formData.append("user_id", item.user_id);
        formData.append("description", matrixproduct[0].name);
        formData.append("price", matrixproduct[0].price);
        formData.append("vat", matrixproduct[0].vat);
        formData.append("encoder", localStorage.getItem('adminusername'));
        // localStorage.setItem('adminusername'
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            // setMatrixProduct(res.data)
            setVisible(false)
        })
            .catch((err) => {
                console.log(err);
            });
    }
    
    const showTransaction = async () => {
        var formData = new FormData();
        formData.append("showTransaction", 1);
        formData.append("user_id", item.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setTransactionList(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleClose = () => {
        setVisible(false)
        setTimeout(()=>setOpenPurchaseMatrix(false), 1000)        
    }

    // console.log(item)
    return (
        <div>
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
            <Dialog draggable={false} blockScroll={true} header={item.fullname + " (" + item.rank_status + ")"} visible={visible} style={{ width: openpurchasematrix ? '30vw' : '95vw' }} onHide={handleClose}>
                {
                    openpurchaseunilevel == false && openpurchasematrix == false && transactionlist.length==0 ?
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' style={{ width: 200 }} onClick={() => window.location.assign('/administrator/members/genealogy/personal/' + item.user_id)}>Personal Genealogy</button>
                        </div>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' style={{ width: 200 }} onClick={() => window.location.assign('/administrator/members/genealogy/matrix/' + item.user_id)}>Matrix Genealogy</button>
                        </div>
                        {/* <div style={{padding:5}}>
                        <button className='btn btn-info btn-xs' style={{width:200}}>Unilevel Activity</button>
                    </div>
                    <div style={{padding:5}}>
                        <button className='btn btn-info btn-xs' style={{width:200}}>Matrix Activity</button>
                    </div> */}
                        <div style={{ border: "1px solid #ddd", width: "100%" }}></div>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "self-start" }}>
                            <div style={{ fontSize: 13 }}>Total Earning
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                                {item.total_earning}</div>
                            <div style={{ fontSize: 13 }}>Total Payout
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                                {item.total_payout}</div>
                            <div style={{ fontSize: 13 }}>Balance
                                <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 17 17">
                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                </svg>
                                {item.balance}</div>
                        </div>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' style={{ width: 200 }}>Payout History</button>
                        </div>
                        <div style={{ border: "1px solid #ddd", width: "100%" }}></div>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' onClick={() => setOpenPurchaseUnilevel(true)} style={{ width: 200 }}>Encode for Purchase (Unilevel)</button>
                        </div>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' onClick={() => setOpenPurchaseMatrix(true)} style={{ width: 200 }}>Encode for Maintenance (Matrix)</button>
                        </div>
                        <div style={{ border: "1px solid #ddd", width: "100%" }}></div>
                        <div style={{ padding: 5 }}>
                            <button className='btn btn-info btn-xs' onClick={()=>showTransaction()} style={{ width: 200 }}>Transactions</button>
                        </div>
                    </div>
                    :
                    null
                }                
                {
                    openpurchaseunilevel ?
                        <>
                            <div>
                                <label style={{fontSize:20}}>Unilevel</label>
                            </div>
                            <div>
                                <label>Description</label>
                                <div>
                                    <select style={{width:"100%"}} onChange={(e)=>SelectItem(e.target.value)}>
                                        <option value={""}>Select...</option>
                                        {
                                            unilevelproduct.map((item, key)=>{
                                                return (<option value={JSON.stringify(item)}>{item.name} (Price: ${item.price}) (VAT: ${item.vat})</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div>
                                <label>Total Due:</label> ${selectitem != 0 ? Number(selectitem.price+Number(selectitem.price*selectitem.vat)) : 0}
                            </div>
                            <div style={{paddingTop:10, display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <button className='btn btn-danger' onClick={()=>{
                                    setOpenPurchaseUnilevel(false)
                                    setSelectedItem(0)
                                }}>Cancel</button>
                                <button className='btn btn-info' onClick={()=>proceedPurchaseUnilevel()}>Proceed</button>
                            </div>
                        </>
                        :
                        null
                }
                {
                    openpurchasematrix ?
                    <>
                        <MonthlyMatrix item={item} setOpenPurchaseMatrix={setOpenPurchaseMatrix} setVisible={setVisible} />
                    </>
                    :
                    null
                }
                {
                    transactionlist.length>0?
                        <>
                            <div>
                                <label style={{fontSize:20}}>Transactions</label>
                            </div>
                            <div>
                                <div>
                                    {
                                        transactionlist.map((item, key)=>{
                                            return (
                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                    <div>{item.date_time}</div>
                                                    <div>{item.description}</div>
                                                    <div>{Number(item.amount+Number(item.amount*item.vat))}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{paddingTop:10, display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <button className='btn btn-danger' onClick={()=>{
                                    setOpenPurchaseUnilevel(false)
                                    setSelectedItem(0)
                                    setTransactionList([])
                                }}>Back</button>
                            </div>
                        </>
                    :
                    null
                }
            </Dialog>



            <a label="Show" onClick={() => setVisible(true)} className='btn btn-info btn-xs'>Details</a>
        </div>
    );
}

export default RowMemberBtn;
