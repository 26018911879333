import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';

const AdminCodeGenerator = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [memberlist, setMemberlist] = useState([])


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("code_list", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            setMemberlist(res.data)
            console.log(res.data)
        }).catch((err)=>{

        });
    }, [ENV])








    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar  userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Create New Code</h1>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10, padding:10, backgroundColor:"#ddd", borderRadius:10}}>
                                <div style={{width:100,display:"flex", flexDirection:"column"}}>
                                    <label>Quantity</label>
                                    <input type='number' style={{width:100, height:30}} />
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <label>validate</label>
                                    <input type='text' style={{height:30}} />
                                </div>
                            </div>
                            <div>
                                {
                                    memberlist.map((item) => {
                                        return (
                                            <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start" }}>
                                                    <div style={{ width: 150 }}>{item.code}</div>
                                                    <div style={{ width: 300, textAlign: "left" }}>{item.generated}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* /.row */}
                        {/* Main row */}
                        <div className="row">
                            {/* Left col */}
                            {/* /.Left col */}
                            {/* right col (We are only adding the ID to make the widgets sortable)*/}
                            
                            {/* right col */}
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminCodeGenerator;
