// CardCaptureView.js
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';


const AutoCapture = () => {

  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";

  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  const [image, setImage] = useState(null);
  const [scannedData, setScannedData] = useState('');
  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: FACING_MODE_USER
  };


  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      setImage(imageSrc);
      processImage(imageSrc);
    }
  };

  const processImage = (imageSrc) => {
    Tesseract.recognize(
      imageSrc,
      'eng',
      { logger: info => console.log(info) } // Optional logging
    ).then((data) => {
        console.log(data)
      setScannedData(data.text);
    }).catch(err => {
      console.error('OCR error:', err);
    });
  };


  const handleClick = React.useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    );
  }, []);

  return (
    <div>
      <h1>Card Capture</h1>
      <button onClick={handleClick}>Switch camera</button>
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            ...videoConstraints,
            facingMode
          }}
          width="50%"
          height="auto"
        />
      </div>
      <button onClick={captureImage}>Capture Image</button>
      {image && (
        <div>
          <h2>Captured Image:</h2>
          <img src={image} alt="Captured" style={{ width: '50%', maxHeight: '50%' }} />
        </div>
      )}
      {scannedData && (
        <div>
          <h3>Scanned Data:</h3>
          <p>{scannedData}</p>
        </div>
      )}
    </div>
  );
};

export default AutoCapture;
