import React, { useEffect, useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import ENV from './ENV';
import axios from 'axios';

const AdminReportPayout = ({ userInfo }) => {
    // console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';

    const [payoutlist, setPayoutList] = useState([])


    const payout_request_list = async () => {

        var formData = new FormData();
        formData.append("payout_request_history", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }

    useMemo(async () => {

        var formData = new FormData();
        formData.append("payout_request_history", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setPayoutList(res.data)
            console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])





    const CancelRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("cancel_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("amount", item.amount);
        formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });

    }

    const ApprovedRequest = async (item, status) => {
        var formData = new FormData();
        formData.append("approve_payout_request", 1);
        formData.append("user_id", item.user_id);
        formData.append("id", item.id);
        formData.append("ref_number", null);
        // formData.append("fee", item.fee);
        formData.append("status", status);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            payout_request_list()
            // setBalance(res.data[0].balance)
            // payout_list()
        }).catch((err) => {

        });
    }

    const calculate = (num, _num) => {
        return (num + _num)
    }


    useEffect(() => {

    }, [])

    return (
        <div className="wrapper">
            {/* Preloader */}
            {/* <div className="preloader flex-column justify-content-center align-items-center">
                <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
            </div> */}
            {/* Navbar */}
            <AdminHeader />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <AdminNavbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Report <span className='pi pi-chevron-right'></span> Payout</h1>
                            </div>
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Main row */}
                        <div className="row">
                            <div className='col-sm-12'>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                                    {
                                        payoutlist.map((item) => {
                                            // if (item.status == 'approved') {
                                                return (
                                                    <div style={{ padding: 0, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "100%", border:"1px solid #ddd" }}>
                                                        <div style={{width:"100%", display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start", alignItems: "center", border:"1px solid #ddd" }}>
                                                            <div style={{width:"100%", display:"flex", flexDirection:"column" }}>
                                                                <div style={{ width: null, padding:5, fontWeight:600, backgroundColor:"#0092b9", color:"#fff" }}>Date Of {item.date_approved}</div>
                                                                {/* {
                                                                    item?.list?.map((_item)=>{
                                                                        return (
                                                                            <div style={{padding:5, width:"100%", display:"flex", flexDirection:"column", fontSize:12, justifyContent:"flex-start", border:"1px solid #ddd"}}>
                                                                                <div>{_item.date_payout} - {_item.date_approveds}</div>
                                                                                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:10}}>
                                                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0}}>
                                                                                        <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                            <div style={{fontWeight:"bold", fontSize:18}}>{_item.payout_method}</div>
                                                                                        </div>
                                                                                        <div style={{display:"flex", flexDirection:"row", gap:5}}>
                                                                                            <div>[{_item.user_id}]</div>
                                                                                            <div><span style={{fontWeight:"bold", textDecoration:"underline"}}>{_item.fullname}</span></div>
                                                                                        </div>
                                                                                        <div style={{display:"flex", flexDirection:"column"}}>
                                                                                            <div>Account Name: <span style={{fontWeight:"bold"}}>{_item.account_name}</span></div>
                                                                                            <div>Account Number: <span style={{fontWeight:"bold"}}>{_item.account_number}</span></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:10}}>
                                                                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-end", alignItems:"flex-start"}}>
                                                                                            <div>Amount: <span style={{fontWeight:"bold"}}>${_item.amount}</span></div>
                                                                                            <div>tax: <span style={{fontWeight:"bold"}}>${_item.fee}</span></div>
                                                                                            <div style={{textDecoration:"underline"}}>Sub-Total: <span style={{fontWeight:"bold"}}>${Number(Number(_item.amount)+Number(_item.fee)).toFixed(2)}</span></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }                                             */}
                                                            </div>
                                                        </div>
                                                        <div style={{padding:5, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:10}}>
                                                            <span style={{fontWeight:"bold", fontSize:12}}>Total Payout: ${item.totalAmount}</span> |
                                                            <span style={{fontWeight:"bold", fontSize:12}}>Total Tax: ${item.totalFee}</span> |
                                                            <span style={{fontWeight:"bold", fontSize:12}}>Overall Payout: ${item.overall}</span>
                                                        </div>
                                                    </div>
                                                )
                                            // }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* /.row (main row) */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                All rights reserved.
                {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </div>

    );
}

export default AdminReportPayout;
