import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const AllMembersAddress = ({ userInfo }) => {

    const [openaddress, setOpenAddress] = useState(false);
    const [memberlist, setMemberlist] = useState([])


    useMemo(async () => {
        var formData = new FormData();
        formData.append("member_list", 1);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setMemberlist(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }, [ENV])

    const [search, setSearch] = useState('')
    const SearchData = (data, value)  => {
        if (value!='') {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase())) || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase())));
        }
        return data;
    }

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <Sidebar visible={openaddress} showCloseIcon={true} position="bottom" style={{ minHeight: window.innerHeight, borderRadius: 0 }} onHide={() => setOpenAddress(false)}>
                <div>
                    <div style={{display:"flex", flexDirection:"row", gap:10, alignItems:"center"}}>
                        <span>Search: </span>
                        <input style={{borderRadius:5, border:"1px solid #303030", height:35, padding:5}} onChange={(e)=>setSearch(e.target.value)} />
                        {/* <span>Total Member/s: {memberlist.length}</span> */}
                    </div>
                    {
                        memberlist.length > 0 ?
                            <>
                                {
                                    SearchData(memberlist, search).map((item, key) => {
                                        if (item.account_status == 1) {
                                            if(item.user_id!='000000000') {
                                                return (
                                                    <div style={{ width: "100%", minHeight: 100, borderRadius: 5, backgroundColor: "#fff", borderBottom:"1px solid #ddd" }}>
                                                        <div style={{ padding: 5, display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: 10 }}>
                                                            <div><img src={ENV.DOMAIN + item.image} style={{ width: 70, height: 70, borderRadius: 10, border: "2px solid #ddd" }} /></div>
                                                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 0 }}>
                                                                <div style={{fontWeight:700}}>{item.fullname}</div>
                                                                <div><span style={{ fontSize: 10 }}>{item.date_registered}</span></div>
                                                                {/* <RowMemberBtn item={item} /> */}
                                                            
                                                                <div>
                                                                    {/* <div style={{ fontSize: 12 }}>User Id: {item.user_id}</div> */}
                                                                    {
                                                                        item.direct_sponsor_name != '' ?
                                                                        <div>
                                                                        <div style={{ fontSize: 12 }}>Direct Upline: <span style={{ textDecoration: "underline" }}>{item.direct_sponsor_name}</span></div>
                                                                        <div style={{ fontSize: 12 }}>Mobile Number: <span style={{ textDecoration: "underline" }}>{item.telephone}</span></div>
                                                                        </div>
                                                                            : null
                                                                    }
                                                                    {
                                                                        item.address != '' ?
                                                                        <>
                                                                            <br />
                                                                            <div style={{ fontSize: 12 }}>Address: </div>
                                                                            <div style={{ fontSize: 12 }}>{item.address}</div>
                                                                        </>
                                                                            : null
                                                                    }
            
                                                                </div>                                                         
                                                            
                                                            </div>
                                                        </div>
                                                        {/* <div>
                                                            <RowMemberBtn item={item} />
                                                        </div> */}
                                                    </div>
                                                )
                                            }
                                        }
                                    })
                                }
                            </>
                            :
                            <>Loading...</>
                    }
                </div>
            </Sidebar>
            {
                userInfo.user_id == '000000000' || userInfo.user_id == '689730542' || userInfo.user_id == '845736201' ?
                <div onClick={() => setOpenAddress(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                    All Members Addresses <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span style={{fontSize:12, color:"#17a2b8"}}>allowed</span><span className='pi pi-chevron-right'></span></span>
                </div>
                :null
            }
        </>
    );
}

export default AllMembersAddress;
