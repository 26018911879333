import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';

const PhoneNumberSetup = ({userInfo}) => {

    const [aftersendotp, setAfterSendOTP] = useState(false);
    const [input, setInput] = useState({number: '', code: ''});


    const sendOTP = async () => {


        var formData = new FormData();
        formData.append("send_otp", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("number", input.number);

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            swal({
                title: "Done!",
                text: "Successfull added contact number",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                setAfterSendOTP(true)
                console.log(input)
            });
        });
    }

    const submitOTP = async () => {
        var formData = new FormData();
        formData.append("verify_otp", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("number", input.number);
        formData.append("code", input.code);

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            swal({
                title: "Done!",
                text: "Successfull added contact number",
                icon: "success",
                timer: 1000,
                button: false,
            }).then(() => {
                setAfterSendOTP(true)
                console.log(input)
            });
        });
    }

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <div style={{width:"100%", height:"100%", backgroundColor:"#000", position:"absolute", zIndex:9999, opacity:0.8, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                
            </div>
            <div style={{width:"100%", height:window.innerHeight, backgroundColor:"transparent", position:"fixed", zIndex:9999, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <>
                    {
                        aftersendotp ?
                        <div style={{width:"80%", minHeight:100, padding:10, backgroundColor:"#fff", opacity:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"row"}}>
                                <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"flex-start", lineHeight:1}}>
                                    <div><label>Update Account</label></div>
                                    <div><label>Add Contact Number</label></div>
                                </div>
                                <div>
                                    <span style={{fontSize:14}}>You will receive a One-Time Password (OTP)</span>
                                </div>
                            </div>
                            <div style={{padding:10}}>
                                <label>Enter OTP</label>
                                <input type={"number"} name='code' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.code} placeholder='Enter 6 digit code' style={{padding:5, borderRadius:5, textAlign:"center", width:"100%", border:"2px solid #ddd"}} /> 
                            </div>
                            <div>
                                <><span style={{fontSize:14}}>Did not receive code?</span> <a style={{textDecoration:"underline"}} onClick={()=>sendOTP()}>resend</a></>
                            </div>
                            <div style={{padding:5}}>
                                <button onClick={(e)=> submitOTP()} style={{width:200, height:40, borderRadius:5, border:"1px solid #ddd", backgroundColor:"#326fd1", color:"#fff"}}>Submit</button>  
                            </div>
                        </div>
                        :
                        <div style={{width:"80%", minHeight:100, padding:10, backgroundColor:"#fff", opacity:1, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                                <div><label>Update Account</label></div>
                                {/* <div><label>Add Contact Number</label></div> */}
                            </div>
                            <div style={{padding:10}}>
                                <label>Phone Number</label>
                                <input type={"number"} name='number' onChange={(e) => setInput({ ...input, [e.target.name]: e.target.value })} value={input.number} placeholder='Enter Number' style={{padding:5, borderRadius:5, textAlign:"center", width:"100%", border:"2px solid #ddd"}} /> 
                            </div>
                            <div style={{padding:5}}>
                                <button onClick={(e)=> sendOTP()} style={{width:200, height:40, borderRadius:5, border:"1px solid #ddd", backgroundColor:"#326fd1", color:"#fff"}}>Send OTP</button>  
                            </div>
                        </div>
                    }
                </>                
            </div>
        </>
    );
}

export default PhoneNumberSetup;
