import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';
import CopyToClipboard from 'react-copy-to-clipboard';

const Pos = ({ userInfo }) => {
    // if (userInfo?.maintenance == true) {
    //     window.location.assign('/')
    // }
    document.body.className = 'sidebar-mini';

    
    const [Copied, setCopied] = useState({id:'', status:false});

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [directInfo, setDirectInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("direct", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    

    // const ClickCopied = async (user_id) => {
    //     await navigator.clipboard.writeText(ENV.DOMAIN+'register/'+user_id)
    //     alert(ENV.DOMAIN+'register/'+user_id)
    //     setCopied(true)
    //     setTimeout(()=>{
    //         setCopied(false)
    //     }, 1000)
    // }


    return (
        <div className="wrapper">
            {/* Navbar */}
            {/* <Header /> */}
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            {/* <Navbar userInfo={userInfo} /> */}
            {/* Content Wrapper. Contains page content */}
            <div className="content" style={{ minHeight: '1000px' }}>
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="">
                    <div className="" style={{marginBottom:50}}>
                        <div className="row">
                            {/* /.col */}
                            <div className="col-sm-4">
                                <div style={{width: "100%", borderRadius: 5, border: "1px solid #ddd"}}>
                                    <div>asdas</div>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div style={{width: "100%", borderRadius: 5, border: "1px solid #ddd"}}>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>Price</th>
                                                <th>Qty</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
            </div>
            {/* /.content-wrapper */}
            {/* <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer> */}
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Pos;
