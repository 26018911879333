import { Sidebar } from 'primereact/sidebar';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';

const MatrixCodeDorm = (props) => {
    const [visible, setVisible] = useState(false);
    const [application, setApplication] = useState([]);
    const [inputvalue, setInputValue] = useState({ "code": "", "user_id": props.userInfo.user_id });

    useMemo(async () => {
        var formData = new FormData();
        formData.append("show_application", 1);
        // formData.append("inputvalue", JSON.stringify(inputvalue));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            setApplication(res.data)
            // console.log(res.data)
        });
    }, [ENV]);


    const SubmitMatrixCode = async () => {
        var formData = new FormData();
        formData.append("send_matrix_code", 1);
        formData.append("inputvalue", JSON.stringify(inputvalue));
        if (inputvalue.code != '') {
            await axios({
                method: "post",
                url: ENV.DOMAIN + 'process.php',
                data: formData,
            }).then((res) => {
                console.log(res.data)
            });
        }
    }

    return (
        <>
            <Sidebar visible={visible} icons={
                <div style={{width: "100%", fontWeight: "bolder"}}>Enter Matrix Code</div>
            } blockScroll={true} showCloseIcon={true} position="bottom" style={{ minHeight: 200, borderRadius: 0 }} onHide={() => setVisible(false)}>
                <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
                    <div>
                        <span>Matrix Code <span style={{ color: "red" }}>*</span></span>
                        <input name='code' onChange={(e) => setInputValue(prev => ({ ...prev, [e.target.name]: e.target.value }))} className='form-control' />
                    </div>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                        <button className='btn btn-info btn-sm' onClick={() => SubmitMatrixCode()}>Submit Code</button>
                    </div>
                </div>
            </Sidebar>
            <div onClick={() => setVisible(true)} style={{ padding: 5, border: "1px solid #ddd", width: "100%", borderRadius: 5, textAlign: "center", backgroundColor: "#fff", color: "#000" }}>Enter Matrix Code</div>
        </>
    );
}

export default MatrixCodeDorm;
