import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const ConfirmPaymentEntry = ({refreshCodeList, item}) => {
    const [visible, setVisible] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    const confirm = async () => {
        setIsLoading(true)
        // console.log(item.user_id, selectitem)
        var formData = new FormData();
        formData.append("confirm_payment", 1);
        formData.append("code", item.code);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            if (res.data[0].status == 'success') {
                swal({
                    // title: "Done!",
                    text: "Done!",
                    icon: "success",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    refreshCodeList()
                    setVisible(false)
                    setIsLoading(false)
                });
            } else if (res.data[0].status == 'invalid') {
                swal({
                    // title: "Done!",
                    text: "Invalid!",
                    icon: "error",
                    timer: 1000,
                    button: false,
                }).then(() => {
                    setIsLoading(false)
                });
            }
        })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <Dialog header={<>
                <div>{item.owner_name}</div>
                <div>Code: {item.code}</div>
                </>} visible={visible} style={{ width: 400 }} onHide={() => setVisible(false)}>
                {
                    item.fullname != '' ?
                    <div><b>Used By</b>: {item.fullname}</div>
                    :null
                }
                {
                    item.date_used != '' ?
                    <div><b>Date</b>: {item.date_used}</div>
                    :null
                }
                <div><b>Rate</b>: Php 55</div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", gap:5}}>
                    <div><b>Amount</b>:</div>
                    <div> 
                        <div><b>USD</b>: {item.amount}</div>
                        <div><b>Php</b>: {Number(Number(item.amount)*55).toFixed(2)}</div>
                    </div>
                </div>
                <div><b>Comment</b>: {item.comment}</div>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                    {
                        isloading ? 
                        <button style={{border:"1px solid rgb(179 179 179)", borderRadius:5, backgroundColor:"#ddd", color:"#fff", height:40}}>Confirm</button>
                        :
                        <button onClick={()=>confirm()} style={{border:"1px solid rgb(179 179 179)", borderRadius:5, backgroundColor:"rgb(35 126 223)", color:"#fff", height:40}}>Confirm</button>
                    }
                    <button onClick={()=>setVisible(false)} style={{border:"1px solid rgb(179 179 179)", borderRadius:5, backgroundColor:"red", color:"#fff", height:40}}>Cancel</button>
                </div>
            </Dialog>
           {
            localStorage.getItem('adminusername') == 'account' || localStorage.getItem('adminusername') == 'master_admin' ?
            <button onClick={()=>setVisible(true)} style={{borderRadius:5, border:"1px solid rgb(179 179 179)"}}>Confirm Payment</button> 
            :null
           }
        </>
    );
}

export default ConfirmPaymentEntry;
