import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../ENV';

const MonthlyMatrix = (props) => {

    const [inputvalue, setInputValue] = useState({qty: 0, totalamount: 0});

    console.log(props.item.user_id, inputvalue)

    const proceedMaintenanceMatrix = async () => {

        var formData = new FormData();
        formData.append("enter_matrixx", 1);
        formData.append("user_id", props.item.user_id);
        formData.append("description", 'Matrix Refill');
        formData.append("qty", inputvalue.qty);
        formData.append("totalamount", inputvalue.totalamount);

        formData.append("encoder", localStorage.getItem('adminusername'));


        // formData.append("description", inputvalue[0].name);
        // formData.append("price", inputvalue[0].price);
        // formData.append("vat", inputvalue[0].vat);
        // formData.append("encoder", localStorage.getItem('adminusername'));
        // localStorage.setItem('adminusername'
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            props.setOpenPurchaseMatrix(false)
            // setMatrixProduct(res.data)
            props.setVisible(false)
        })
            .catch((err) => {
                console.log(err);
            });
    }



    return (
        <div>
            <div>
                <label style={{ fontSize: 20 }}>Monthly Matrix</label>
            </div>

            <div>
                <div>
                    <label>Qty</label>
                    <input type='number' className='form-control' name='qty' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} />
                </div>
                <div>
                    <label>Total Amount</label>
                    <input type='number' className='form-control' name='totalamount' onChange={(e)=>setInputValue({...inputvalue, [e.target.name]: e.target.value})} />
                </div>
            </div>
            {/* <div>
                <label>Description</label>
                <div>
                    <select style={{ width: "100%" }}>
                        {
                            matrixproduct.map((item, key) => {
                                return (<option value={item}>{item.name} (Price: ${item.price}) + (VAT: {Math.round(item.vat * 100)}%)</option>)
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div>

                    <div>
                        <label>Total Due: </label> ${Number(matrixproduct[0].price + Number(matrixproduct[0].price * matrixproduct[0].vat))}
                    </div>
                </div>
            </div> */}
            <div style={{ paddingTop: 10, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <button className='btn btn-danger' onClick={() => props.setOpenPurchaseMatrix(false)}>Cancel</button>
                <button className='btn btn-info' onClick={() => proceedMaintenanceMatrix()}>Proceed</button>
            </div>
        </div>
    );
}

export default MonthlyMatrix;
