import React, { useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';

const Unpaid = ({userInfo}) => {
    document.body.className = 'sidebar-mini';

    const [directInfo, setDirectInfo] = useState([]);
    useMemo(async()=>{
        var formData = new FormData();
        formData.append("unpaid", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            // console.log(res.data)
            setDirectInfo(res.data)
        })
        .catch((err) => {
            console.log(err);
        });
    },[userInfo]);


    return (
        <div className="wrapper">
            {/* Navbar */}
            <Header />
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Unpaid Account</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header p-2">
                                        {/* <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul> */}
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>Fullname</th>
                                                    {/* <th>Placement</th> */}
                                                    <th>Rank</th>
                                                    <th>Date Registered</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    directInfo.map((item, key)=>{
                                                        return (
                                                            <tr>
                                                                <td>{item.fullname}</td>
                                                                {/* <td>{item.placement_fullname}</td> */}
                                                                <td>{item.rank_status}</td>
                                                                <td>{item.date_registered}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer>
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Unpaid;
