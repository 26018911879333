import { Sidebar } from 'primereact/sidebar';
import React, { useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import swal from 'sweetalert';

const ChangeAddress = ({ userInfo }) => {

    const [changepasswordform, setChangePasswordForm] = useState(false);


    const [address, setAddress] = useState(false);
    const [input, setInput] = useState({number: '', code: ''});


    const saveAddress = async () => {
        var formData = new FormData();
        formData.append("set_address", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("address", address);
        if (address!='') {
            await axios({
                method: "post",
                url: ENV.DOMAIN+'process.php',
                data: formData,
            }).then((res)=>{
                if (res.data[0].status == 'success') {
                    swal({
                        title: "Done!",
                        text: "Successfull saved",
                        icon: "success",
                        timer: 1000,
                        button: false,
                    }).then(() => {
                        // console.log(input)
                        setChangePasswordForm(false)
                        window.location.assign('/profile')
                    });
                } else {

                }
            });   
        }
    }

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')
    return (
        <>
            <Sidebar visible={changepasswordform} header={<>Address</>} position="bottom" style={{ minHeight: 350, borderRadius: 0 }} onHide={() => setChangePasswordForm(false)}>
                <div>
                    <>
                        <textarea onChange={(e)=>setAddress(e.target.value)} style={{width:"100%", height:200, padding:5, outline:"none"}}>{userInfo.address}</textarea>
                    </>
                    {
                        userInfo.address != '' ?
                        <button style={{width:"100%", height:40, borderRadius:10, border:"1px solid #ddd", backgroundColor:"#17a2b8", color:"#fff"}} onClick={()=>saveAddress()}>Change Address</button>
                        :
                        <button style={{width:"100%", height:40, borderRadius:10, border:"1px solid #ddd", backgroundColor:"#17a2b8", color:"#fff"}} onClick={()=>saveAddress()}>Add Address</button>
                    }
                </div>
            </Sidebar>
            {
                userInfo.address != '' ?
                <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                    Change Address <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span className='pi pi-chevron-right'></span></span>
                </div>
                :
                <div onClick={() => setChangePasswordForm(true)} style={{ width: "100%", height: 35, border: "1px solid #ddd", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: 5, borderRadius: 0, backgroundColor: "#fff" }}>
                    Add Address <span className='pi pi-chevron-right'></span>
                </div>
            }
        </>
    );
}

export default ChangeAddress;
