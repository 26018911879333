import React, { useMemo, useRef, useState } from 'react';
import ENV from '../../ENV';
import axios from 'axios';
import { SpeedDial } from 'primereact/speeddial';
import { Toast } from 'primereact/toast';

const Pending = ({userInfo, cartlist, setCartList, cartLoad}) => {

    const toast = useRef(null);

    // { cartlist, setCartList, cartLoad, item, userInfo, checked, setChecked }


    const [subtotal, setSubTotal] = useState(0);
    const [modepayment, setModePayment] = useState('COD');
    const [discounted, setDiscount] = useState(0);
    const [shippingfee, setShippingfee] = useState(0.25);


    const [checked, setChecked] = useState([]);
    const handleCheck = (event, item) => {
        const intex = cartlist.findIndex(x => x.cart_id == item?.cart_id);

        var updatedList = [...checked];
        if (event == 'checked') {
            updatedList = [...checked, { ...item }];
            cartlist[intex].checked = true;
            console.log(cartlist)
        } else {
            cartlist[intex].checked = false;
            updatedList = updatedList.filter((i) => {
                return i.cart_id !== item.cart_id;
            });
        }



        // console.log(updatedList)
        setCartList(cartlist)
        setChecked(updatedList);
    };

    const handleRemove = async (e, item) => {
        var formData = new FormData();
        formData.append("remove_cart", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("cart_id", item.cart_id);
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;

            cartLoad();

            let updatedList = cartlist.filter((i) => {
                return i.cart_id !== item.cart_id;
            });
            setChecked(updatedList);
            // setCartList(res.data)
            // console.log(res.data)
        }).catch((err) => {

        });
    }


    
    const SubTotal = checked?.reduce((amount, item) => amount = (item.price.split('$')[1] * item.qty) + amount, 0);

    const placeOrder = async () => {
        alert(JSON.stringify(checked)) //(SubTotal + shippingfee)
        var formData = new FormData();
        formData.append("placeorder", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("items", JSON.stringify(checked));
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            cartLoad();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            // setChecked(updatedList);
        }).catch((err) => {

        });
    }

    
    const [orderlist, setOrderList] = useState([]);

    useMemo(async()=>{
        var formData = new FormData();
        formData.append("my_order", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("status", 'pending');
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            // cartLoad();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            setOrderList(data);
        }).catch((err) => {

        });
    }, [ENV]);

    const loadOrder = async () => {
        var formData = new FormData();
        formData.append("my_order", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("status", 'pending');
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            // cartLoad();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            setOrderList(data);
        }).catch((err) => {

        });
    };

    const CancelOrder = async (item) => {
        // alert(JSON.stringify(checked)) //(SubTotal + shippingfee)
        var formData = new FormData();
        formData.append("cancel_order", 1);
        formData.append("user_id", userInfo.user_id);
        formData.append("order_id", item.order_id);
        // (SubTotal + shippingfee)
        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            const data = res.data;
            console.log(data)
            loadOrder();

            // let updatedList = cartlist.filter((i) => {
            //     return i.cart_id !== item.cart_id;
            // });
            // setChecked(updatedList);
        }).catch((err) => {

        });
    }

    
    const items = [
        {
            label: 'Shop',
            icon: 'pi pi-server',
            command: () => {
                toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
            }
        },
        // {
        //     label: 'Update',
        //     icon: 'pi pi-refresh',
        //     command: () => {
        //         toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
        //     }
        // },
        // {
        //     label: 'Delete',
        //     icon: 'pi pi-trash',
        //     command: () => {
        //         toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
        //     }
        // },
        // {
        //     label: 'React Website',
        //     icon: 'pi pi-external-link',
        //     command: () => {
        //         window.location.href = 'https://facebook.github.io/react/';
        //     }
        // }
    ];


    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
                {
                    orderlist.map((item, key) => {
                        return (
                            <div style={{ width: "100%", backgroundColor: "#fff", borderRadius: 5, padding: 5, border: "1px solid #ddd", boxShadow: "2px 3px 2px 0px rgb(0,0,0,0.4)" }}>
                                <div style={{ fontSize: 12 }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                        <div>Status: Pending</div>
                                        {/* <div> */}
                                        <button onClick={()=>CancelOrder(item)} style={{ border: "1px solid #ddd", borderRadius: 5, backgroundColor: "#dd5959", color: "#fff" }}>Cancel Order</button>
                                        {/* </div> */}
                                    </div>
                                    <div>Date: <span style={{fontWeight:600}}>{item.date_time}</span></div>
                                    <div>ORDER ID: <span style={{fontWeight:600}}>{item.order_id}</span></div>
                                    <div>Mode of Payment: <span style={{fontWeight:600}}>{item.mode_payment}</span></div>
                                </div>
                                <div>
                                    <div style={{ fontSize: 12 }}>-- Shipping Details --</div>
                                    <div style={{ fontSize: 12 }}>Customer Name: <span style={{fontWeight:600}}>{item.fullname}</span></div>
                                    <div style={{ fontSize: 12 }}>Shipping Address: <span style={{fontWeight:600}}>{item.shipping_address}</span></div>
                                </div>
                                <div>
                                    <div style={{ fontSize: 12 }}>-- Items --</div>
                                    <div>
                                        {
                                            item.items.map((item, key) => {
                                                return (
                                                    <div style={{display:"flex", flexDirection:"column", border:"1px solid #ddd", padding:2}}>
                                                        <div>
                                                            <span style={{fontSize:12}}>{item.product_name}</span>
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", gap:5}}>
                                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap:5}}>
                                                                <span style={{fontSize:12}}>{item.qty}</span>
                                                                <span style={{fontSize:12}}>x</span>
                                                                <span style={{fontSize:12}}>${item.price}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12}}>${Number(item.qty*item.price).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <div style={{textAlign:"end"}}>Sub Total: &nbsp;
                                        <span style={{fontWeight:600}}>
                                            ${item.items?.reduce((amount, i) => amount = (i.price * i.qty) + amount, 0).toFixed(2)}
                                            {/* {item.amount} */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {/* <SpeedDial model={items} direction="up" transitionDelay={80} style={{right:5, bottom: 50, position:"fixed"}} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-outlined" /> */}
        </>
    );
}

export default Pending;
