import React, { useMemo, useState } from 'react';
import Navbar from './component/Navbar';
import Header from './component/Header';
import AdminHeader from './component/AdminHeader';
import AdminNavbar from './component/AdminNavbar';
import axios from 'axios';
import ENV from './ENV';

const AdminOrderPending = ({ userInfo }) => {
    console.log(userInfo)
    document.body.className = 'hold-transition sidebar-mini layout-fixed';



    const [memberlist, setMemberlist] = useState([])


    useMemo(async()=>{
        
        var formData = new FormData();
        formData.append("code_list_used", 1);        
        await axios({
            method: "post",
            url: ENV.DOMAIN+'process.php',
            data: formData,
        }).then((res)=>{
            setMemberlist(res.data)
            console.log(res.data)
        }).catch((err)=>{

        });
    }, [ENV])






    if(window.innerWidth <= 768) {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h5 className="m-0">Pending Order - <small style={{color:"#a6352d"}}>{"[Under Construction]"}</small></h5>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                            <div style={{display:"flex", flexDirection:"column", gap:5}}>
                                
                            {
                                        memberlist.map((item) => {
                                            return (
                                                <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", flexDirection:"column", width: "100%", borderRadius:5, border:"1px solid #ddd" }}>
                                                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                                        <span><small style={{fontSize:11}}>Order ID:</small> {"12345678910"}</span>
                                                        <span><small style={{fontSize:11}}>Order Date: {item.date_used}</small></span>
                                                    </div>
                                                    {/* Fullname */}
                                                    <div style={{width:"100%", display:"flex", flexDirection:"column", lineHeight:1}}>
                                                        <span><small style={{fontSize:11}}>Fullname: {"DrinkGOOD"}</small> </span>
                                                        <span><small style={{fontSize:11}}>Contact: {"00000000000"}</small> </span>
                                                    </div>
                                                    <div style={{width:"100%"}}><small style={{fontSize:11}}>Mode of Payment: {"GCash"}</small> </div>
                                                    {/* Order Item */}
                                                    <div>
                                                        <div><small style={{fontSize:11}}>Order Item</small></div>
                                                        <div style={{padding:2, backgroundColor:"#ddd"}}>
                                                            {
                                                                [1,2].map(()=> {
                                                                    return (
                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                                                                            <small style={{fontSize:11}}>Item Name</small>
                                                                            <span style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center", gap:50}}>
                                                                                <small style={{fontSize:11}}>Price: 0.00</small>
                                                                                <small style={{fontSize:11}}>Qty: 0</small>
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{lineHeight:1}}>
                                                        {/* Address */}
                                                        <div><small style={{fontSize:11}}>Address</small></div>
                                                        <div>
                                                            <small style={{fontSize:11}}>Looking up an address based on location</small>
                                                        </div>
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-evenly", alignItems:"center", paddingTop:15}}>
                                                        <button style={{borderRadius:5, border:"none", backgroundColor:"#a14c4c", color:"#fff"}}>Decline</button>
                                                        {/* <button style={{borderRadius:5, border:"none", backgroundColor:"#b88d42"}}>Prepare</button> */}
                                                        <button style={{borderRadius:5, border:"none", backgroundColor:"#154d85", color:"#fff"}}>to Ship</button>
                                                        {/* <button style={{borderRadius:5, border:"none", backgroundColor:"#085c02", color:"#fff"}}>Complete</button> */}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>
        )
    } else {
        return (
            <div className="wrapper">
                {/* Preloader */}
                {/* <div className="preloader flex-column justify-content-center align-items-center">
                    <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                </div> */}
                {/* Navbar */}
                <AdminHeader />
                {/* /.navbar */}
                {/* Main Sidebar Container */}
                <AdminNavbar  userInfo={userInfo} />
                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1 className="m-0">New Order</h1>
                                </div>
                            </div>
                            {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </div>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            {/* Small boxes (Stat box) */}
                            <div>
                                
                            {
                                        memberlist.map((item) => {
                                            return (
                                                <div style={{ padding: 5, backgroundColor: "#fff", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start" }}>
                                                        <div style={{width:150}}>{item.code}</div>
                                                        <div style={{width:300}}>{item.fullname}</div>
                                                        <div style={{width:300, textAlign:"left"}}>{item.date_used}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                            {/* /.row */}
                            {/* Main row */}
                            <div className="row">
                                {/* Left col */}
                                {/* /.Left col */}
                                {/* right col (We are only adding the ID to make the widgets sortable)*/}
                                
                                {/* right col */}
                            </div>
                            {/* /.row (main row) */}
                        </div>
                        {/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
                {/* /.content-wrapper */}
                <footer className="main-footer">
                    <strong>Copyright © 2023 <a href="https://adminlte.io">DrinkGood</a>.</strong>
                    All rights reserved.
                    {/* <div className="float-right d-none d-sm-inline-block"><b>Version</b> 3.2.0</div> */}
                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                </aside>
                {/* /.control-sidebar */}
            </div>

        );
    }



}

export default AdminOrderPending;
