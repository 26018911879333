import React, { useEffect, useMemo, useState } from 'react';
import Header from './component/Header';
import Navbar from './component/Navbar';
import axios from 'axios';
import ENV from './ENV';

const Matrix = ({ userInfo }) => {
    document.body.className = 'sidebar-mini';

    const useCheckMobileScreen = () => {
        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
        }

        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

        return (width <= 768);
    }

    const [downlineInfo, setDownlineInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("matrix_bonus", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            console.log(res.data)
            setDownlineInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);


    return (
        <div className="wrapper">
            {/* Navbar */}
                {
                    window.innerWidth <= 768 ?
                        <>
                            <div style={{ height: 50, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", backgroundColor: "#fff" }}>
                                <div style={{paddingLeft: 10, display:"flex", flexDirection:"row", justifyContent:"flex-start", alignItems:"center", gap: 5}}>
                                    <img src={require('./c456944a-87e8-47d5-8d2d-eb6645f0a7b2.jpg')} style={{width:45, height:45}} />
                                    {/* <div style={{display: "flex", flexDirection: "column", color: "green"}}>
                                        <span style={{fontWeight:600}}>Decuple Aquae Vivae</span>
                                        <span style={{fontWeight:600, fontSize: 15}}>INCORPORATED</span>
                                    </div> */}
                                    <div style={{display: "flex", flexDirection: "column", color: "green", lineHeight: 1}}>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 5, position: "absolute"}}>Mirabile</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 18, position: "absolute", color: "orange", marginLeft: 7}}>&</span>
                                        <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 26, position: "absolute", color: "red", marginLeft: 12}}>Proventus 
                                            <span style={{fontWeight:600, fontFamily: "Tangerine", fontSize: 25, top: 20, color: "gold", marginLeft: 5}}>Co.</span></span>
                                        {/* <span style={{fontWeight:600, fontSize: 15}}>CORPORATED</span> */}
                                    </div>
                                </div>
                                {/* <div style={{paddingRight:10}}>
                                <div onClick={()=>{
                                    localStorage.removeItem('user_id')
                                    localStorage.removeItem('username')
                                    window.location.assign('/')
                                }} style={{padding:5}}><i className='pi pi-sign-out' style={{color:"red"}} /></div>
                            </div> */}
                            </div>
                        </>
                        :
                        <Header />
                }
            {/* /.navbar */}
            {/* Main Sidebar Container */}
            <Navbar userInfo={userInfo} />
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper" style={{ minHeight: '1604.8px' }}>
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Matrix Bonus</h1>
                            </div>
                            {/* <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">User Profile</li>
                                </ol>
                            </div> */}
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                {/* <div className="card-header p-2"> */}
                                {/* <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul> */}
                                {/* </div> */}
                                {/* /.card-header */}
                                {
                                    !useCheckMobileScreen() ?
                                        <div className="card">
                                            {/* <div className="card-body table-responsive">
                                                <table className='table table-bordered'>
                                                    <thead>
                                                        <tr>
                                                            <th>Fullname</th>
                                                            <th>Sponsor</th>
                                                            <th>Placement</th>
                                                            <th>Rank</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            downlineInfo.map((item, key) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            {item.fullname}
                                                                            <br />
                                                                            <small>{item.direct_user_id != '' ? item.direct_user_id : ''}</small>
                                                                        </td>
                                                                        <td>
                                                                            {item.sponsor_name}
                                                                            <br />
                                                                            <small>{item.sponsor_user_id != '' ? item.sponsor_user_id : ''}</small>
                                                                        </td>
                                                                        <td>
                                                                            {item.placement_name}
                                                                            <br />
                                                                            <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                        </td>
                                                                        <td>{item.rank_status}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                        :
                                        <>
                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                {
                                                    downlineInfo.map((item, key) => {
                                                        return (
                                                            <div className="card" style={{ padding: 10, marginBottom: 5 }}>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                                    <div>
                                                                        <b>$ {item.income}</b>
                                                                        {/* <b>{item.fullname}</b> */}
                                                                        <br />
                                                                        <small>{item.direct_user_id != '' ? item.direct_user_id : ''}</small>
                                                                    </div>
                                                                    <small>{item.rank_status}</small>
                                                                </div>
                                                                <div style={{border:"0.5px solid #ddd"}}></div>
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                        <small>{item.date_time}</small>
                                                                    </div>
                                                                    {/* <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start", width:"50%"}}>
                                                                        <small><b>Placement</b></small>
                                                                        <small>{item.placement_name}</small>
                                                                        <small>{item.placement_user_id != '' ? item.placement_user_id : ''}</small>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                }

                                {/* /.card-body */}
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div style={{ position: "fixed", zIndex: 2, bottom: 0, backgroundColor: "#fff", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-evenly", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                    <div onClick={() => window.location.assign('/')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopLeftRadius: 20}}>
                        <i className='pi pi-microsoft' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Home</span>
                    </div>
                    {/* <div onClick={() => window.location.assign('/shop')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div> */}
                    <div onClick={() => window.location.assign('/shop')} style={{ position:"relative", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-shopping-bag' />
                        <span style={{position:"absolute", color:"#fff", padding:3, top:2, right:10, backgroundColor:"red", fontSize:8, borderRadius:100}}>Coming</span>
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Shop</span>
                    </div>
                    <div onClick={() => window.location.assign('/wallet')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <i className='pi pi-wallet' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Wallet</span>
                    </div>
                    <div onClick={() => window.location.assign('/profile')} style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width: "100%", backgroundColor: "#fff", textAlign: "center", border: "1px solid #ddd", height: 45, display: "flex", justifyContent: "center", alignItems: "center", borderTopRightRadius: 20 }}>
                        <i className='pi pi-user' />
                        <span style={{fontSize: 12, color:"rgb(101 101 101)"}}>Profile</span>
                    </div>
                </div>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block"><b>Version</b> 3.2.0</div>
                <strong>Copyright © 2023 <a href="#">DrinkGood</a>.</strong> All rights reserved.
            </footer>
            {/* /.control-sidebar */}
            <div id="sidebar-overlay" />
        </div>
    );
}

export default Matrix;
