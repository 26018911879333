import axios from 'axios';
import React, { useMemo, useState } from 'react';
import ENV from '../../ENV';

const PreSignUpListBtn = ({userInfo}) => {
    const [directInfo, setDirectInfo] = useState([]);
    useMemo(async () => {
        var formData = new FormData();
        formData.append("unpaid", 1);
        formData.append("user_id", userInfo?.user_id);

        await axios({
            method: "post",
            url: ENV.DOMAIN + 'process.php',
            data: formData,
        }).then((res) => {
            // console.log(res.data)
            setDirectInfo(res.data)
        })
            .catch((err) => {
                console.log(err);
            });
    }, [userInfo]);

    return (
        <>
        {
            directInfo?.length > 0?
            <div style={{paddingTop:5}}>
                <div onClick={()=>window.location.assign('/pre-sign-up')} style={{fontSize:15, height:35, backgroundColor:"#fff", padding:5, borderRadius:5, display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", border:"1px solid #ddd"}}>
                    <span style={{fontWeight:600}}>LIST OF PRE-SIGN-UP</span>
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", alignItems:"center", gap:5}}> <div style={{padding:2, paddingLeft:10, paddingRight:10, borderRadius:100, backgroundColor:"#6366f1", color:"#fff", minWidth:20, fontSize:12}}>{directInfo?.length}</div> <span className='pi pi-chevron-right'></span></div>
                </div>
            </div>
            :null
        }
        </>
    );
}

export default PreSignUpListBtn;
