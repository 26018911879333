import React, { useState } from 'react';
import RowMemberBtn from './RowMemberBtn';
import ENV from '../ENV';
import { ProgressSpinner } from 'primereact/progressspinner';
import ActivateButton from '../extra/activate/ActivateButton';

const RowMember = ({ memberlist, setMemberlist, showStatus, setShowStatus}) => {

    const [search, setSearch] = useState('')
    const [showPayout, setShowPayout] = useState({user_id: null, open: false})
    const [showactive, setShowActive] = useState(1)
    
    const SearchData = (data, value, showactive)  => {
        if (value!='') {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase()) && (i?.account_status == showactive))
             || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase()) && (i?.account_status == showactive))).slice(0, 10);
        } else {
            data = data.filter((i) => (i?.fullname.toLowerCase().includes(value.trim().toLowerCase()) && (i?.account_status == showactive))
             || (i?.user_id.toLowerCase().includes(value.trim().toLowerCase()) && (i?.account_status == showactive))).slice(0, 10);
        }
        return data;
    }
    console.log(memberlist, showactive)

    // const buttonStatus = (showStatus) => {
    //     if(showStatus == 0) {
    //         setMemberlist([])
    //         setShowStatus(1)
    //     } else if(showStatus == 1) {
    //         setMemberlist([])
    //         setShowStatus(0)
    //     }
    // }

    if(window.innerWidth <= 768) {
        
        return (
            <>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 5 }}>
                    <div style={{display:"flex", flexDirection:"row", gap:10, alignItems:"center"}}>
                        <span>Search: </span>
                        <input style={{borderRadius:5, border:"1px solid #303030", height:35, padding:5}} onChange={(e)=>setSearch(e.target.value)} />
                        {/* <span>Total Member/s: {memberlist.length}</span> */}
                    </div>
                    {
                        memberlist.length > 0 ?
                            <>
                                {
                                    SearchData(memberlist, search, showactive).map((item, key) => {
                                        // if (item.account_status == 1) {
                                            return (
                                                <div style={{width:"100%", minHeight:100, borderRadius:5, backgroundColor:"#fff"}}>
                                                    <div style={{padding:5, display:"flex", flexDirection:"row", justifyContent:"flex-start", gap:10}}>
                                                        <div><img src={ENV.DOMAIN+item.image} style={{width:70, height:70, borderRadius:10, border:"2px solid #ddd"}} /></div>
                                                        <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", gap:0, lineHeight:1}}>
                                                            <div>{item.fullname}</div>
                                                            <div><span style={{fontSize:10}}>{item.date_registered}</span></div>
                                                            <RowMemberBtn item={item} />
                                                        </div>
                                                    </div>
                                                    <div style={{padding:5}}>
                                                        <div style={{fontSize:12}}>User Id: {item.user_id}</div>
                                                        {
                                                            item.direct_sponsor_name!='' ?
                                                            <div style={{fontSize:12}}>Direct Upline: <span style={{textDecoration:"underline"}}>{item.direct_sponsor_name}</span></div>
                                                            :null
                                                        }
                                                        <div style={{fontSize:12}}>Rank: {item.account_status == 0 ? <span style={{ color: "orange" }}>Unpaid</span> : item.rank_status}</div>
                                                        <div style={{fontSize:12}}>Upcoming Earning: {item.upcoming_earning}</div>
                                                        <div style={{fontSize:12}}>Total Earnings: {item.total_earning}</div>
                                                        <div style={{fontSize:12}}>Balance: {item.balance}</div>
                                                        <div style={{fontSize:12}}>Total Payout: {Number(item.total_payout).toFixed(2)}</div>
                                                        
                                                        {
                                                            item.address != '' ?
                                                            <div style={{fontSize:12}}>Address: {item.address}</div>
                                                            :null
                                                        }
                                                        
                                                    </div>
                                                    {/* <div>
                                                        <RowMemberBtn item={item} />
                                                    </div> */}
                                                </div>
                                            )
                                        // }
                                    }).slice(0, 20)
                                }
                            </>
                            :
                            <>Loading...</>
                    }
                </div>            
            </>
        )
    } else {
        return (
            <div>
                <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}>
                    <div style={{display:"flex", flexDirection:"row", gap:10, alignItems:"center"}}>
                        <span>Search: </span>
                        <input style={{borderRadius:5, border:"1px solid #303030", height:35, padding:5}} onChange={(e)=>setSearch(e.target.value)} />
                        <span>Total Member/s: {SearchData(memberlist, '', showactive).length}</span>
                        <div>
                            <button onClick={()=> setShowActive(showactive == 1 ? 0 : 1)} style={{display:"flex", flexDirection:"row", alignItems:"center", borderRadius:5, border:"1px solid #ddd", backgroundColor:showactive == 0 ? "green" : "#cb4d4d", color:"#fff"}}>
                                {
                                    memberlist.length>0?
                                    null
                                    :
                                    <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                }
                                {showactive == 0 ? 'Show Active' : 'Show Inactive'}
                            </button>
                        </div>
                    </div>
                    
                    <div style={{ padding: 5, backgroundColor: "rgb(144 173 235)", display: "flex", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-start" }}>
                            <div style={{ width: 100 }}><b>#</b></div>
                            <div style={{ width: 150 }}><b>User Id #</b></div>
                            <div style={{ width: showStatus == 0 ? 350 : 200, textAlign: "left" }}><b>Fullname</b></div>
                            {/* <div style={{ minWidth: 100, textAlign: "left" }}><b>Rank</b></div> */}
                            <div style={{ minWidth: 100, textAlign: "right", display: showStatus == 0 ? 'none' : 'block' }}><b>Balance</b></div>
                            <div style={{ minWidth: 200, textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}><b>Total Payout</b></div>
                            <div style={{ width: 200, textAlign: "left" }}><b>Registered</b></div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10, justifyContent: "flex-end" }}>
                            {/* <div style={{ width: 100 }}><b>Status</b></div> */}
                            <div style={{ width: 50, display: showStatus == 0 ? 'none' : 'block' }}><b>Action</b></div>
                        </div>
                    </div>
                    {
                        memberlist.length > 0 ?
                            <div style={{display:"flex", flexDirection:"column", gap:10}}>
                                {
                                    SearchData(memberlist, search, showactive).map((item, key) => {
                                        // if (item.account_status == 1) {
                                            return (
                                                <div key={key} style={{ padding: 0, backgroundColor: "#fff", display: "flex", justifyContent: "space-between", border:"1px solid #000", borderRadius:10, width: "100%" }}>
                                                    <div style={{ minWidth: 100, padding: 5 }}>
                                                            <img src={ENV.DOMAIN + item.image} style={{ width: 100, height: 100, borderRadius: 10, border: "2px solid #ddd" }} />
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", padding:5}}>
                                                        
                                                        <div style={{ display: "flex", flexDirection: "row", gap: 10, padding:0, justifyContent: "flex-start" }}>
                                                            
                                                            <div style={{ width: 150, height:50, fontWeight:600, display:"flex", flexDirection:"row", alignItems:"center" }}>{item.user_id}</div>
                                                            <div style={{ width: showStatus == 0 ? 350 : 200, textAlign: "left", fontWeight:600, display:"flex", flexDirection:"row", alignItems:"center" }}>{item.fullname}</div>
                                                            {/* <div style={{ minWidth: 100, textAlign: "left" }}>{item.account_status == 0 ? <span style={{ color: "orange" }}>Unpaid</span> : item.rank_status}</div> */}
                                                            <div style={{ minWidth: 100, textAlign: "right", display: showStatus == 0 ? 'none' : 'block' }}>
                                                                <div style={{ padding: 2, borderRadius: 10, minWidth: 100, backgroundColor: "#ddd" }}>$ {item.balance}</div>
                                                            </div>
                                                            <div style={{ minWidth: 200, textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}>
                                                            {
                                                                item.total_payout !=null ?
                                                                <div style={{ padding: 2, borderRadius: 10, minWidth: 100, backgroundColor: "#ddd" }}>$ {Number(item.total_payout, 0).toFixed(2)}</div>
                                                                :
                                                                null
                                                            }
                                                            </div>
                                                            <div style={{ width: 200, textAlign: "left", display:"flex", flexDirection:"row", alignItems:"center" }}>{item.date_registered}</div>
                                                            <div style={{width:null, display: showactive == 0 ? 'none' : 'block'}}>
                                                                <div style={{display:"flex", flexDirection:"column", gap: 5}}>
                                                                    <RowMemberBtn item={item} />
                                                                    {/* <button>Allow Access to Administrator </button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{width:"100%", border:"1px solid #ddd", padding:5, borderRadius:5}}>
                                                            <div style={{ width: "100%", textAlign: "left" }}><span style={{fontWeight:"bolder"}}>{showStatus == 0 ? 'Sponsored By' : 'Activated By'}:</span> {showStatus == 0 ? item.direct_sponsor_name : item.activatedby_name}</div>
                                                            <div style={{ width: "100%", textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}><span style={{fontWeight:"bolder"}}>Rank:</span> {item.account_status == 0 ? <span style={{ color: "orange" }}>Unpaid</span> : item.rank_status}</div>
                                                            <div style={{ width: "100%", textAlign: "left" }}><span style={{fontWeight:"bolder"}}>Status:</span> {item.account_status == 0 ? <span style={{ color: "orange" }}>INACTIVATED</span> : <span style={{ color: "green", fontWeight:"bolder" }}>ACTIVATED</span>}</div>
                                                            <div style={{ width: "100%", textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}><span style={{fontWeight:"bolder"}}>Entry Code:</span> {item.code}</div>
                                                            <div style={{ width: "100%", textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}><span style={{fontWeight:"bolder"}}>Since Activated:</span> {item.activated_date}</div>
                                                            <ActivateButton showStatus={showStatus} setShowStatus={setShowStatus} item={item} />
                                                            <div style={{ width: "100%", textAlign: "left", display: showStatus == 0 ? 'none' : 'block' }}>
                                                                
                                                                {
                                                                    item.list?.length>0?
                                                                    <span style={{fontWeight:"bolder", display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>Payout History: {showPayout.user_id == item.user_id && showPayout.open == true ? <span onClick={()=>setShowPayout({user_id: item.user_id, open: false})} style={{cursor:"pointer", padding:0, width:100, borderRadius:10, color:"#fff", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:"#5ebd78"}}>Show</span> : <span onClick={()=>setShowPayout({user_id: item.user_id, open: true})} style={{cursor:"pointer", padding:0, width:100, borderRadius:10, color:"#fff", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", backgroundColor:"#5ebd78"}}>Show</span>}</span> 
                                                                    :null
                                                                }
                                                                {
                                                                    showPayout.user_id == item.user_id && showPayout.open == true ?
                                                                    <div style={{marginTop:10}}>
                                                                        {
                                                                            item.list?.map((items, key)=>{
                                                                                return (
                                                                                    <div style={{border:"1px solid #ddd", padding:10}}>
                                                                                        <div>{items.date_payout} - {items.date_approved}</div>
                                                                                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", gap:20}}>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Account Name</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>{items.account_name}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Account Number</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>{items.account_number}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Amount</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>${items.amount}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Service Fee</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>${items.fee}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Method</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>${items.payout_method}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Total</span>
                                                                                                <span style={{textDecoration:"underline", fontWeight:"bold"}}>${Number(items.amount+items.fee).toFixed(2)}</span>
                                                                                            </div>
                                                                                            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignItems:"center", gap:2}}>
                                                                                                <span style={{fontSize:12}}>Status</span>
                                                                                                <div style={{fontWeight:"bold", backgroundColor:items.status == 'deducted' ? "#ffd4d4" : "#b4fcf8", borderRadius:5, paddingLeft:10, paddingRight:10}}>
                                                                                                    {
                                                                                                        items.status == 'approved' ?
                                                                                                        <span style={{color:"green"}}>Approved</span>
                                                                                                        :null
                                                                                                    }
                                                                                                    {
                                                                                                        items.status == 'deducted' ?
                                                                                                        <span style={{color:"red"}}>Deducted</span>
                                                                                                        :null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    :null
                                                                }
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            )
                                        // }
                                    }).slice(0, 10)
                                }
                            </div>
                            :
                            <>Loading...</>
                    }
                </div>
            </div>
        );
    }

}

export default RowMember;
