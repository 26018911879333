import axios from 'axios';
import React, { useState } from 'react';
import ENV from '../ENV';
import swal from 'sweetalert';
import { enCA } from 'date-fns/locale';

const ChristmasBanner = ({userInfo}) => {

    // const [aftersendotp, setAfterSendOTP] = useState(false);
    // const [input, setInput] = useState({number: '', code: ''});


    // const sendOTP = async () => {


    //     var formData = new FormData();
    //     formData.append("send_otp", 1);
    //     formData.append("user_id", userInfo.user_id);
    //     formData.append("number", input.number);

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         swal({
    //             title: "Done!",
    //             text: "Successfull added contact number",
    //             icon: "success",
    //             timer: 1000,
    //             button: false,
    //         }).then(() => {
    //             setAfterSendOTP(true)
    //             console.log(input)
    //         });
    //     });
    // }

    // const submitOTP = async () => {
    //     var formData = new FormData();
    //     formData.append("verify_otp", 1);
    //     formData.append("user_id", userInfo.user_id);
    //     formData.append("number", input.number);
    //     formData.append("code", input.code);

    //     await axios({
    //         method: "post",
    //         url: ENV.DOMAIN+'process.php',
    //         data: formData,
    //     }).then((res)=>{
    //         swal({
    //             title: "Done!",
    //             text: "Successfull added contact number",
    //             icon: "success",
    //             timer: 1000,
    //             button: false,
    //         }).then(() => {
    //             setAfterSendOTP(true)
    //             console.log(input)
    //         });
    //     });
    // }

    // setInput({ ...input, [e.target.name]: e.target.value })
    // input?.number?.replace(/.(?=.{4,}$)/g, '*')

    const [open_banner, setOpenBAnner] = useState(false);

    // console.log(userInfo)

    if (sessionStorage.getItem('open_banner')) {
        return (
            <></>
        )
    }
    return (
        <>
            <div style={{width:"100%", height:"100%", backgroundColor:"#000", position:"absolute", zIndex:9999, opacity:0.8, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                
            </div>
            <div style={{width:"100%", height:window.innerHeight, backgroundColor:"transparent", position:"fixed", zIndex:9999, display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>

                <img src={userInfo?.banner?.image} style={{width:"100%", height:"auto"}} />
         
            </div>
            <div style={{position:"fixed", zIndex:9999, bottom: 100, width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <div onClick={()=>{
                    sessionStorage.setItem('open_banner', true)
                    setOpenBAnner(true)
                }} style={{borderRadius:100, width:30, height:30, backgroundColor:"#fff", border:"3px solid red", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", fontWeight:800, fontSize:20}}>
                    <span className='pi pi-times'></span>
                </div>
                <div style={{color:userInfo?.banner?.color_text}}>Close</div>
            </div>
        </>
    );
}

export default ChristmasBanner;
